// useForm.js
import { useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';

const useDelete = (data, fetchTable, hideModal) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = (val) => {
        setLoading(true);

        let endpoint = "perizinan/jenis/" + data.id;
        let method = "DELETE";

        _setAxios(endpoint, method).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        loading,
        handleSubmit,
    };
};

export default useDelete;
