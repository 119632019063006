import React from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const LabelYesNo = ({ ...props }) => {

  if(props.isActive) {
    return (
      <Tag {...props} color="green" icon={<CheckCircleOutlined />} style={{width: 100, textAlign: 'center'}}>Iya</Tag>
    );
  }
  
  return (
    <Tag {...props} color="red" icon={<CloseCircleOutlined />} style={{width: 100, textAlign: 'center'}}>Tidak</Tag>
  );
};
export default LabelYesNo;
