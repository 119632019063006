import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { _success, _setAxios, _warn } from '../../../../lib/Helper';
import { InfoCircleOutlined } from '@ant-design/icons';

const Delete = ({ visible, hideModal, data, fetchTable }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const resp = await _setAxios(`presensi/setting/${data.id}`, "DELETE");
            if (resp.status === true) {
                if(resp.data.status){
                    _success('topRight', 'Success', resp.data.message);
                    fetchTable(true);
                    hideModal();
                }else{
                    _warn('topRight', 'Warning', resp.data.message);
                }
            }
        } catch (error) {
            // Handle any error if needed
            console.error("Error deleting data:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            maskClosable={false}
            title={<><InfoCircleOutlined /> Konfirmasi</>}
            open={visible}
            onCancel={hideModal}
            width={300}
            footer={(
                <>
                    <Button onClick={hideModal}>Batal</Button>
                    <Button type="primary" onClick={handleSubmit} loading={loading}>Simpan</Button>
                </>
            )}
        >
            Data akan dihapus. Apakah Anda yakin?
        </Modal>
    );
};

export default Delete;
