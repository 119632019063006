import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, Divider, Popover, Button, Radio, Space, Input, Tooltip, BackTop } from 'antd';
import { PageHeader } from 'components/header';
import { InfoCircleOutlined, SearchOutlined, SortAscendingOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering, _scrollFloatBtn, _success } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/AuthRedirect'
import Delete from '../modal/Delete';
import Form from '../modal/Form';
import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import Export from '../modal/Export';


const tableOptions = {
  sorts: [{
    value: "ASC",
    label: "Ascending",
  }, {
    value: "DESC",
    label: "Descending",
  }],
  fields: [{
    value: "id",
    label: "Tanggal Pembuatan",
  }, {
    value: "nama_struktural",
    label: "Nama Struktural",
  }, {
    value: "periode",
    label: "Periode",
  }, {
    value: "status",
    label: "Status",
  }]
}

class ListIndex extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      download: {
        url: null,
        filename: null,
      },
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      delete: {
        data: {},
        visible: false
      },
      form: {
        data: {},
        visible: false
      },
      filter: {
        values: {},
        visible: false,
      },
      export: {
        values: {},
        visible: false,
      },
      file_active: null
    };
  }

  componentDidMount() {
    _scrollFloatBtn()
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("lembaga/struktur-organisasi/table", "POST", params).then(resp => {
      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };
  // FILTER END ------------------------------------------


  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }
  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: data,
        visible: visible
      },
    })
  }

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
      },
    })
  }

  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }

  onDownloadFile = (data = {}) => {
    let param = {
      siswa_id: data.id
    }
    _setAxios("ppdb/siswa/formulir-pendaftaran", "POST", param).then(resp => {
      if (resp.status === true) {
        _success('topRight', 'Success', resp.data.message)
        window.open(resp.data.data.link)
      }
    })
  }

  render() {

    const access = this.props.privilege.access["/lembaga/struktur-organisasi"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { table } = this.state;

    const sortComponent = <>
      <Radio.Group onChange={(e) => {
        const params = getParamTable("sort_field", table, e.target.value)
        this.fetch(params, isMobile ? true : false)
      }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
        <Space direction="vertical">
          {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
        </Space>
      </Radio.Group>
      <Divider orientation="left"></Divider>
      <Radio.Group onChange={(e) => {
        const params = getParamTable("sort", table, e.target.value)
        this.fetch(params, isMobile ? true : false)
      }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
        <Space direction="vertical">
          {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
        </Space>
      </Radio.Group>
    </>

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Struktur Organisasi"
          subTitle="Data Struktur Organisasi"
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true)}><DownloadOutlined /> Export</Button> : null,
            action.create ? <Button onClick={this.modalForm.bind(this, true, {})} type='primary'><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <MobileView>
          <div className='float-btn-center' id='float-btn-center' align='center'>
            <div className="float-btn-div">
              <Popover placement="top" content={sortComponent} trigger="click">
                <Button type='primary' className='float-btn' icon={<SortAscendingOutlined />}>Urutkan</Button>
              </Popover>
            </div>

          </div>
        </MobileView>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19} >
            <Input
              name="search"
              className='search-table'
              placeholder="Search..."
              prefix={<SearchOutlined className="site-form-item-icon" />}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const params = getParamTable("search", table, e.target.value)
                  this.fetch(params, isMobile ? true : false)
                }
              }}
              suffix={
                <Tooltip title="Cari berdasarkan nama">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <BrowserView>
              <Button.Group className='ant-btn-group-fullwidth'>
                <Popover placement="bottom" content={sortComponent} trigger="click">
                  <Button block className='btn-border-radius'><SortAscendingOutlined /> Sort</Button>
                </Popover>
              </Button.Group>
            </BrowserView>
          </Col>

          <Col xs={24}>
            <BrowserView>
              <Desktop
                access={access}
                state={this.state}
                fetch={this.fetch}
                fetchTable={this.fetchTable}
                modalDelete={this.modalDelete}
                modalForm={this.modalForm}
                onDownloadFile={this.onDownloadFile}
              />
            </BrowserView>
            <MobileView>
              <Mobile
                access={access}
                state={this.state}
                fetch={this.fetch}
                fetchTable={this.fetchTable}
                modalDelete={this.modalDelete}
                modalForm={this.modalForm}
                onDownloadFile={this.onDownloadFile}
              />
            </MobileView>
          </Col>
        </Row>

        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        <BackTop />

        <a href={this.state.download.url} className="download-file" download={this.state.download.filename} target="_blank" rel="noopener noreferrer" >download</a>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ListIndex);
