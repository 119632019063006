import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop } from 'antd';
import { TableList } from 'components/list';
import Filter from '../modal/Filter';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableWithFilter } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';

const sortFields = [{
  value: "sended_at",
  label: "Waktu Kirim",
}];

const KehadiranIndex = ({ privilege, action }) => {
  const {
    table,
    filter,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
  } = useList(privilege?.tahun_ajaran, privilege?.semester);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Notifikasi Kehadiran"
        subTitle="Data notifikasi kehadiran"
        />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={18} md={19}>
          <SearchTable table={table} fetch={fetchData} />
        </Col>
        <Col xs={24} sm={6} md={5}>
          <SortingTableWithFilter
            table={table}
            fetch={fetchData}
            modalFilter={modalFilter}
            sortFields={sortFields}
          />
        </Col>
        <Col xs={24}>
          <TableList
            state={{ table, filter }}
            fetch={fetchData}
            fetchTable={fetchTable}
            columns={columns(action)}
          />
        </Col>
      </Row>
      {filter.visible && (
        <Filter
          visible={filter.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filter.values}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(KehadiranIndex);