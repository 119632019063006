import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, getParamTempTableFiltering } from '../../../../lib/Helper';
import { schemaPelanggaran } from '../schema';
import validate from 'validate.js';

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Menu, Dropdown, Input, Tooltip, Modal, Form, Pagination } from 'antd';
import { PageHeader } from 'components/header';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, FilterOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { aspekPelanggaranOptions } from '../../../../data/options';
import SelectTahunAjaran from '../../../../components/select/SelectTahunAjaran';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class DataPelanggaran extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tahun_ajaran: null
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            filter: {
                visible: false,
                values: {},
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tatibsi/pelanggaran/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaPelanggaran);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaPelanggaran);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------

    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },

            values: {},
            errors: {},
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    kode: row.kode ? row.kode : null,
                    aspek: row.aspek ? row.aspek : null,
                    pelanggaran: row.pelanggaran ? row.pelanggaran : null,
                    poin: row.poin ? row.poin : null,
                    sanksi: row.sanksi ? row.sanksi : null,
                    tahun_ajaran_id: row.tahun_ajaran_id ? row.tahun_ajaran_id : null,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state
        const { privilege } = this.props
        const { tahun_ajaran } = privilege

        //Validation 
        let validation = this.setValidate(values, schemaPelanggaran)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        let param = {
            kode: values.kode ? values.kode : null,
            aspek: values.aspek ? values.aspek : null,
            pelanggaran: values.pelanggaran ? values.pelanggaran : null,
            poin: values.poin ? values.poin : null,
            sanksi: values.sanksi ? values.sanksi : null,
            tahun_ajaran_id: tahun_ajaran?.id,
        }

        // Define Network
        let method = "POST"
        let endpoint = "tatibsi/pelanggaran"
        if (values.id) {
            param = {
                kode: values.kode ? values.kode : null,
                aspek: values.aspek ? values.aspek : null,
                pelanggaran: values.pelanggaran ? values.pelanggaran : null,
                poin: values.poin ? values.poin : null,
                sanksi: values.sanksi ? values.sanksi : null,
                tahun_ajaran_id: values.tahun_ajaran_id ? values.tahun_ajaran_id : null,
            }

            method = "PUT"
            endpoint = "tatibsi/pelanggaran/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tatibsi/pelanggaran/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------


    render() {
        const { table, values, errors } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalFilter = <Modal
            title="Filter Data Pelanggaran"
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <SelectTahunAjaran
                        onChange={(val) => {
                            this.setTempTableFilter("tahun_ajaran", val ? val : null)
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>

        const modalForm = <Modal
            title="Form Data Pelanggaran"
            visible={this.state.form.visible}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Kode *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="kode"
                        type="text"
                        onChange={this.handleChange}
                        value={values.kode || ''}
                        error={this.hasError('kode')}
                        helperText={
                            this.hasError('kode') ? errors.kode[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={aspekPelanggaranOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Aspek"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="aspek"
                            type="text"
                            error={this.hasError('aspek')}
                            helperText={
                                this.hasError('aspek') ? errors.aspek[0] : null
                            }
                        />}
                        value={values.aspek || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("aspek", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Pelanggaran *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="pelanggaran"
                        type="text"
                        onChange={this.handleChange}
                        value={values.pelanggaran || ''}
                        error={this.hasError('pelanggaran')}
                        helperText={
                            this.hasError('pelanggaran') ? errors.pelanggaran[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Poin"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="poin"
                        type="text"
                        onChange={this.handleChange}
                        value={values.poin || ''}
                        error={this.hasError('poin')}
                        helperText={
                            this.hasError('poin') ? errors.poin[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Sanksi"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="sanksi"
                        type="text"
                        onChange={this.handleChange}
                        value={values.sanksi || ''}
                        error={this.hasError('sanksi')}
                        helperText={
                            this.hasError('sanksi') ? errors.sanksi[0] : null
                        }
                    />
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Data Pelanggaran"
                    extra={
                        this.props.action.create ?
                            <>
                                <Tooltip title="Tambah">
                                    <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                                </Tooltip>
                            </> : null
                    }
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={19} md={19} >
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }
                            }}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={6} md={5}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Kode',
                                    dataIndex: 'kode',
                                },
                                {
                                    title: 'Aspek',
                                    dataIndex: 'aspek',
                                },
                                {
                                    title: 'Pelanggaran',
                                    dataIndex: 'pelanggaran',
                                },
                                {
                                    title: 'Poin',
                                    dataIndex: 'poin',
                                },
                                {
                                    title: 'Sanksi',
                                    dataIndex: 'sanksi',
                                },
                                {
                                    title: <EllipsisOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        {this.props.action.update ? <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item> : ""}
                                        {this.props.action.delete ? <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item> : ""}
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={false}
                            loading={table.loading}
                            size="small"
                        />
                    </Col>
                    <Col xs={24} className="table-pagination">
                        <Pagination
                            size="small"
                            current={table.pagination.current}
                            pageSize={table.pagination.pageSize}
                            total={table.pagination.total}
                            showTotal={() => `Total ${table.pagination.total} items`}
                            showSizeChanger
                            onChange={(current, pageSize) => {
                                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                        />
                    </Col>
                </Row>

                {modalForm}
                {modalFilter}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(DataPelanggaran);
