import React from 'react';
import { connect } from 'react-redux'
import Config from "../../Config";
import { _getToken, isAuth, _error, _success, _setAxios } from '../../lib/Helper';
import { Breadcrumb, Row, Col, Divider, Tree, Button } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined } from '@ant-design/icons';
import axios from 'axios';
import AuthRedirect from '../../components/AuthRedirect'
import PreloadContent from '../../components/preload/PreloadContent'
import { Link } from 'react-router-dom';

class ConfPrivilege extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loadingBtn: false,
            roleID: 0,
            roleName: 0,

            treeData: [],
            expandedKeys: [],
            checkedKeys: [],
            selectedKeys: [],
            autoExpandParent: true,
            searchValue: '',
        };
    }

    componentDidMount() {
        this.setState({
            roleID: this.props.match.params.id
        })
        this.getTreeData(this.props.match.params.id)
        this.getRoleByID(this.props.match.params.id)
    }

    getTreeData = (roleID) => {
        const headers = {
            'Accept': 'application/json',
            'Authorization': _getToken()
        };

        axios.get(Config.api + '/privilege/tree_access', { headers })
            .then((resp) => {
                this.setState({
                    treeData: resp.data.data,
                });
                this.getAccessByRoleID(this.state.roleID)
            })
            .catch(function (error) {
                if (error.response.data.message) {
                    _error('topRight', 'Failed', error.response.data.message)
                }
            });
    }

    getAccessByRoleID = (roleID = 0) => {
        if (roleID === 0) {
            return
        }
        const headers = {
            'Accept': 'application/json',
            'Authorization': _getToken()
        };

        axios.get(Config.api + '/privilege/have_access/' + roleID, { headers })
            .then((resp) => {
                let access = []
                resp.data.data.access.forEach(element => {
                    access.push(parseInt(element))
                });
                this.setState({
                    checkedKeys: access,
                    expandedKeys: access,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.data.message) {
                    _error('topRight', 'Failed', error.response.data.message)
                }
            });
    }

    getRoleByID = (roleID = 0) => {
        if (roleID === 0) {
            return
        }
        const headers = {
            'Accept': 'application/json',
            'Authorization': _getToken()
        };

        axios.get(Config.api + '/role/' + roleID, { headers })
            .then((resp) => {
                this.setState({
                    roleName: resp.data.data.name,
                });
            })
            .catch(function (error) {
                if (error.response.data.message) {
                    _error('topRight', 'Failed', error.response.data.message)
                }
            });
    }

    onExpand = (expandedKeysValue = []) => {
        this.setState({
            expandedKeys: expandedKeysValue,
            autoExpandParent: false
        })
    };

    onCheck = (checkedKeysValue = []) => {
        this.setState({
            checkedKeys: checkedKeysValue
        })
    };

    onSelect = (selectedKeysValue = []) => {
        this.setState({
            selectedKeys: selectedKeysValue
        })
    };
    onSubmit = (e) => {
        e.preventDefault();
        const menuActionIDs = []
        this.state.checkedKeys.forEach(menuActionID => {
            if (Number.isInteger(menuActionID) === true) {
                if (menuActionIDs.indexOf(menuActionID) !== -1) {
                } else {
                    menuActionIDs.push(menuActionID)
                }
            }
        });

        this.setState({
            loadingBtn: true
        });
        _setAxios("privilege", "POST", {
            role: this.state.roleID,
            access: menuActionIDs,
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    loadingBtn: false
                });
                this.getTreeData()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    loadingBtn: false
                });
            }
        })
    };

    render() {
        const access = this.props.privilege.access["/configuration/role"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action

        const { expandedKeys, autoExpandParent, checkedKeys, selectedKeys, loading } = this.state;
        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Konfigurasi</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/configuration/role`}>Role</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Privilege</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <Divider className='breadcrumb-line' orientation="left"></Divider>
                <PageHeader
                    className="site-page-header"
                    title="Privilege"
                    subTitle="Data konfigurasi privilege"
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    {
                        loading === true ? <PreloadContent /> :
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col xs={12}>
                                        <h5>Role: {this.state.roleName}</h5>
                                    </Col>
                                    <Col xs={12} style={{textAlign: "right"}}>
                                        {action.update === true ? <>
                                            <Button onClick={() => {
                                                this.props.history.goBack();
                                            }} loading={this.state.loadingBtn}>Batal</Button>&nbsp;
                                            <Button type='primary' htmlType='submit' onClick={this.onSubmit} loading={this.state.loadingBtn}>Simpan</Button>
                                        </> : ""}
                                    </Col>
                                </Row>

                                <Divider orientation="left"></Divider>

                                <Row>
                                    <Col span={24}>
                                        <Tree
                                            checkable
                                            onExpand={this.onExpand}
                                            expandedKeys={expandedKeys}
                                            autoExpandParent={autoExpandParent}
                                            onCheck={this.onCheck}
                                            checkedKeys={checkedKeys}
                                            onSelect={this.onSelect}
                                            selectedKeys={selectedKeys}
                                            treeData={this.state.treeData}
                                        />
                                    </Col>
                                </Row>
                            </>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(ConfPrivilege
);
