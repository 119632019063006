import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg } from '../../lib/Helper';
import { schemaAnak } from './schema';
import validate from 'validate.js';
import { pendidikanFormalJenjangOptions, genderOptions, anakStatusOptions, anakPekerjaanOptions } from '../../data/options';

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Menu, Dropdown, Input, Tooltip, Modal } from 'antd';
import { PageHeader } from 'components/header';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormLabel from '@mui/material/FormLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class Anak extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tk/anak/table/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaAnak);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaAnak);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    nama: row.nama ? row.nama : null,
                    tempat_lahir: row.tempat_lahir ? row.tempat_lahir : null,
                    tgl_lahir: row.tgl_lahir ? row.tgl_lahir : null,
                    pendidikan: row.pendidikan ? row.pendidikan : null,
                    jenis_kelamin: row.jenis_kelamin ? row.jenis_kelamin : null,
                    pekerjaan: row.pekerjaan ? row.pekerjaan : null,
                    status: row.status ? row.status : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaAnak)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Tanggal
        let tglLahir = values.tgl_lahir
        if (values.tgl_lahir && values.tgl_lahir instanceof Date) {
            let tgl = values.tgl_lahir.getDate() <= 9 ? "0" + values.tgl_lahir.getDate() : values.tgl_lahir.getDate()
            let bln = values.tgl_lahir.getMonth() <= 9 ? "0" + values.tgl_lahir.getMonth() : values.tgl_lahir.getMonth()
            let thn = values.tgl_lahir.getFullYear()

            tglLahir = tgl + "-" + bln + "-" + thn
        }

        // Payload
        const param = {
            nama: values.nama ? values.nama : null,
            tempat_lahir: values.tempat_lahir ? values.tempat_lahir : null,
            tgl_lahir: tglLahir ? tglLahir : null,
            pendidikan: values.pendidikan ? values.pendidikan : null,
            jenis_kelamin: values.jenis_kelamin ? values.jenis_kelamin : null,
            pekerjaan: values.pekerjaan ? values.pekerjaan : null,
            status: values.status ? values.status : null,
        }

        // Define Network
        let method = "POST"
        let payload = [param]
        let id = this.props.dataID
        if (values.id) {
            method = "PUT"
            payload = param
            id = values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios("tk/anak/" + id, method, payload).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tk/anak/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const { table, values, errors } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Anak"
            visible={this.state.form.visible}
            // width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Anak *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={24}>
                    <FormLabel style={{ fontSize: "94%", lineHeight: "1em" }}>Jenis Kelamin *</FormLabel>
                    <Radio.Group style={{ width: '100%' }}
                        name="jenis_kelamin"
                        value={values.jenis_kelamin || ""}
                        onChange={this.handleChange}
                    >
                        <Row gutter={[16, 16]}>
                            {genderOptions.map((row, i) => (
                                <Col xs={12} sm={8} md={6} key={i}>
                                    <Radio value={row.value}>{row.label}</Radio>
                                </Col>
                            ))}
                        </Row>
                    </Radio.Group>
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Tempat Lahir"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="tempat_lahir"
                        type="text"
                        onChange={this.handleChange}
                        value={values.tempat_lahir || ''}
                        error={this.hasError('tempat_lahir')}
                        helperText={
                            this.hasError('tempat_lahir') ? errors.tempat_lahir[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <MobileDatePicker
                                label="Tanggal lahir"
                                value={values.tgl_lahir || null}
                                onChange={(newValue) => {
                                    this.handleChangeSetValue("tgl_lahir", newValue)
                                }}
                                renderInput={(params) => <TextField
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={this.hasError('tgl_lahir')}
                                    helperText={
                                        this.hasError('tgl_lahir') ? errors.tgl_lahir[0] : null
                                    }
                                    {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={pendidikanFormalJenjangOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pendidikan"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="pendidikan"
                            type="text"
                            error={this.hasError('pendidikan')}
                            helperText={
                                this.hasError('pendidikan') ? errors.pendidikan[0] : null
                            }
                        />}
                        value={values.pendidikan || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("pendidikan", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={anakPekerjaanOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pekerjaan"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="pekerjaan"
                            type="text"
                            error={this.hasError('pekerjaan')}
                            helperText={
                                this.hasError('pekerjaan') ? errors.pekerjaan[0] : null
                            }
                        />}
                        value={values.pekerjaan || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("pekerjaan", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={anakStatusOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Status Anak *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="status"
                            type="text"
                            error={this.hasError('status')}
                            helperText={
                                this.hasError('status') ? errors.status[0] : null
                            }
                        />}
                        value={values.status || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("status", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Anak"
                    extra={
                        <>
                            {/* <Button type="text"><Link to={`/tenaga-kependidikan/guru`}>Kembali</Link></Button> */}
                            <Tooltip title="Tambah">
                                <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </>
                    }

                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={21}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => {
                                const params = getParamTable("search", table, e.target.value)
                                this.fetch(params)
                            }}
                            value={table.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={3}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>

                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Nama Anak',
                                    dataIndex: 'nama',
                                },
                                {
                                    title: 'Tempat Lahir',
                                    dataIndex: 'tempat_lahir',
                                },
                                {
                                    title: 'Tanggal Lahir',
                                    dataIndex: 'tgl_lahir',
                                },
                                {
                                    title: 'Jenis Kelamin',
                                    dataIndex: 'jenis_kelamin',
                                },
                                {
                                    title: 'Pendidikan',
                                    dataIndex: 'pendidikan',
                                },
                                {
                                    title: 'Pekerjaan',
                                    dataIndex: 'pekerjaan',
                                },
                                {
                                    title: 'Status Anak',
                                    dataIndex: 'status',
                                },
                                {
                                    title: <EllipsisOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Anak);
