import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, BackTop } from 'antd';
import { TableList } from 'components/list';
import AuthRedirect from 'components/AuthRedirect';
import Filter from '../modal/Filter';
import ModalDelete from '../modal/Delete';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableWithFilter } from 'components/sorting-table';
import columns from './Columns';
import useKetidakhadiranSiswa from '../hooks/useKetidakhadiranSiswa';
import { currentDate } from 'constant/constant_format';

const { Text } = Typography;
const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const KetidakhadiranSiswa = ({ privilege }) => {
  const {
    table,
    filter,
    deleteState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalDelete,
  } = useKetidakhadiranSiswa(privilege?.tahun_ajaran, privilege?.semester);

  const access = privilege.access["/presensi/ketidakhadiran"];
  if (!access) return <AuthRedirect />;
  const action = access.action;

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Siswa"
        subTitle="Data ketidakhadiran siswa" />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={18} md={19}>
          <SearchTable table={table} fetch={fetchData} />
        </Col>
        <Col xs={24} sm={6} md={5}>
          <SortingTableWithFilter
            table={table}
            fetch={fetchData}
            modalFilter={modalFilter}
            sortFields={sortFields}
          />
        </Col>
        <Col xs={24}>
          <Text italic>Default data yang tampil adalah hari ini, </Text><Text code>{currentDate}</Text>
        </Col>
        <Col xs={24}>
          <TableList
            state={{ table, filter }}
            fetch={fetchData}
            fetchTable={fetchTable}
            columns={columns(action, modalDelete)}
          />
        </Col>
      </Row>
      {filter.visible && (
        <Filter
          visible={filter.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filter.values}
        />
      )}
      {deleteState.visible && (
        <ModalDelete
          data={deleteState.data}
          visible={deleteState.visible}
          hideModal={() => modalDelete(false)}
          fetchTable={fetchTable}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(KetidakhadiranSiswa);