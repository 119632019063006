import React from 'react';
import { Modal, Button, Form } from 'antd';
import { SelectPresenceType } from 'components/select';

const ModalFilter = ({ values, visible, hideModal, setTempTableFilter, setTableFilterFromTemp }) => {
    return (
        <Modal
            title="Filter"
            width={300}
            open={visible}
            onCancel={hideModal}
            footer={<>
                <Button onClick={hideModal}>Tutup</Button>
                <Button type='primary' onClick={setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tipe" name="tipe">
                    <SelectPresenceType
                        allowClear
                        defaultValue={values.tipe}
                        value={values.tipe}
                        onChange={(val) => {
                            setTempTableFilter("tipe", val || null)
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalFilter;
