import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import "./style.css"; // Import CSS
import { _error, _getToken, _setAxios, _success } from "../../lib/Helper";
import config from "../../Config";
const logoMan = require("../../assets/images/logo-man.png");

const Login = () => {

    const [loadingBtn, setLoadingBtn] = React.useState(false);

    const checkAuth = () => {
        if (!_getToken()) {
            _error('topRight', 'Access', 'Gagal mendapatkan akses')
        } else {
            _setAxios("privilege/access", "GET").then(resp => {
                if (resp.status === true) {
                    const profile = resp.data.data.profile
                    const tahunAjaran = {
                        ...resp.data.data.tahun_ajaran,
                        id: 0
                    }
                    if(tahunAjaran === null) {
                        _error('topRight', 'Access', 'Tahun ajaran belum aktif atau semester di tahun ajaran belum aktif')
                    }
                    const blockRoles = ["siswa", "wali siswa", "alumni"];
    
                    tahunAjaran.id = tahunAjaran?.id_tahun_ajaran
                    localStorage.setItem('persist:tahun_ajaran', JSON.stringify(tahunAjaran));
    
                    if (blockRoles.includes(profile.roles.toLowerCase())) {
                        _error('topRight', 'Access', 'Anda tidak memiliki akses ke aplikasi ini')
                        setLoadingBtn(false);
                        localStorage.clear();
                        return false
                    } else {
                        window.location.href = config.base_url
                        return true
                    }
    
                } else {
                    _error('topRight', 'Access', 'Gagal mendapatkan akses')
                    localStorage.clear();
                    setLoadingBtn(false);
                    return false
                }
            })
    
        }
    }
    
    const onFinish = async (values) => {
        try {
            setLoadingBtn(true);
            if (values.remember) {
                localStorage.setItem('persist:remember', JSON.stringify(values));
            }
            _setAxios("login", "POST", values, "data").then(resp => {
                if (resp.status === true) {
                    var token = resp.data.token_type + " " + resp.data.access_token
                    localStorage.setItem('persist:auth', token);
    
                    localStorage.setItem('persist:remember', "");
                    if (values.remember) {
                        localStorage.setItem('persist:remember', JSON.stringify(values));
                    }
    
                    if (!checkAuth()) {
                    } else {
                        _success('topRight', 'Success', resp.data.message)
                    }
                } else {
                    setLoadingBtn(false);
                    localStorage.clear();
                }
            })
    
        } catch (error) {
            // Tangani error dari server
            message.error("Terjadi kesalahan. Silakan coba lagi.");
            console.error(error);
        }
    };

    
    return (
        <div className="layout">
            <div className="left-column"></div>
            <div className="right-column">
                {/* Tambahkan header di luar login container */}
                <div className="header-content">
                    <img
                        src={logoMan}
                        alt="Logo"
                        className="login-logo"
                    />
                    <div className="login-title">Assalamu'alaikum</div>
                    <div className="login-description">
                        Masukkan email dan password yang terdaftar
                    </div>
                </div>
                <div className="login-container">
                    <Form
                        layout="vertical"
                        name="loginForm"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="E-Mail"
                            name="email"
                            rules={[{ required: true, message: "E-Mail wajib diisi" }]}
                        >
                            <Input placeholder="Ex: john.doe@man1gresik.sch.id" />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: "Password wajib diisi" }]}
                        >
                            <Input.Password placeholder="Masukkan password" />
                        </Form.Item>
                        {/* Tambahkan Checkbox Remember Me */}
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingBtn}>
                                Masuk
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="login-footer">
                    Madrasah Digital MAN 1 Gresik @{new Date().getFullYear()}
                </div>

            </div>
        </div>
    );
};

export default Login;
