// useForm.js
import { useState } from 'react';
import { _setAxios, _success, _warn } from 'lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateFormat, dateFormatNumber } from 'constant/constant_format';
dayjs.extend(customParseFormat);

const dateNow = dayjs().format(dateFormat);

const useExport = (privilege, hideModal) => {
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        tahun_ajaran_id: privilege?.tahun_ajaran?.id,
        semester_id: privilege?.semester?.id,
        periode: [dayjs(dateNow, dateFormat), dayjs(dateNow, dateFormat)],
    });

    const handleSubmit = (val) => {
        if (!val.periode && !val.periode[0] && !val.periode[1]) {
            _warn('topRight', 'Error', 'Tolong pilih periode terlebih dahulu')
            return
        }

        setLoading(true);
        const periodeStart = dayjs(val.periode[0]).format(dateFormat);
        const periodeEnd = dayjs(val.periode[1]).format(dateFormat);

        let endpoint = "perizinan/siswa/ekspor";
        let method = "POST";

        _setAxios(endpoint, method, {
            tgl_awal: periodeStart,
            tgl_akhir: periodeEnd
        }).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                hideModal();
                if (!resp.data?.data?.link) {
                    setLoading(false);
                    return
                }
                setTimeout(() => {
                    window.location.href = resp.data?.data?.link
                }, 300);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    return {
        values,
        setValues,
        handleChangeSetValue,
        loading,
        handleSubmit,
    };
};

export default useExport;
