import React, { useCallback } from 'react';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

import { getParamTable } from '../../lib/Helper';
import { isMobile } from 'react-device-detect';

const SearchTable = ({ table, fetch }) => {
  // Create a debounced function for fetch
  const debouncedFetch = useCallback(
    debounce((value) => {
      const params = getParamTable("search", table, value);
      fetch(params, isMobile);
    }, 300), // Adjust debounce delay as needed
    [table, fetch] // Dependencies for useCallback
  );

  const handleChange = (e) => {
    debouncedFetch(e.target.value);
  };

  return (
    <Input
      name="search"
      className="search-table"
      placeholder="Search..."
      prefix={<SearchOutlined className="site-form-item-icon" />}
      onChange={handleChange}
      suffix={
        <Tooltip title="Cari berdasarkan nama">
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      }
    />
  );
};

export default SearchTable;