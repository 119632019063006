import { _setAxios } from 'lib/Helper';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

const dateNow = moment(new Date()).format("YYYY-MM-DD")


const useAbsensi = (id) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const getAbsensiById = useCallback(async (id) => {
        if (!id) {
            return [];
        }
        setLoading(true);
        return await _setAxios("presensi/gtk/temporary/" + id + "/" + dateNow, "GET", null, null, "Data tidak ditemukan").then((resp) => {
            if (resp.status && resp.data) {
                setData(resp.data.data);
            }
            setLoading(false);
            return resp;
        }).catch((err) => {
            setLoading(false);
            return err;
        });
    }, []);

    useEffect(() => {
        getAbsensiById(id)
    }, [getAbsensiById, id]);


    return {
        data,
        loading
    };
};

export default useAbsensi;
