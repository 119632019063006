// ModalForm.js
import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Flex } from 'antd';
import { RangeYear } from 'components/datetime';
import SwitchBasic from 'components/switch/SwitchBasic';
import useModalForm from '../hooks/useModalForm';
import { SelectTipeSemester } from 'components/select';

const ModalForm = ({ data = {}, visible, hideModal, fetchTable }) => {
    const {
        values,
        loading,
        loadingContent,
        handleChangeSetValue,
        handleSubmit,
    } = useModalForm(data, fetchTable, hideModal);

    if (loadingContent) {
        return null;
    }

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Semester"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
                initialValues={values}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Periode *"
                    name="periode"
                    rules={[{ required: true, message: 'Periode wajib diisi' }]}
                >
                    <RangeYear
                        onChange={(e, val) => {
                            const periodeStart = val ? val[0] : null;
                            const periodeEnd = val ? val[1] : null;
                            const valPeriode = periodeStart && periodeEnd ? `${periodeStart}-${periodeEnd}` : null;
                            handleChangeSetValue("periode", valPeriode);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Tipe Semester"
                    name="tipe_semester"
                    rules={[{ required: true, message: 'Tipe semester wajib diisi' }]}
                >
                    <SelectTipeSemester />
                </Form.Item>
                <Row gutter={[16, 0]}>
                    {data.id && (
                        <Col xs={24}>
                            <Form.Item label="Penggunaan *" name="is_active">
                                <Flex>
                                    <SwitchBasic
                                        value={values.is_active}
                                        onChange={(val) => handleChangeSetValue("is_active", val)}
                                    />
                                    &nbsp;Aktifkan jika ingin data tersebut aktif untuk digunakan
                                </Flex>
                            </Form.Item>
                        </Col>
                    )}
                    {data.id && (
                        <Col xs={24}>
                            <Form.Item label="Status *" name="is_deleted">
                                <SwitchBasic
                                    value={values.is_deleted}
                                    onChange={(val) => handleChangeSetValue("is_deleted", val)}
                                />
                                &nbsp;Aktifkan jika ingin data tersebut tampil
                            </Form.Item>
                        </Col>
                    )}
                    <Col xs={24}>
                        <Divider />
                        <Flex justify='end' gap="small" wrap>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" loading={loading}>Simpan</Button>
                        </Flex>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;