import React from 'react';
import { Button, Dropdown } from 'antd';
import { DownCircleOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';

class Action extends React.Component {
    render() {
        const { row, action, modalForm, modalDelete } = this.props;
        const items = [];

        if (!action.update && !action.delete) {
            return "-"
        }

        if (action.update) {
            items.push({
                key: '1',
                label: "Update",
                onClick: () => {
                    modalForm(true, row)
                },
                icon: <EditOutlined />,
            })
        }

        if (action.delete) {
            items.push({
                key: '2',
                label: "Delete",
                icon: <CloseCircleOutlined />,
                onClick: () => modalDelete(true, row),
            })
        }

        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown>
        );
    }
}


export default Action;
