// useForm.js
import { useState } from 'react';
import { _setAxios, _success, _warn } from 'lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateFormat } from 'constant/constant_format';
dayjs.extend(customParseFormat);

const useDeleteFile = (fetchTable, hideModal) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = (val) => {
        if(!val.tgl && !val.tgl[0] && !val.tgl[1]) {
            _warn('topRight', 'Error', 'Tolong pilih tanggal terlebih dahulu')
            return
        }

        setLoading(true);
        const dateStart = dayjs(val.tgl[0]).format(dateFormat);
        const dateEnd = dayjs(val.tgl[1]).format(dateFormat);

        let endpoint = "presensi/gtk/kehadiran/" + dateStart + "/" + dateEnd;
        let method = "DELETE";

        _setAxios(endpoint, method).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        loading,
        handleSubmit,
    };
};

export default useDeleteFile;
