// useForm.js
import { useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';

const useForm = (data, fetchTable, hideModal) => {
    const [values, setValues] = useState({
        ...data
    });
    const [loading, setLoading] = useState(false);

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };


    const handleSubmit = (val) => {

        const params = {
            nama: val?.nama,
            inisial: val?.inisial,
            tipe: val?.tipe,
            is_approve: values?.is_approve === "1" ? 1 : 0,
            keterangan: val?.keterangan
        };

        let endpoint = "perizinan/jenis";
        let method = "POST";

        if (data?.id) { 
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    };
};

export default useForm;
