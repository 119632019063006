import React from 'react';
import { connect } from 'react-redux'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Breadcrumb, Tabs, message, Row, Col, Statistic, Card } from 'antd';
import { PageHeader } from 'components/header';
import AuthRedirect from '../../../components/AuthRedirect'
import { DoubleRightOutlined } from '@ant-design/icons';

import PreloadContent from '../../../components/preload/PreloadContent'
import { isAuth, _setAxios } from '../../../lib/Helper';

import PesertaDidikPointTerbanyak from './PesertaDidikPointTerbanyak'
import PelanggaranSeringDilakukan from './PelanggaranSeringDilakukan';
import TataTertib from './TataTertib';
import Pelanggaran from './pelanggaran/Pelanggaran';
import Penghargaan from './Penghargaan';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const { TabPane } = Tabs;
const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

class TataTertibSekolah extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIdx: "0",
            graph: {
                loading: true,
                pelanggaran: []
            },
            total: {
                pelanggaran: 0,
                siswa_melanggar: 0,
            }
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : "0"
        })
        this.getPelanggaranGraph()
        this.getTotalPelanggaran()
    }

    getPelanggaranGraph = () => {
        _setAxios("tatibsi/dashboard/grafik", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    graph: {
                        ...this.state.graph,
                        loading: false,
                        pelanggaran: resp.data.data
                    },
                })
            } else {
                this.setState({
                    graph: {
                        ...this.state.graph,
                        loading: false,
                    },
                })
            }
        })
    }
    getTotalPelanggaran = () => {
        _setAxios("tatibsi/dashboard/label-header", "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    total: {
                        pelanggaran: data.total_pelanggaran,
                        siswa_melanggar: data.total_siswa_melanggar,
                    }
                })
            }
        })
    }


    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.error('Data Rombel wajib disimpan terlebih dahulu.');
                return
            }
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIdx: key
        })
    }

    render() {
        const access = this.props.privilege.access["/kesiswaan/tata-tertib-sekolah"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.view === false) {
            return <AuthRedirect />
        }
        
        const { graph } = this.state
        const steps = [
            {
                title: 'Tata Tertib',
                content: <TataTertib action={action}/>,
            },
            {
                title: 'Pelanggaran',
                content: <Pelanggaran action={action}/>,
            },
            {
                title: 'Penghargaan',
                content: <Penghargaan action={action}/>,
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kesiswaan</Breadcrumb.Item>
                        <Breadcrumb.Item>Tata Tertib Sekolah</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title={`Tata Tertib`}
                        subTitle={`Data tata tertib sekolah`}
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={16}>
                            {
                                this.state.graph.pelanggaran.loading ? <PreloadContent /> :
                                    <Bar options={options} data={{
                                        "labels": graph.pelanggaran.labels ? graph.pelanggaran.labels : [],
                                        "datasets": graph.pelanggaran.dataset ? graph.pelanggaran.dataset : []
                                    }} />
                            }
                        </Col>
                        <Col xs={24} sm={8}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Statistic title="Total Pelanggaran" value={`${this.state.total.pelanggaran} Kasus`} />
                                </Col>
                                <Col xs={24}>
                                    <Statistic title="Total Peserta Didik yang Melanggar" value={`${this.state.total.siswa_melanggar} Anak`} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Card title="10 Peserta Didik Point Terbanyak">
                                <PesertaDidikPointTerbanyak />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Card title="10 Pelanggaran Sering Dilakukan">
                                <PelanggaranSeringDilakukan />
                            </Card>
                        </Col>
                        <Col xs={24}>
                            <Tabs defaultActiveKey="0" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                                {steps.map((item, i) => (
                                    <TabPane key={i} tab={item.title}>
                                        {steps[i].content}
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(TataTertibSekolah);
