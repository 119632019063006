import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";
import { LabelIsActive, LabelIsUsed } from "components/labels";

const columns = (action, modalForm, modalDelete) => [
    {
      title: 'No',
      dataIndex: 'rownum',
      width: '2%',
    },
    {
      title: 'Periode',
      dataIndex: 'tahun_ajaran',
    },
    {
      title: 'Penggunaan',
      render: (a, row) => <LabelIsUsed isUsed={row.is_active === "1" ? true : false} />,
    },
    {
      title: 'Status',
      render: (a, row) => <LabelIsActive isActive={row.is_deleted === "0" ? true : false} />,
    },
    {
      title: <SettingOutlined />,
      align: "center",
      key: 'operation',
      width: '2%',
      render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete} />,
    },
    {
      title: 'LogActivity',
      key: 'logactivity',
      width: '10%',
      render: (a, row) => _tableLogActivity(row),
    },
  ];
  
  export default columns