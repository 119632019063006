import React from 'react';
import { connect } from 'react-redux'
import { _setAxios, _success, _validationFormMsg } from '../../../lib/Helper';
import { jenisRombelOptions, tingkatRombelOptions } from '../../../data/options';
import { schemaRombelForm } from './schema';
import validate from 'validate.js';
import { Row, Col, Button, List, Tooltip } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'react-router-dom';
import PreloadContent from '../../../components/preload/PreloadContent'

class RombelForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddl: {
                kurikulum: [],
                jurusan: [],
                waliKelas: [],
                ruangKelas: [],
            },
            values: {},
            errors: {},
            id: null,
            loadingBtn: false,
            typeForm: "create",
            dataDetail: {
                loading: true,
                data1: [],
                data2: [],
                data3: []
            }
        };
    }

    componentDidMount() {
        this.getKurikulumDDL()
        this.getJurusanDDL()
        this.getWaliKelasDDL()
        this.getRuangKelasDDL()

        if (this.props.typeForm !== "create") {
            this.getRombelByID(this.props.match.params.id)
        }

        this.setState({
            typeForm: this.props.typeForm
        })

    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;

        this.setForm(values, name, value)
    };

    setForm = (values, name, value, row = {}) => {
        values[name] = value;

        const errors = validate(values, schemaRombelForm);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaRombelForm);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getRombelByID = (id) => {
        _setAxios("rombel/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const rombel = resp.data.data
                this.setState({
                    values: {
                        tahun_ajaran: rombel.thn_awal+"/"+rombel.thn_akhir+" - "+rombel.tipe_semester,
                        tahun_ajaran_id: rombel.tahun_ajaran_id,
                        wali_id: rombel.tk_id ? {
                            id: rombel.tk_id,
                            nama_lengkap: rombel.tk_nama,
                        } : null,
                        kurikulum_id: rombel.kurikulum_id ? {
                            id: rombel.kurikulum_id,
                            nama: rombel.kurikulum_nama,
                        } : null,
                        jurusan_id: rombel.jurusan_id ? {
                            id: rombel.jurusan_id,
                            nama: rombel.jurusan_nama,
                        } : null,
                        ruangan_id: rombel.ruangan_id ? {
                            id: rombel.ruangan_id,
                            nama: rombel.ruangan_nama,
                        } : null,
                        nama: rombel.nama ? rombel.nama : null,
                        tingkatan_kelas: rombel.tingkatan_kelas ? rombel.tingkatan_kelas : null,
                        jenis_rombel: rombel.jenis_rombel ? rombel.jenis_rombel : null,
                    },
                    dataDetail: {
                        loading: false,
                        data1: [
                            {
                                title: 'Tahun Ajaran',
                                description: rombel.thn_awal + "/" + rombel.thn_akhir,
                            },
                            {
                                title: 'Nama Rombel',
                                description: rombel.nama ? rombel.nama : "-",
                            },
                            {
                                title: 'Kurikulum',
                                description: rombel.kurikulum_nama ? rombel.kurikulum_nama : "-",
                            }
                        ],
                        data2: [
                            {
                                title: 'Semester',
                                description: rombel.tipe_semester ? rombel.tipe_semester : "-",
                            },
                            {
                                title: 'Wali Kelas',
                                description: rombel.tk_nama ? rombel.tk_nama : "-",
                            },
                            {
                                title: 'Jurusan',
                                description: rombel.jurusan_nama ? rombel.jurusan_nama : "-",
                            }
                        ],
                        data3: [
                            {
                                title: 'Tingkat Kelas',
                                description: rombel.tingkatan_kelas ? rombel.tingkatan_kelas : "-",
                            },
                            {
                                title: 'Nama Ruangan',
                                description: rombel.ruangan_nama ? rombel.ruangan_nama : "-",
                            },
                            {
                                title: 'Jenis Rombel',
                                description: rombel.jenis_rombel ? rombel.jenis_rombel : "-",
                            }
                        ],
                    },
                    loadingBtn: false,
                    typeForm: "detail"
                })
            } else {
                this.setState({
                    dataDetail: {
                        loading: false
                    }
                })
            }
        })
    }

    getKurikulumDDL = () => {
        _setAxios("kurikulum", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        kurikulum: resp.data.data
                    },
                })
            }
        })
    }

    getJurusanDDL = () => {
        _setAxios("jurusan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jurusan: resp.data.data
                    },
                })
            }
        })
    }

    getWaliKelasDDL = () => {
        _setAxios("tk/dropdown/Guru", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        waliKelas: resp.data.data
                    },
                })
            }
        })
    }

    getRuangKelasDDL = () => {
        _setAxios("ruangan/dropdown", "POST", {
            jenis_ruangan_id: 5,
            is_booking: 0
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        ruangKelas: resp.data.data
                    },
                })
            }
        })
    }

    onSubmitForm = async () => {
        const { values } = this.state;
        const { privilege } = this.props
        const { tahun_ajaran } = privilege

        //Validation Siswa
        let validation = this.setValidate(values, schemaRombelForm)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        const params = {
            wali_id: values.wali_id ? values.wali_id.id : null,
            tahun_ajaran_id: this.state.typeForm === "create" ? tahun_ajaran?.id : values.tahun_ajaran_id,
            kurikulum_id: values.kurikulum_id ? values.kurikulum_id.id : null,
            jurusan_id: values.jurusan_id ? values.jurusan_id.id : null,
            ruangan_id: values.ruangan_id ? values.ruangan_id.id : null,
            nama: values.nama ? values.nama : null,
            tingkatan_kelas: values.tingkatan_kelas ? values.tingkatan_kelas : null,
            jenis_rombel: values.jenis_rombel ? values.jenis_rombel : null,
        }

        this.setState({
            loadingBtn: true
        });

        let method = "POST"
        let endpoint = "rombel"
        if (this.state.typeForm === "update") {
            method = "PUT"
            endpoint = "rombel/" + this.props.match.params.id
        }
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', 'Data berhasil disimpan')

                const id = resp.data.data.id
                if (this.state.typeForm === "update") {
                    this.getRombelByID(this.props.match.params.id)
                } else {
                    this.setState({
                        id: id,
                        loadingBtn: false,
                        typeForm: "create"
                    });
                    this.props.onGetID(id);
                }

            } else {
                this.setState({
                    loadingBtn: false
                });
            }
        })
    };

    render() {

        const { ddl, values, errors, loadingBtn, typeForm, dataDetail } = this.state;
        const { privilege } = this.props
        let tahunAjaran = privilege.tahun_ajaran ? privilege.tahun_ajaran.thn_awal + "/" + privilege.tahun_ajaran.thn_akhir + " - " + privilege.tahun_ajaran.tipe_semester : null
        if (typeForm !== "create") {
            tahunAjaran = values.tahun_ajaran
        }

        if (typeForm === "detail") {
            return (
                <>
                    <Row>
                        {
                            dataDetail.loading ? <Col xs={24}><PreloadContent /></Col> :
                                <>
                                    <Col xs={24} style={{ textAlign: "right" }}>
                                        <Tooltip placement="top" title={"Ubah data diri"}>
                                            <Button shape="circle" icon={<EditOutlined />} onClick={() => {
                                                this.setState({
                                                    typeForm: "update"
                                                })
                                            }}></Button>
                                        </Tooltip>
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={dataDetail.data2}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={dataDetail.data3}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={dataDetail.data1}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                </>
                        }
                    </Row>
                </>
            )
        } else {
            return (
                <>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <TextField
                                        fullWidth
                                        label="Tahun Ajaran"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="tahun_ajaran_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={tahunAjaran || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Autocomplete
                                        options={jenisRombelOptions}
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Jenis Rombel *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="jenis_rombel"
                                            type="text"
                                            helperText={
                                                this.hasError('jenis_rombel') ? errors.jenis_rombel[0] : null
                                            }
                                            error={this.hasError('jenis_rombel')}
                                        />}
                                        value={values.jenis_rombel || ''}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("jenis_rombel", newValue ? newValue.label : null)
                                        }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <TextField
                                        fullWidth
                                        label="Nama Rombel *"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="nama"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={values.nama || ''}
                                        helperText={
                                            this.hasError('nama') ? errors.nama[0] : null
                                        }
                                        error={this.hasError('nama')}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Autocomplete
                                        options={ddl.kurikulum}
                                        getOptionLabel={(option) =>
                                            option.nama
                                        }
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Kurikulum *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="kurikulum_id"
                                            type="text"
                                            error={this.hasError('kurikulum_id')}
                                            helperText={
                                                this.hasError('kurikulum_id') ? errors.kurikulum_id[0] : null
                                            }
                                        />}
                                        value={values.kurikulum_id || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("kurikulum_id", newValue ? newValue : null)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Row gutter={[16, 16]}>
                                <Col xs={8}>
                                    <Autocomplete
                                        options={tingkatRombelOptions}
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Tingkat *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="tingkatan_kelas"
                                            type="text"
                                            helperText={
                                                this.hasError('tingkatan_kelas') ? errors.tingkatan_kelas[0] : null
                                            }
                                            error={this.hasError('tingkatan_kelas')}
                                        />}
                                        value={values.tingkatan_kelas || ''}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("tingkatan_kelas", newValue ? newValue.label : null)
                                        }}
                                    />
                                </Col>
                                <Col xs={16}>
                                    <Autocomplete
                                        options={ddl.jurusan}
                                        getOptionLabel={(option) =>
                                            option.nama
                                        }
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Jurusan *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="jurusan_id"
                                            type="text"
                                            error={this.hasError('jurusan_id')}
                                            helperText={
                                                this.hasError('jurusan_id') ? errors.jurusan_id[0] : null
                                            }
                                        />}
                                        value={values.jurusan_id || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("jurusan_id", newValue ? newValue : null)
                                        }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Autocomplete
                                        options={ddl.waliKelas}
                                        getOptionLabel={(option) =>
                                            (option.gelar_depan ? option.gelar_depan + ". " : "") + option.nama_lengkap + (option.gelar_belakang ? ". " + option.gelar_belakang : "")
                                        }
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Wali Kelas *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="wali_id"
                                            type="text"
                                            error={this.hasError('wali_id')}
                                            helperText={
                                                this.hasError('wali_id') ? errors.wali_id[0] : null
                                            }
                                        />}
                                        value={values.wali_id || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("wali_id", newValue ? newValue : null)
                                        }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Autocomplete
                                        options={ddl.ruangKelas}
                                        getOptionLabel={(option) =>
                                            option.nama
                                        }
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Ruang Kelas *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="ruangan_id"
                                            type="text"
                                            error={this.hasError('ruangan_id')}
                                            helperText={
                                                this.hasError('ruangan_id') ? errors.ruangan_id[0] : null
                                            }
                                        />}
                                        value={values.ruangan_id || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("ruangan_id", newValue ? newValue : null)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Button type='text'><Link to={`/kurikulum/rombel`}>Kembali</Link></Button> &nbsp;
                            {this.state.id ?
                                <Button type="primary" icon={<SaveOutlined />} style={{ float: "right" }} disabled={true}>Simpan</Button> :
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingBtn} onClick={this.onSubmitForm} style={{ float: "right" }}>Simpan</Button>}
                        </Col>
                    </Row>

                </>

            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(RombelForm);
