import React from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, BackTop, Button } from 'antd';
import { PageHeader } from 'components/header';

import { _setAxios, getParamTable } from '../../../../lib/Helper';

import Form from '../modal/Form';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SortingTableDesktop from '../../../../components/sorting-table/SortingTableDesktop';
import SortingTableMobile from '../../../../components/sorting-table/SortingTableMobile';
import SearchTable from '../../../../components/search-table/SearchTable';
import { PlusOutlined } from '@ant-design/icons';
import Delete from '../modal/Delete';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}]

class LembagaDocument extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        endpoint: "lembaga/dokumen/table",
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      form: {
        data: {},
        visible: false
      },
      delete: {
        data: {},
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    const { table } = this.state

    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios(table.endpoint, "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }

  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: data,
        visible: visible
      },
    })
  }

  render() {
    const { table } = this.state;
    const { access } = this.props;
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Dokumen"
          subTitle="Data dokumen lembaga"
          extra={[
            access.action.create && <Button icon={<PlusOutlined />} type='primary' onClick={() => this.modalForm(true)}> Tambah</Button>,
          ]}
        />
        <Row gutter={[16, 16]}>
          {/* SEARCH LIST/TABLE */}
          <Col xs={24} sm={18} md={19} >
            <SearchTable table={table} fetch={this.fetch} />
          </Col>

          {/* SORTING LIST/TABLE */}
          <Col xs={24} sm={6} md={5}>
            <SortingTableMobile isFilter={true} table={table} fetch={this.fetch} sortFields={sortFields} />
            <SortingTableDesktop table={table} fetch={this.fetch} sortFields={sortFields} />
          </Col>

          {/* DATA LIST/TABLE */}
          <Col xs={24}>
            <BrowserView>
              <Desktop
                access={access}
                state={this.state}
                fetch={this.fetch}
                fetchTable={this.fetchTable}
                modalForm={this.modalForm}
                modalDelete={this.modalDelete}
              />
            </BrowserView>
            <MobileView>
              <Mobile
                access={access}
                state={this.state}
                fetch={this.fetch}
                fetchTable={this.fetchTable}
                modalForm={this.modalForm}
                modalDelete={this.modalDelete}
              />
            </MobileView>
          </Col>
        </Row>
        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

export default LembagaDocument;
