import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, _getDate, _getFile, _beforeUploadFile } from '../../../lib/Helper';
import validate from 'validate.js';
import AuthRedirect from '../../../components/AuthRedirect'

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Menu, Dropdown, Input, Tooltip, Modal, Breadcrumb, List, Upload } from 'antd';
import { PageHeader } from 'components/header';
import { SaveOutlined, DownloadOutlined, InfoCircleOutlined, SearchOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, DoubleRightOutlined, InboxOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import { hasilTesKategoriOptions } from '../../../data/options';
import { Link } from 'react-router-dom';

const { Dragger } = Upload;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

const schema = {}

class HasilTestPesertaDidikDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            dataDetail: {
                data1: [
                    {
                        title: "NISN",
                        description: "-",
                    },
                    {
                        title: "Nama Peserta Didik",
                        description: "-",
                    },
                    {
                        title: "Jenis Kelamin",
                        description: "-",
                    },
                    {
                        title: "Rombel",
                        description: "-",
                    },
                ],
                total_poin: 0,
            },
            form: {
                visible: false,
                loading: false,
            },
            ddl: {
                pelanggaran: []
            },
            fileList: [],
            validateFile: false
        };
    }

    componentDidMount() {
        this.getDetailSiswa()
        this.fetchTable()
    }

    getDetailSiswa = () => {
        _setAxios("bk/hasil-tes-siswa/table-header/" + this.props.match.params.id + "/" + this.props.match.params.rombel_id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    dataDetail: {
                        data1: [
                            {
                                title: "NISN",
                                description: data.nisn ? data.nisn : "-",
                            },
                            {
                                title: "Nama Peserta Didik",
                                description: data.nama ? data.nama : "-",
                            },
                            {
                                title: "Jenis Kelamin",
                                description: data.jenis_kelamin ? data.jenis_kelamin : "-",
                            },
                            {
                                title: "Rombel",
                                description: data.rombel_nama ? data.rombel_nama : "-",
                            },
                        ],
                    }
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("bk/hasil-tes-siswa/table-riwayat/" + this.props.match.params.id, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadFile(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    tgl: row.tgl ? row.tgl : null,
                    kategori_tes: row.kategori_tes ? row.kategori_tes : null,
                    keterangan: row.keterangan ? row.keterangan : null,
                    tahun_ajaran_id: row.tahun_ajaran_id ? row.tahun_ajaran_id : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state
        const { privilege } = this.props
        const { tahun_ajaran } = privilege

        //Validation 
        let validation = this.setValidate(values, schema)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        let tgl = _getDate(values.tgl)
        let file = await _getFile(this.state.fileList)

        // Payload
        const param = {
            tgl: tgl,
            dokumen: file,
            siswa_id: this.props.match.params.id,
            rombel_id: this.props.match.params.rombel_id,
            kategori_tes: values.kategori_tes ? values.kategori_tes : null,
            keterangan: values.keterangan ? values.keterangan : null,
            tahun_ajaran_id: tahun_ajaran?.id,
        }

        // Define Network
        let method = "POST"
        let endpoint = "bk/hasil-tes-siswa"
        if (values.id) {
            param.tahun_ajaran_id = values.tahun_ajaran_id ? values.tahun_ajaran_id : null
            method = "PUT"
            endpoint = "bk/hasil-tes-siswa/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("bk/hasil-tes-siswa/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/hasil-tes"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.detail === false) {
            return <AuthRedirect />
        }

        const { table, values, errors, dataDetail } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Hasil Tes"
            visible={this.state.form.visible}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>

                <Col xs={24}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <MobileDatePicker
                                label="Tanggal"
                                inputFormat="dd-MM-yyyy"
                                value={values.tgl || null}
                                onChange={(newValue) => {
                                    this.handleChangeSetValue("tgl", newValue)
                                }}
                                renderInput={(params) => <TextField
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={this.hasError('tgl')}
                                    helperText={
                                        this.hasError('tgl') ? errors.tgl[0] : null
                                    }
                                    {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={hasilTesKategoriOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kategori *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="kategori_tes"
                            type="text"
                            error={this.hasError('kategori_tes')}
                            helperText={
                                this.hasError('kategori_tes') ? errors.kategori_tes[0] : null
                            }
                        />}
                        value={values.kategori_tes || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("kategori_tes", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        multiline
                        fullWidth
                        label="Keterangan"
                        size="small"
                        minRows={2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="keterangan"
                        type="text"
                        onChange={this.handleChange}
                        value={values.keterangan || ''}
                        error={this.hasError('keterangan')}
                        helperText={
                            this.hasError('keterangan') ? errors.keterangan[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.fileList}
                        onChange={this.handleChangeFile}
                        beforeUpload={this.beforeUploadFile}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                    </Dragger>
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/bimbingan-konseling/hasil-tes`}>Hasil Tes Peserta Didik</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <PageHeader
                        className="site-page-header"
                        title="Hasil Tes Peserta Didik Detail"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={18}>
                            <List
                                itemLayout="horizontal"
                                dataSource={dataDetail.data1}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                        <Col xs={24}>
                            <PageHeader
                                className="site-page-header"
                                title="Riwayat Hasil Tes"
                                extra={
                                    <>
                                        <Tooltip title="Tambah">
                                            <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                                        </Tooltip>
                                    </>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Tanggal',
                                        dataIndex: 'tgl',
                                    },
                                    {
                                        title: 'Kategori',
                                        dataIndex: 'kategori_tes',
                                    },
                                    {
                                        title: 'Keterangan',
                                        dataIndex: 'keterangan',
                                    },
                                    {
                                        title: 'Dokumentasi',
                                        dataIndex: 'file',
                                        render: (a, row) => row.dokumen ? <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.dokumen} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-",
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                            <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(HasilTestPesertaDidikDetail);
