import React from 'react';
import { connect } from 'react-redux'
import { _success, _validationFormMsg, _setAxios, _warn } from '../../../../lib/Helper';
import { statusKehidupanOptions, pendidikanOptions, pekerjaanOptions, penghasilanOptions } from '../../../../data/options';
import { schemaAyahMasihHidup, schemaAyahSudahMeninggal, schemaAyahTidakDiketahui, schemaIbuMasihHidup, schemaIbuSudahMeninggal, schemaIbuTidakDiketahui } from './schema';
import validate from 'validate.js';
import { Button, Row, Col, Divider, Radio, Skeleton, List, Tooltip, Alert } from 'antd';
import { SaveOutlined, EditOutlined } from '@ant-design/icons';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';

class OrangTua extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dataOrangTua: {
        ayah: [],
        ibu: [],
        // wali: []
      },
      loading: true,
      isUpdate: false,
      loadingBtn: false,
      values: {},
      errors: {},
      isValid: false,

      ddl: {
        regionsAyah: [],
        regionsIbu: [],
        villagesAyah: [],
        villagesIbu: [],
      },

      dataID: this.props.dataID

    };
  }

  componentDidMount() {
    this.getOrangTua()
  }

  getOrangTua = () => {
    this.setState({
      loading: true
    })

    _setAxios("siswa/data-orang-tua/" + this.state.dataID, "GET").then(resp => {
      if (resp.data.status === true) {
        if (resp.data.data.length > 0) {
          let ayah = []
          let ibu = []
          let values = {}

          resp.data.data.forEach(row => {
            let data = [
              {
                title: "Statu Hidup",
                description: row.status_hidup ? row.status_hidup : "-",
              },
              {
                title: "Nama",
                description: row.nama ? row.nama : "-",
              },
              {
                title: "NIK",
                description: row.nik ? row.nik : "-",
              },
              {
                title: "Pendidikan",
                description: row.pendidikan ? row.pendidikan : "-",
              },
              {
                title: "Pekerjaan",
                description: row.pekerjaan ? row.pekerjaan : "-",
              },
              {
                title: "Penghasilan",
                description: row.penghasilan ? row.penghasilan : "-",
              },
              {
                title: "No.Whatsapp",
                description: row.no_telp ? row.no_telp : "-",
              },
            ]

            if (row.tipe === "Ayah") {
              ayah = data
              values = {
                ...values,
                ayah_id: row.id ? row.id : null,
                ayah_status: row.status_hidup ? row.status_hidup : null,
                ayah_nama: row.nama ? row.nama : null,
                ayah_nik: row.nik ? row.nik : null,
                ayah_pendidikan: row.pendidikan ? {
                  value: row.pendidikan,
                  label: row.pendidikan,
                } : null,
                ayah_pekerjaan: row.pekerjaan ? {
                  value: row.pekerjaan,
                  label: row.pekerjaan,
                } : null,
                ayah_penghasilan: row.penghasilan ? {
                  value: row.penghasilan,
                  label: row.penghasilan,
                } : null,
                ayah_no_whatsapp: row.no_telp ? row.no_telp : null,
              }
            } else if (row.tipe === "Ibu") {
              ibu = data
              values = {
                ...values,
                ibu_id: row.id ? row.id : null,
                ibu_status: row.status_hidup ? row.status_hidup : null,
                ibu_nama: row.nama ? row.nama : null,
                ibu_nik: row.nik ? row.nik : null,
                ibu_pendidikan: row.pendidikan ? {
                  value: row.pendidikan,
                  label: row.pendidikan,
                } : null,
                ibu_pekerjaan: row.pekerjaan ? {
                  value: row.pekerjaan,
                  label: row.pekerjaan,
                } : null,
                ibu_penghasilan: row.penghasilan ? {
                  value: row.penghasilan,
                  label: row.penghasilan,
                } : null,
                ibu_no_whatsapp: row.no_telp ? row.no_telp : null,
              }
            }
          }
          );

          if (ibu.length === 0) {
            values = {
              ...values,
              ibu_status: "Masih Hidup",
            }
          }
          if (ayah.length === 0) {
            values = {
              ...values,
              ayah_status: "Masih Hidup",
            }
          }

          this.setState({
            isUpdate: false,
            loading: false,
            dataOrangTua: {
              ayah: ayah,
              ibu: ibu,
            },
            values: values

          })
        }
      } else {
        this.setState({
          isUpdate: false,
          loading: false,
        })
      }
    })
  }

  // DDL START ------------------------------------------------------------------------------------------------------------------------

  getRegionDDL = (name, search = "") => {
    _setAxios("region", "POST", {
      "pagination": {
        "current": 1,
        "pageSize": 10
      },
      "search": search
    }).then(resp => {
      if (resp.status === true) {
        switch (name) {
          case "ayah_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                regionsAyah: resp.data.list
              }
            });
            break;
          case "ibu_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                regionsIbu: resp.data.list
              }
            });
            break;
          case "wali_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                regionsWali: resp.data.list
              }
            });
            break;

          default:
            break;
        }
      }
    })
  }

  getVillageDDL = (name, districtID) => {
    _setAxios("region/" + districtID, "POST", {
      pagination: {
        current: 1,
        pageSize: 30
      },
    }).then(resp => {
      if (resp.status === true) {
        switch (name) {
          case "ayah_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                villagesAyah: resp.data.data
              }
            });
            break;
          case "ibu_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                villagesIbu: resp.data.data
              }
            });
            break;

          default:
            break;
        }
      }
    })
  }
  // DDL END ---------------------------------------------------------------------------------------------------------------------------

  // HANDLE FORM START ---------------------------------------------------------------------------------------------------------------------------
  handleChange = (e) => {
    const { values } = this.state;
    const { name, value } = e.target;

    if (name === "ayah_domisili" && value === "Luar Negeri") {
      values.ayah_region = null
      values.ayah_village = null
      values.ayah_kode_pos = null
    } else if (name === "ibu_domisili" && value === "Luar Negeri") {
      values.ibu_region = null
      values.ibu_village = null
      values.ibu_kode_pos = null
    }

    this.setForm(values, name, value)
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;

    if (name === "region" || name === "ayah_region" || name === "ibu_region") {
      switch (name) {
        case "region":
          values["village"] = null;
          break;
        case "ayah_region":
          values["ayah_village"] = null;
          break;
        case "ibu_region":
          values["ibu_village"] = null;
          break;

        default:
          break;
      }
      if (value) {
        this.getVillageDDL(name, value.kecamatan_id)
      }
    }

    this.setForm(values, name, value)
  };

  setForm = (values, name, value, row = {}) => {
    values[name] = value;

    this.setValidate(values)
  }

  setValidate = (values) => {
    const schema = this.getSchemaParent(values)
    const errors = validate(values, schema);
    this.setState({
      values: values,
      errors: errors || {},
    });

    return errors
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }
  // HANDLE FORM END ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  getSchemaParent = (values) => {
    let schema = {}

    if (values.ayah_nama || values.ayah_nik || values.ayah_status || values.ayah_domisili || values.ayah_kewarganegaraan || values.ayah_tempat_lahir || values.ayah_tgl_lahir || values.ayah_pekerjaan || values.ayah_pendidikan || values.ayah_penghasilan || values.ayah_pendidikan || values.ayah_no_whatsapp) {
      if (values.ayah_status === "Masih Hidup") {
        schema = { ...schema, ...schemaAyahMasihHidup }
      } else if (values.ayah_status === "Sudah Meninggal") {
        schema = { ...schema, ...schemaAyahSudahMeninggal }
      } else {
        schema = { ...schema, ...schemaAyahTidakDiketahui }
      }
    }
    if (values.ibu_nama || values.ibu_nik || values.ibu_status || values.ibu_domisili || values.ibu_kewarganegaraan || values.ibu_tempat_lahir || values.ibu_tgl_lahir || values.ibu_pekerjaan || values.ibu_pendidikan || values.ibu_penghasilan || values.ibu_pendidikan || values.ibu_no_whatsapp) {
      if (values.ibu_status === "Masih Hidup") {
        schema = { ...schema, ...schemaIbuMasihHidup }
      } else if (values.ibu_status === "Sudah Meninggal") {
        schema = { ...schema, ...schemaIbuSudahMeninggal }
      } else {
        schema = { ...schema, ...schemaIbuTidakDiketahui }
      }
    }

    return schema
  }

  onSubmitIbu = () => {
    const { values } = this.state;
    const params = {
      status_hidup: values.ibu_status ? values.ibu_status : null,
      nama: values.ibu_nama ? values.ibu_nama : null,
      nik: values.ibu_nik ? values.ibu_nik : null,
      pendidikan: values.ibu_status === "Masih Hidup" ? (values.ibu_pendidikan ? values.ibu_pendidikan.value : null) : null,
      pekerjaan: values.ibu_status === "Masih Hidup" ? (values.ibu_pekerjaan ? values.ibu_pekerjaan.value : null) : null,
      penghasilan: values.ibu_status === "Masih Hidup" ? (values.ibu_penghasilan ? values.ibu_penghasilan.value : null) : null,
      no_telp: values.ibu_status === "Masih Hidup" ? (values.ibu_no_whatsapp ? values.ibu_no_whatsapp : null) : null,
      tipe: "Ibu",
    }

    if (values.ibu_id) {
      _setAxios("ppdb/siswa/data-orang-tua/" + values.ibu_id, "PUT", params).then(resp => {
        if (resp.status === true) {
          _success('topRight', 'Success', 'Data Ibu berhasil diupdate')
          this.setState({
            isUpdate: false,
            loadingBtn: false
          });
          this.getOrangTua()
        } else {
          this.setState({
            loadingBtn: false
          });
        }
      })
    } else {
      if (params.status_hidup) {
        _setAxios("ppdb/siswa/data-orang-tua/" + this.state.dataID, "POST", params).then(resp => {
          if (resp.status === true) {
            _success('topRight', 'Success', 'Data Ibu berhasil disimpan')
            this.setState({
              isUpdate: false,
              loadingBtn: false
            });
            this.getOrangTua()
          } else {
            this.setState({
              loadingBtn: false
            });
          }
        })
      } else {
        this.setState({
          loadingBtn: false
        });
        _warn('topRight', 'Gagal', 'Data Ibu tidak ada data yang diupdate')
      }
    }
  }

  onSubmitAyah = () => {
    const { values } = this.state;

    //Validation Orang Tua
    const schema = this.getSchemaParent(values)
    const validation = this.setValidate(values, schema)
    if (validation !== undefined) {
      return _validationFormMsg(validation)
    }

    const params = {
      status_hidup: values.ayah_status ? values.ayah_status : null,
      nama: values.ayah_nama ? values.ayah_nama : null,
      nik: values.ayah_nik ? values.ayah_nik : null,
      pendidikan: values.ayah_status === "Masih Hidup" ? (values.ayah_pendidikan ? values.ayah_pendidikan.value : null) : null,
      pekerjaan: values.ayah_status === "Masih Hidup" ? (values.ayah_pekerjaan ? values.ayah_pekerjaan.value : null) : null,
      penghasilan: values.ayah_status === "Masih Hidup" ? (values.ayah_penghasilan ? values.ayah_penghasilan.value : null) : null,
      no_telp: values.ayah_status === "Masih Hidup" ? (values.ayah_no_whatsapp ? values.ayah_no_whatsapp : null) : null,
      tipe: "Ayah",
    }

    this.setState({
      loadingBtn: true,
    })

    if (values.ayah_id) {
      _setAxios("ppdb/siswa/data-orang-tua/" + values.ayah_id, "PUT", params).then(resp => {
        if (resp.status === true) {
          _success('topRight', 'Success', 'Data Ayah berhasil diupdate')
          this.onSubmitIbu()
        } else {
          this.setState({
            loadingBtn: false
          });
        }
      })
    } else {
      if (params.status_hidup) {
        _setAxios("ppdb/siswa/data-orang-tua/" + this.state.dataID, "POST", params).then(resp => {
          if (resp.status === true) {
            _success('topRight', 'Success', 'Data Ayah berhasil disimpan')
            this.onSubmitIbu()
          } else {
            this.setState({
              loadingBtn: false
            });
          }
        })
      } else {
        this.onSubmitIbu()
        _warn('topRight', 'Gagal', 'Data Ayah tidak ada data yang diupdate')
      }
    }
  }

  render() {
    const action = this.props.action
    const { loading, dataOrangTua, errors, values } = this.state;
    const { isOpen } = this.props.dataState;

    const compDetail = <>
      <Row gutter={[16, 16]}>
        {
          !loading && isOpen && (action.update || action.update_partial) ?
            <Col xs={24} style={{ textAlign: "right" }}>
              <Tooltip placement="top" title={"Ubah data diri"}>
                <Button shape="circle" icon={<EditOutlined />} onClick={() => {
                  this.setState({
                    isUpdate: true
                  })
                }}></Button>
              </Tooltip>
            </Col>
            : ""
        }
        <Col xs={24} sm={12}>
          <Divider orientation="left"><h6>DATA AYAH KANDUNG</h6></Divider>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataOrangTua.ayah}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>
        <Col xs={24} sm={12}>
          <Divider orientation="left"><h6>DATA IBU KANDUNG</h6></Divider>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataOrangTua.ibu}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>
      </Row>
    </>

    const form = <Row gutter={[16, 16]}>
      <Col xs={24} sm={12}>
        <Card className='form-box'>
          <CardContent>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={24}>
                    <Alert message="Data Ayah kandung" type="info" showIcon />
                  </Col>
                  <Col xs={24}>
                    <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Status</FormLabel>
                    <Radio.Group style={{ width: '100%' }}
                      name="ayah_status"
                      value={values.ayah_status || ""}
                      onChange={this.handleChange}
                    >
                      <Row gutter={[16, 16]}>
                        {statusKehidupanOptions.map((row, i) => (
                          <Col xs={12} sm={8} md={6} key={i}>
                            <Radio value={row.value}>{row.label}</Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Col>

                  <Col xs={24}>
                    <TextField
                      fullWidth
                      label={<>Nama{values.ayah_status !== "Tidak Diketahui" ? " *" : ""}</>}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="ayah_nama"
                      type="text"
                      onChange={this.handleChange}
                      value={values.ayah_nama || ''}
                      error={this.hasError('ayah_nama')}
                      helperText={
                        this.hasError('ayah_nama') ? errors.ayah_nama[0] : null
                      }
                    />
                  </Col>
                  <Col xs={24}>
                    <TextField
                      fullWidth
                      label={<>NIK{values.ayah_status === "Masih Hidup" ? " *" : ""}</>}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="ayah_nik"
                      type="text"
                      onChange={this.handleChange}
                      value={values.ayah_nik || null}
                      error={this.hasError('ayah_nik')}
                      helperText={
                        this.hasError('ayah_nik') ? errors.ayah_nik[0] : null
                      }
                    // InputProps={{
                    //   readOnly: true,
                    // }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Autocomplete
                      options={pendidikanOptions}
                      value={values.ayah_status === "Masih Hidup" ? (values.ayah_pendidikan || null) : null}
                      readOnly={values.ayah_status === "Masih Hidup" ? false : true}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("ayah_pendidikan", newValue ? newValue : null)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label={<>Pendidikan{values.ayah_status === "Masih Hidup" ? " *" : ""}</>}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="ayah_pendidikan"
                        type="text"
                        error={this.hasError('ayah_pendidikan')}
                        helperText={
                          this.hasError('ayah_pendidikan') ? errors.ayah_pendidikan[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Autocomplete
                      options={pekerjaanOptions}
                      value={values.ayah_status === "Masih Hidup" ? (values.ayah_pekerjaan || null) : null}
                      readOnly={values.ayah_status === "Masih Hidup" ? false : true}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("ayah_pekerjaan", newValue ? newValue : null)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label={<>Pekerjaan{values.ayah_status === "Masih Hidup" ? " *" : ""}</>}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="ayah_pekerjaan"
                        type="text"
                        error={this.hasError('ayah_pekerjaan')}
                        helperText={
                          this.hasError('ayah_pekerjaan') ? errors.ayah_pekerjaan[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Autocomplete
                      options={penghasilanOptions}
                      value={values.ayah_status === "Masih Hidup" ? (values.ayah_penghasilan || null) : null}
                      readOnly={values.ayah_status === "Masih Hidup" ? false : true}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("ayah_penghasilan", newValue ? newValue : null)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label={<>Penghasilan{values.ayah_status === "Masih Hidup" ? " *" : ""}</>}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="ayah_penghasilan"
                        type="text"
                        error={this.hasError('ayah_penghasilan')}
                        helperText={
                          this.hasError('ayah_penghasilan') ? errors.ayah_penghasilan[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <TextField
                      fullWidth
                      label={<>No. Whatsapp{values.ayah_status === "Masih Hidup" ? " *" : ""}</>}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="ayah_no_whatsapp"
                      type="text"
                      onChange={this.handleChange}
                      value={values.ibu_status === "Masih Hidup" ? (values.ayah_no_whatsapp || null) : null}
                      error={this.hasError('ayah_no_whatsapp')}
                      helperText={
                        this.hasError('ayah_no_whatsapp') ? errors.ayah_no_whatsapp[0] : null
                      }
                      InputProps={{
                        readOnly: values.ayah_status === "Masih Hidup" ? false : true,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>

      <Col xs={24} sm={12}>
        <Card className='form-box'>
          <CardContent>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Alert message={<>Data Ibu kandung</>} type="info" showIcon />
              </Col>
              <Col xs={24}>
                <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Status</FormLabel>
                <Radio.Group style={{ width: '100%' }}
                  name="ibu_status"
                  value={values.ibu_status || ""}
                  onChange={this.handleChange}
                >
                  <Row gutter={[16, 16]}>
                    {statusKehidupanOptions.map((row, i) => (
                      <Col xs={12} sm={8} md={6} key={i}>
                        <Radio value={row.value}>{row.label}</Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Col>

              <Col xs={24}>
                <TextField
                  fullWidth
                  label={<>Nama{values.ibu_status !== "Tidak Diketahui" ? " *" : ""}</>}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="ibu_nama"
                  type="text"
                  onChange={this.handleChange}
                  value={values.ibu_nama || ''}
                  error={this.hasError('ibu_nama')}
                  helperText={
                    this.hasError('ibu_nama') ? errors.ibu_nama[0] : null
                  }
                // InputProps={{
                //   readOnly: true,
                // }}
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label={<>NIK{values.ibu_status === "Masih Hidup" ? " *" : ""}</>}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="ibu_nik"
                  type="text"
                  onChange={this.handleChange}
                  value={values.ibu_nik || ''}
                  error={this.hasError('ibu_nik')}
                  helperText={
                    this.hasError('ibu_nik') ? errors.ibu_nik[0] : null
                  }
                // InputProps={{
                //   readOnly: true,
                // }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Autocomplete
                  options={pendidikanOptions}
                  value={values.ibu_status === "Masih Hidup" ? (values.ibu_pendidikan || null) : null}
                  readOnly={values.ibu_status === "Masih Hidup" ? false : true}
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("ibu_pendidikan", newValue ? newValue : null)
                  }}
                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label={<>Pendidikan{values.ibu_status === "Masih Hidup" ? " *" : ""}</>}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="ibu_pendidikan"
                    type="text"
                    error={this.hasError('ibu_pendidikan')}
                    helperText={
                      this.hasError('ibu_pendidikan') ? errors.ibu_pendidikan[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Autocomplete
                  options={pekerjaanOptions}
                  value={values.ibu_status === "Masih Hidup" ? (values.ibu_pekerjaan || null) : null}
                  readOnly={values.ibu_status === "Masih Hidup" ? false : true}
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("ibu_pekerjaan", newValue ? newValue : null)
                  }}
                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label={<>Pekerjaan{values.ibu_status === "Masih Hidup" ? " *" : ""}</>}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="ibu_pekerjaan"
                    type="text"
                    error={this.hasError('ibu_pekerjaan')}
                    helperText={
                      this.hasError('ibu_pekerjaan') ? errors.ibu_pekerjaan[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Autocomplete
                  options={penghasilanOptions}
                  value={values.ibu_status === "Masih Hidup" ? (values.ibu_penghasilan || null) : null}
                  readOnly={values.ibu_status === "Masih Hidup" ? false : true}
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("ibu_penghasilan", newValue ? newValue : null)
                  }}
                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label={<>Penghasilan{values.ibu_status === "Masih Hidup" ? " *" : ""}</>}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="ibu_penghasilan"
                    type="text"
                    error={this.hasError('ibu_penghasilan')}
                    helperText={
                      this.hasError('ibu_penghasilan') ? errors.ibu_penghasilan[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label={<>No. Whatsapp{values.ibu_status === "Masih Hidup" ? " *" : ""}</>}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="ibu_no_whatsapp"
                  type="text"
                  onChange={this.handleChange}
                  value={values.ibu_status === "Masih Hidup" ? (values.ibu_no_whatsapp || null) : null}
                  error={this.hasError('ibu_no_whatsapp')}
                  helperText={
                    this.hasError('ibu_no_whatsapp') ? errors.ibu_no_whatsapp[0] : null
                  }
                  InputProps={{
                    readOnly: values.ibu_status === "Masih Hidup" ? false : true,
                  }}
                />
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>

      <Col xs={24} style={{ textAlign: "right" }}>
        <Button style={{ margin: 0 }} loading={this.state.loadingBtn} onClick={() => {
          this.setState({
            isUpdate: false
          })
        }}>
          Batal
        </Button>
        <Button style={{ margin: '0 0 0 8px' }} type="primary" htmlType="submit" icon={<SaveOutlined />} loading={this.state.loadingBtn} onClick={this.onSubmitAyah}>
          Simpan
        </Button>
      </Col>
    </Row>

    return (
      <>
        {this.state.isUpdate ? form : compDetail}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(OrangTua);
