import React from 'react';
import { Modal, Button, Form, Divider } from 'antd';
import useDelete from '../hooks/useDelete';

const ModalDelete = ({ data, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance
    const {
        loading,
        handleSubmit,
    } = useDelete(data, fetchTable, hideModal);


    return (
        <Modal
            maskClosable={false}
            title={"Konfirmasi"}
            open={visible}
            onCancel={hideModal}
            width={300}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
            >
                <p>Apakah anda yakin ingin menghapus data ini?</p>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button onClick={hideModal} disabled={loading}>Batal</Button>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Lanjutkan</Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalDelete;
