import React from 'react';
import { Button, Dropdown } from 'antd';
import { DownCircleOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';


class Action extends React.Component {

    render() {
        const items = [];
        const { row, action, modalForm } = this.props;

        if (!action.update && !action.detail) {
            return "-"
        }

        if (action.update) {
            items.push({
                key: row.id,
                label: "Update",
                onClick: () => {
                    console.log("Opening modal for row:", row);
                    modalForm(true, row)
                },
                icon: <EditOutlined />,
            })
        }

        if (action.detail) {
            items.push({
                key: row.id,
                label: "Detail Semester",
                onClick: () => {
                    this.props.history.push(`tahun-ajaran/semester/${row.id}`)
                },
                icon: <InfoCircleOutlined />,
            })
        }

        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown>
        );
    }
}


export default React.memo(withRouter(Action));
