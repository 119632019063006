import React from 'react';
import { connect } from 'react-redux'
import Config from "../../Config";
import { _getToken, isAuth, _error, _success, _validationFormMsg, _setAxios, _tableLogActivity } from '../../lib/Helper';

import { Input, Tooltip, Breadcrumb, Table, Row, Col, Divider, Popover, Modal, Button, Radio, Space, Menu, Dropdown, Checkbox } from 'antd';
import { PageHeader } from 'components/header';
import { InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, SettingOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, AntDesignOutlined } from '@ant-design/icons';
import axios from 'axios';
import AuthRedirect from '../../components/AuthRedirect'
import validate from 'validate.js';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PreloadContent from '../../components/preload/PreloadContent'

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 100
    }
  },
  path: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 100
    }
  }
};

class ConfMenu extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tableOption: {
        sortField: "id",
        sortValue: "DESC",
      },
      data: [],
      search: "",
      pagination: {
        current: 1,
        pageSize: 10,
        // position: [
        //   "none",
        //   "bottomCenter"
        // ]
      },
      loading: false,
      visibleModalCreate: false,
      id: 0,
      values: {},
      errors: {},
      ddl: {
        menuParent: []
      },
      create: {
        visible: false,
        loading: false,
      },
      update: {
        visible: false,
        loading: false,
      },
      delete: {
        visible: false,
        loading: false,
      },
      loadingAction: true,
      assignAction: {
        visible: false,
        loading: false,
        value: 0,
        data: [],
        checked: []
      },

    };
  }

  componentDidMount() {
    this.fetchTable()
    this.getMenuParentDDL()
    this.getActionDDL()
  }

  fetchTable = () => {
    var params = {
      search: this.state.search,
      pagination: { ...this.state.pagination },
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    }
    this.fetch(params);
  }


  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
      search: this.state.search,
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    });
  };

  handleTableSearch = (e) => {
    const { value } = e.target;

    this.setState({
      search: value,
    });

    var params = {
      search: value,
      pagination: { ...this.state.pagination, current: 1 },
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    }
    this.fetch(params);

  };

  handleTableFilter = e => {
    this.setState({
      tableOption: {
        ...this.state.tableOption,
        sortField: e.target.value
      },
    });

    this.fetch({
      search: this.state.search,
      pagination: { ...this.state.pagination },
      sorting: [{
        field: e.target.value,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    });
  };

  handleTableSort = e => {
    this.setState({
      tableOption: {
        ...this.state.tableOption,
        sortValue: e.target.value
      },
    });

    this.fetch({
      search: this.state.search,
      pagination: { ...this.state.pagination },
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: e.target.value,
        urutan: 1,
      }]
    });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const headers = {
      'Accept': 'application/json',
      'Authorization': _getToken()
    };

    axios.post(Config.api + '/menu/table', JSON.stringify(params), { headers })
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data.list,
          pagination: {
            ...params.pagination,
            total: resp.data.info.total,
          },
        });
      })
      .catch(function (error) {
        if (error.response.data.message) {
          _error('topRight', 'Failed', error.response.data.message)
        }
      });
  };

  getMenuParentDDL = () => {
    const headers = {
      'Accept': 'application/json',
      'Authorization': _getToken()
    };

    axios.get(Config.api + '/menu/parent', { headers })
      .then((resp) => {

        this.setState({
          ddl: {
            ...this.state.ddl,
            menuParent: resp.data.data
          },
        });
      })
      .catch(function (error) {
        if (error.response.data.message) {
          _error('topRight', 'Failed', error.response.data.message)
        }
      });
  }

  getActionDDL = () => {
    const headers = {
      'Accept': 'application/json',
      'Authorization': _getToken()
    };

    axios.get(Config.api + '/action', { headers })
      .then((resp) => {
        this.setState({
          assignAction: {
            ...this.state.assignAction,
            data: resp.data.data
          },
        });
      })
      .catch(function (error) {
        if (error.response.data.message) {
          _error('topRight', 'Failed', error.response.data.message)
        }
      });
  }

  getActionByMenuID = (id) => {
    this.setState({
      loadingAction: true
    });

    const headers = {
      'Accept': 'application/json',
      'Authorization': _getToken()
    };

    axios.get(Config.api + '/action/' + id, { headers })
      .then((resp) => {
        this.setState({
          loadingAction: false,
          assignAction: {
            ...this.state.assignAction,
            checked: resp.data.data,
          },
        });
      })
      .catch(function (error) {
        if (error.response.data.message) {
          _error('topRight', 'Failed', error.response.data.message)
        }
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setForm(name, value)
  };

  setForm = (name, value, row = {}) => {
    const { values } = this.state;
    values[name] = value;
    const errors = validate(values, schema);
    this.setState({
      values: values,
      errors: errors || {},
    });
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }

  resetForm = () => {
    this.setState({
      values: {},
      errors: {},
    });
  };


  // CREATE START ------------------------------------------
  showModalCreate = () => {
    this.setState({
      create: {
        ...this.state.create,
        visible: true,
      },
    });

    this.resetForm()
  };

  hideModalCreate = () => {
    this.setState({
      create: {
        visible: false,
        loading: false,
      },

    });
  };

  createSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const errors = validate(values, schema);
    if (errors) {
      return _validationFormMsg(errors)
    }
    this.setState({
      create: {
        ...this.state.create,
        loading: true
      }
    });
    _setAxios("menu", "POST", {
      name: this.state.values.name,
      path: this.state.values.path,
      parent: this.state.values.parent ? this.state.values.parent : 0,
    }).then(resp => {
      if (resp.status === true) {
        this.hideModalCreate()
        this.fetchTable()
        this.getMenuParentDDL()
        _success('topRight', 'Success', resp.data.message)
      } else {
        this.setState({
          create: {
            ...this.state.create,
            loading: false
          }
        });
      }
    })
  };

  // CREATE END ------------------------------------------

  // UPDATE START ------------------------------------------
  showModalUpdate = (row) => {
    this.setState({
      update: {
        visible: true,
        loading: false
      },

      id: row.id,
      values: {
        name: row.name,
        path: row.path,
        parent: row.parent,
      }
    });
  };

  hideModalUpdate = () => {
    this.setState({
      update: {
        visible: false,
        loading: false
      }
    });
  };

  updateSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const errors = validate(values, schema);
    if (errors) {
      return _validationFormMsg(errors)
    }

    this.setState({
      update: {
        ...this.state.update,
        loading: true
      }
    });
    _setAxios("menu/" + this.state.id, "PUT", {
      name: values.name,
      path: values.path,
      parent: values.parent,
    }).then(resp => {
      if (resp.status === true) {
        this.hideModalUpdate()
        this.resetForm()
        this.fetchTable()
        this.getMenuParentDDL()
        _success('topRight', 'Success', resp.data.message)
      } else {
        this.setState({
          update: {
            ...this.state.update,
            loading: false
          }
        });
      }
    })

  };
  // UPDATE END ------------------------------------------

  // DELETE START ------------------------------------------

  hideModalDelete = () => {
    this.setState({
      delete: {
        ...this.state.delete,
        visible: false
      },
    });
  };

  showModalDelete = (row) => {
    this.setState({
      delete: {
        ...this.state.delete,
        visible: true
      },
      id: row.id,
    });

  };

  deleteSubmit = (e) => {
    e.preventDefault();

    this.setState({
      delete: {
        ...this.state.delete,
        loading: true
      }
    });

    _setAxios("menu/" + this.state.id, "DELETE").then(resp => {
      if (resp.status === true) {
        this.setState({
          delete: {
            visible: false,
            loading: false
          }
        });
        _success('topRight', 'Success', resp.data.message)
        this.fetchTable()
        this.getMenuParentDDL()
      } else {
        this.setState({
          delete: {
            ...this.state.delete,
            loading: false
          }
        });
      }
    })
  };

  // DELETE END ------------------------------------------

  // ASSIGN ACTION START ------------------------------------------

  hideModalAssignAction = () => {
    this.setState({
      assignAction: {
        ...this.state.assignAction,
        visible: false
      },
    });
  };

  showModalAssignAction = (row) => {
    this.setState({
      assignAction: {
        ...this.state.assignAction,
        visible: true,
        checked: []
      },
      id: row.id,
    });

    this.getActionByMenuID(row.id)
  };

  onChangeAction(checkedValues) {
    this.setState({
      assignAction: {
        ...this.state.assignAction,
        checked: checkedValues
      }
    })
  }


  assignActionSubmit = (e) => {
    e.preventDefault();

    this.setState({
      assignAction: {
        ...this.state.assignAction,
        loading: true
      }
    });

    _setAxios("action/" + this.state.id, "POST", {
      actions: this.state.assignAction.checked
    }).then(resp => {
      if (resp.status === true) {
        this.setState({
          assignAction: {
            ...this.state.assignAction,
            visible: false,
            loading: false
          }
        });
        _success('topRight', 'Success', resp.data.message)
      } else {
        this.setState({
          assignAction: {
            ...this.state.assignAction,
            loading: false
          }
        });
      }
    })
  };

  // ASSIGN ROLE END ------------------------------------------



  render() {
    const access = this.props.privilege.access["/configuration/menu"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { data, pagination, loading, values, errors, tableOption, ddl } = this.state;

    const sortComponent = <>
      <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
        <Space direction="vertical">
          <Radio value="id">Tanggal Pembuatan</Radio>
          <Radio value="name">Name</Radio>
          <Radio value="path">Path</Radio>
          <Radio value="parent_name">Parent</Radio>
        </Space>
      </Radio.Group>

      <Divider orientation="left"></Divider>

      <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
        <Space direction="vertical">
          <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
          <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
        </Space>
      </Radio.Group>
    </>

    const formInput = <>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <TextField
            fullWidth
            label="Nama"
            size="small"
            placeholder="Nama menu"
            InputLabelProps={{
              shrink: true,
            }}
            name="name"
            type="text"
            onChange={this.handleChange}
            value={values.name || ''}
            error={this.hasError('name')}
            helperText={
              this.hasError('name') ? errors.name[0] : null
            }
          />
        </Col>
        <Col xs={24}>
          <TextField
            fullWidth
            label="Path"
            size="small"
            placeholder="ex: /path"
            InputLabelProps={{
              shrink: true,
            }}
            name="path"
            type="text"
            onChange={this.handleChange}
            value={values.path || ''}
            error={this.hasError('path')}
            helperText={
              this.hasError('path') ? errors.path[0] : null
            }
          />
        </Col>
        <Col xs={24}>
          <TextField
            select
            fullWidth
            label="Parent"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="parent"
            onChange={this.handleChange}
            value={values.parent || ''}
            error={this.hasError('parent')}
            helperText={
              this.hasError('parent') ? errors.parent[0] : null
            }
          >
            <MenuItem key={0} value={0}></MenuItem>
            {ddl.menuParent.map((row) =>
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            )}
          </TextField>
        </Col>
      </Row>
    </>

    const modalCreate = <Modal
      title="Tambah Menu"
      visible={this.state.create.visible}
      onCancel={this.hideModalCreate}
      footer={<>
        <Button onClick={this.hideModalCreate}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.createSubmit} loading={this.state.create.loading}>Simpan</Button>
      </>}
    >
      <form autoComplete="off" onSubmit={this.createSubmit}>
        {formInput}
      </form>
    </Modal>

    const modalUpdate = <Modal
      title="Update Menu"
      onCancel={this.hideModalUpdate}
      visible={this.state.update.visible}
      footer={<>
        <Button onClick={this.hideModalUpdate}>Batal</Button>
        <Button type="primary" htmlType="submit" id="btnUpdateSubmit" onClick={this.updateSubmit} loading={this.state.update.loading}>Simpan</Button>
      </>}

    >
      <form autoComplete="off" onSubmit={this.updateSubmit}>
        {formInput}
      </form>
    </Modal>

    const modalDelete = <Modal
      title={<><ExclamationCircleOutlined /> Confirm</>}
      visible={this.state.delete.visible}
      onCancel={this.hideModalDelete}
      footer={<>
        <Button onClick={this.hideModalDelete}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Simpan</Button>
      </>}
    >
      Apakah Anda Yakin?
    </Modal>

    const modalAssignAction = <Modal
      title={<>Tambah / Update Action Menu</>}
      visible={this.state.assignAction.visible}
      onCancel={this.hideModalAssignAction}
      footer={<>
        <Button onClick={this.hideModalAssignAction}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.assignActionSubmit} loading={this.state.assignAction.loading}>Simpan</Button>
      </>}
    >
      <form autoComplete="off" onSubmit={this.assignActionSubmit}>
        {
          this.state.loadingAction ? <PreloadContent /> :
            <Checkbox.Group style={{ width: '100%' }} value={this.state.assignAction.checked} onChange={this.onChangeAction.bind(this)}>
              <Row>
                {this.state.assignAction.data.map((row, i) => (
                  <Col span={8} key={i}>
                    <Checkbox value={row.action} >{row.action}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
        }

      </form>

    </Modal>

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Konfigurasi</Breadcrumb.Item>
            <Breadcrumb.Item>Menu</Breadcrumb.Item>
          </Breadcrumb>}
          extra={action.create === true ? <Tooltip title="Tambah">
            <Button type='primary' block onClick={this.showModalCreate}><PlusOutlined /> Tambah</Button>
          </Tooltip> : ""}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Menu"
            subTitle="Data konfigurasi menu"
          />
          <Row gutter={[16, 16]}>
            <Col xs={16} sm={18} md={20} lg={21}>
              <Input
                name="search"
                className='search-table'
                placeholder="Search..."
                prefix={<SearchOutlined className="site-form-item-icon" />}
                onChange={this.handleTableSearch}
                value={this.state.search || ""}
                suffix={
                  <Tooltip title="Cari berdasarkan nama">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Col>
            <Col xs={8} sm={6} md={4} lg={3} className="text-align-right">
              <Popover placement="bottom" content={sortComponent} trigger="click">
                <Button block><SortAscendingOutlined /> Sort</Button>
              </Popover>
            </Col>
          </Row>

          <Divider orientation="left"></Divider>

          <Row>
            <Col span={24}>
              <Table
                columns={[
                  {
                    title: 'No',
                    dataIndex: 'rownum',
                    sorter: false,
                    width: '3%',
                  },
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    sorter: false,
                  },
                  {
                    title: 'Path',
                    dataIndex: 'path',
                    sorter: false,
                  },
                  {
                    title: 'Parent',
                    dataIndex: 'parent_name',
                    sorter: false,
                  },
                  {
                    title: <SettingOutlined />, align: "center",
                    key: 'operation',
                    width: '2%',
                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                      {action.update === true ? (<Menu.Item key={0} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                      {action.delete === true ? (<Menu.Item key={1} onClick={this.showModalDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                      {action.update === true ? (<Menu.Item key={2} onClick={this.showModalAssignAction.bind(this, row)}><AntDesignOutlined /> Assign Action</Menu.Item>) : ""}
                    </Menu>}>
                      <div className="ant-dropdown-link tb-action">
                        <Button type='primary'>Opsi</Button>
                      </div>
                    </Dropdown>,
                  },
                  {
                    title: 'LogActivity',
                    key: 'operation',
                    width: '10%',
                    render: (a, row) => _tableLogActivity(row),
                  },
                ]}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                size="small"
              />
            </Col>
          </Row>
        </div>

        {modalCreate}

        {modalUpdate}

        {modalDelete}

        {modalAssignAction}

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ConfMenu);
