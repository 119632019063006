import React, { useState } from 'react';
import { Row, Col, Image, Button, Skeleton, Typography } from 'antd';
import { connect } from 'react-redux';
import { CheckCircleOutlined, ClockCircleFilled, ScheduleOutlined } from '@ant-design/icons';
import { noImage } from '../../data/image';
import AbsensiCheckLog from './AbsensiCheckLog';
import useAbsensi from './useAbsensi';

const { Text, Title } = Typography;

const Absensi = ({ presensiSetting, loading, profile }) => {
  const [absensi, setAbsensi] = useState({ data: {}, visible: false });
  const { data: presensiData, loading: absensiLoading } = useAbsensi(profile?.tk_id);
  const dataIn = presensiData?.in;
  const dataOut = presensiData?.out;

  const modalAbsensi = (visible = false, data = {}) => {
    setAbsensi({ data, visible });
  };

  if (loading || absensiLoading) {
    return <Skeleton.Input active={true} className="skeleton-statistik" />;
  }

  return (
    <div
      className="dashboard-column"
      style={{ minHeight: 210, borderColor: "#F7BE2A" }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Title level={5} style={{ margin: 0 }}>
            Presensi hari ini <CheckCircleOutlined />
          </Title>
        </Col>

        <Col xs={24} sm={8}>
          <div className="dashboard-column" style={boxStyle("#52C41A")}>
            <Row gutter={[16, 16]}>
              <Col xs={10}>
                <Image
                  src={
                    dataIn?.foto
                      ? presensiData?.main_path + dataIn?.foto
                      : noImage
                  }
                  className="image-circle"
                />
              </Col>
              <Col xs={14}>
                <div style={labelStyle}>Masuk</div>
                <div style={timeStyle}>
                  {dataIn?.waktu
                    ? dataIn?.waktu.substring(0, 5)
                    : "-"}
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={24} sm={8}>
          <div className="dashboard-column" style={boxStyle("#52C41A")}>
            <Row gutter={[16, 16]}>
              <Col xs={10}>
                <Image
                  src={
                    dataOut?.foto
                      ? presensiData?.main_path + dataOut?.foto
                      : noImage
                  }
                  className="image-circle"
                />
              </Col>
              <Col xs={14}>
                <div style={labelStyle}>Pulang</div>
                <div style={timeStyle}>
                  {dataOut?.waktu
                    ? dataOut?.waktu.substring(0, 5)
                    : "-"}
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={24} sm={8}>
          <div style={{ width: "100%" }}>
            <div style={{ padding: "12.5px\ 0px" }}>
              <Text strong>Check Log</Text>
              <br />
              <ClockCircleFilled style={{ color: "#F7BE2A" }} />{" "}
              {presensiSetting?.jam_masuk} - {presensiSetting?.jam_pulang}
            </div>
            <Button
              block
              style={buttonStyle}
              onClick={() => modalAbsensi(true)}
            >
              <ScheduleOutlined /> Rekam Kehadiran
            </Button>
          </div>
        </Col>
      </Row>

      {absensi.visible && (
        <AbsensiCheckLog
          data={absensi.data}
          visible={absensi.visible}
          hideModal={() => modalAbsensi(false)}
        />
      )}
    </div>
  );
};

const boxStyle = (borderColor) => ({
  borderColor,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: 5,
});

const labelStyle = {
  fontSize: 16,
  fontWeight: "bold",
};

const timeStyle = {
  color: "#52C41A",
  fontSize: 20,
};

const buttonStyle = {
  background: "#10B582",
  color: "#fff",
  border: "1px solid #10B582",
  boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.043)",
  borderRadius: 5,
  width: "100%",
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  privilege: state.privilege,
});

export default connect(mapStateToProps)(Absensi);
