// useModalForm.js
import { useEffect, useState } from 'react';
import { _setAxios } from 'lib/Helper';
import dayjs from 'dayjs';

const useModalForm = (data, fetchTable, hideModal) => {
    
    const [values, setValues] = useState({
        is_active: false,
        is_deleted: false,
        ...data,
    });
    const [loading, setLoading] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        setValues(prev => ({
            ...prev,
            is_active: data.is_active === "1" ? true : false,
            is_deleted: data.is_deleted === "0" ? true : false,
        }));
        setLoadingContent(false);
    }, [data]);

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        let tahunAjaran = values.periode;
        if(values.periode && values.periode.length === 2) {
            tahunAjaran = `${dayjs(values.periode[0]).format('YYYY')}-${dayjs(values.periode[1]).format('YYYY')}`;
        }

        const params = {
            tahun_ajaran: tahunAjaran,
            is_active: values.is_active === true ? 1 : 0,
            is_deleted: values.is_deleted === true ? 0 : 1,
        };

        let endpoint = "tahun-ajaran";
        let method = "POST";
        if (data.id) {
            endpoint = `${endpoint}/${data.id}`;
            method = "PUT";
        }

        try {
            const resp = await _setAxios(endpoint, method, params);
            if (resp.status) {
                fetchTable(true);
                hideModal();
            }
        } catch (error) {
            console.error("Error while submitting form", error);
        } finally {
            setLoading(false);
        }
    };

    return {
        values,
        loading,
        loadingContent,
        handleChangeSetValue,
        handleSubmit,
    };
};

export default useModalForm;
