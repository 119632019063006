import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button, Typography } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import ModalForm from '../modal/Form';
import Filter from '../modal/Filter';
import ModalDelete from '../modal/Delete';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableWithFilter } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';
import ModalExport from '../modal/Export';
import ModalApproval from '../modal/Approval';
import ModalDetail from '../modal/Detail';
import { currentDate } from 'constant/constant_format';

const { Text } = Typography;
const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const PerizinanSiswa = ({ action, privilege }) => {
  const {
    table,
    formState,
    filterState,
    deleteState,
    exportState,
    approvalState,
    detailState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalForm,
    modalDelete,
    modalExport,
    modalApproval,
    modalDetail,
  } = useList(privilege?.tahun_ajaran, privilege?.semester);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Siswa"
        subTitle="Data perizinan siswa"
        extra={[
          action.create && (
            <Button
              type='primary'
              onClick={() => modalForm(true)}
              icon={<PlusOutlined />}>
              Tambah
            </Button>
          ),
          action.export && (
            <Button
              onClick={() => modalExport(true)}
              icon={<DownloadOutlined />}>
              Export
            </Button>
          ),
        ]} />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={18} md={19}>
          <SearchTable table={table} fetch={fetchData} />
        </Col>
        <Col xs={24} sm={6} md={5}>
          <SortingTableWithFilter
            table={table}
            fetch={fetchData}
            modalFilter={modalFilter}
            sortFields={sortFields}
          />
        </Col>
        <Col xs={24}>
          <Text italic>Default data yang tampil adalah hari ini, </Text><Text code>{currentDate}</Text>
        </Col>
        <Col xs={24}>
          <TableList
            state={{ table, filterState }}
            fetch={fetchData}
            fetchTable={fetchTable}
            columns={columns(action, modalForm, modalDelete, modalApproval, modalDetail)}
          />
        </Col>
      </Row>
      {formState.visible && (
        <ModalForm
          data={formState.data}
          visible={formState.visible}
          hideModal={() => modalForm(false)}
          fetchTable={fetchTable}
        />
      )}
      {filterState.visible && (
        <Filter
          visible={filterState.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filterState.values}
        />
      )}
      {deleteState.visible && (
        <ModalDelete
          data={deleteState.data}
          visible={deleteState.visible}
          hideModal={() => modalDelete(false)}
          fetchTable={fetchTable}
        />
      )}
      {exportState.visible && (
        <ModalExport
          privilege={privilege}
          visible={exportState.visible}
          hideModal={() => modalExport(false)}
        />
      )}
      {approvalState.visible && (
        <ModalApproval
          data={approvalState.data}
          visible={approvalState.visible}
          hideModal={() => modalApproval(false)}
          fetchTable={fetchTable}
        />
      )}
      {detailState.visible && (
        <ModalDetail
          data={detailState.data}
          visible={detailState.visible}
          hideModal={() => modalDetail(false)}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PerizinanSiswa);