export const yearFormat = "YYYY"
export const monthFormat = 'MMM, YYYY';
export const monthFormatNumber = 'YYYY-MM';
export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm';

export const currentDate = new Date().toLocaleDateString('id-ID', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
});

