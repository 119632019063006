import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Breadcrumb, Button } from 'antd';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import { isAuth } from 'lib/Helper';
import AuthRedirect from '../../../../components/AuthRedirect';
import Form from '../modal/Form';
import Filter from '../modal/Filter';
import ModalDelete from '../modal/Delete';
import SearchTable from '../../../../components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableOnly } from 'components/sorting-table';
import useTahunAjaran from '../hooks/useTahunAjaran';
import columns from './Columns';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan"
}];

const TahunAjaran = ({ privilege }) => {
  const {
    table,
    form,
    filter,
    deleteState,
    action,
    modalForm,
    modalDelete,
    modalFilter,
    setTempTableFilter,
    setTableFilterFromTemp,
    fetchData,
    fetchTable,
  } = useTahunAjaran(privilege);

  if (!isAuth(privilege) || action === undefined) {
    return <AuthRedirect />;
  }

  return (
    <>
      <PageHeader
        style={{ padding: 0 }}
        onBack={() => window.history.back()}
        subTitle={
          <Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Kurikulum</Breadcrumb.Item>
            <Breadcrumb.Item>Tahun Ajaran</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          action.create ? <Button type='primary' onClick={() => modalForm(true)}><PlusOutlined /> Tambah</Button> : null,
        ]}
      />
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader className="site-page-header" title="Tahun Ajaran" subTitle="Data Tahun Ajaran" />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableOnly
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filter }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalForm, modalDelete)}
            />
          </Col>
        </Row>
      </div>
      {form.visible && (
        <Form
          data={form.data}
          visible={form.visible}
          hideModal={() => modalForm(false)}
          fetchTable={fetchTable}
        />
      )}
      {deleteState.visible && (
        <ModalDelete
          data={deleteState.data}
          visible={deleteState.visible}
          hideModal={() => modalDelete(false)}
          fetchTable={fetchTable}
        />
      )}
      {filter.visible && (
        <Filter
          visible={filter.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filter.values}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(TahunAjaran);
