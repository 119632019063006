import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, getParamTempTableFiltering, _setAxios } from 'lib/Helper';
import moment from 'moment';

const initialTableState = () => ({
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 10,
        total: null,
    },
    sorting: [],
    filtering: {
        tgl_awal: moment().format("YYYY-MM-DD"),
        tgl_akhir: moment().format("YYYY-MM-DD"),
    },
});

const useList = (tahunAjaran, semester) => {
    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
            tahun_ajaran_id: tahunAjaran?.id,
            semester_id: semester?.id
        },
    });
    const [formState, setFormState] = useState({ data: {}, visible: false });
    const [filterState, setFilterState] = useState({ values: {
        ...table.filtering
    }, visible: false });
    const [deleteState, setDeleteState] = useState({visible: false, data: {}});
    const [exportState, setExportState] = useState({visible: false });
    const [approvalState, setApprovalState] = useState({visible: false, data: {}});
    const [detailState, setDetailState] = useState({visible: false, data: {}});

    const fetchTable = (isNew = false) => {
        const params = getParamTable("default", table);
        fetchData(params, isNew);
    };

    const fetchData = useCallback(async (params = {}, isNew = false) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("perizinan/gtk/table", "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data.list, params.pagination.current, isNew);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, current, isNew) => {
        const updatedData = isNew ? [] : table.data;
        const newCurrent = isMobile ? current + 1 : current;

        setTable(prev => ({
            ...prev,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data] : data,
            pagination: {
                ...prev.pagination,
                current: newCurrent,
                total: data.length,
            },
        }));
    };

    const setTempTableFilter = (name, value) => {
        setFilterState(prev => ({
            ...prev,
            values: { ...prev.values, [name]: value }
        }));
        setTable(prev => ({ ...prev, filtering: { ...prev.filtering, [name]: value } }));
    };

    const setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(table, filterState.values);
        fetchData(params, isMobile);
        modalFilter(false);
    };

    const modalFilter = (visible = false) => {
        setFilterState(prev => ({ ...prev, visible }));
    };

    const modalForm = (visible = false, data = {}) => {
        setFormState({ visible, data });
    };

    const modalDelete = (visible = false, data = {}) => {
        setDeleteState({ visible, data });
    };
    
    const modalExport = (visible = false) => {
        setExportState({ visible });
    };
    const modalApproval = (visible = false, data = {}) => {
        setApprovalState({ visible, data });
    };

    const modalDetail = (visible = false, data = {}) => {
        setDetailState({ visible, data });
    };

    return {
        table,
        formState,
        filterState,
        deleteState,
        exportState,
        approvalState,
        detailState,
        fetchTable,
        fetchData,
        setTempTableFilter,
        setTableFilterFromTemp,
        modalFilter,
        modalForm,
        modalDelete,
        modalExport,
        modalApproval,
        modalDetail,
    };
};

export default useList;
