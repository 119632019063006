import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";

const columns = (action, modalForm, modalDetail) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nama',
    dataIndex: 'siswa_nama',
    sorter: false,
  }, {
    title: 'Rombel',
    sorter: false,
    render: (a, row) => row.rombel_nama ? row.rombel_nama : "-",
  }, {
    title: 'Tanggal',
    sorter: false,
    render: (a, row) => row.tgl ? row.tgl : "-",
  }, {
    title: 'Jam',
    sorter: false,
    render: (a, row) => <>{(row.waktu_masuk ? row.waktu_masuk : "0")} - {(row.waktu_pulang ? row.waktu_pulang : "0")}</>,
  }, {
    title: 'Jarak',
    sorter: false,
    render: (a, row) => <>
      Masuk: <b>{row.radius_masuk ? parseFloat(row.radius_masuk).toFixed(2) + "m" : "-"}</b> <br />
      Pulang: <b>{row.radius_pulang ? parseFloat(row.radius_pulang).toFixed(2) + "m" : "-"}</b></>,
  }, {
    title: 'Durasi',
    sorter: false,
    render: (a, row) => <>
      Telat: <b>{row.durasi_telat ? row.durasi_telat : "-"}</b> <br />
      Pulang Cepat: <b>{row.durasi_pulang_cepat ? row.durasi_pulang_cepat : "-"}</b></>,
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDetail={modalDetail} />,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns