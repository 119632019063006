import { Divider, Tooltip } from "antd";
import { _tableLogActivitySended } from "lib/Helper";

const columns = (action) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nama',
    dataIndex: 'siswa_nama',
    sorter: false,
  }, {
    title: 'Rombel',
    sorter: false,
    render: (a, row) => row.rombel_nama ? row.rombel_nama : "-",
  }, {
    title: 'Tanggal',
    sorter: false,
    render: (a, row) => row.tgl ? row.tgl : "-",
  }, {
    title: 'Status',
    sorter: false,
    render: (a, row) => row.status ? row.status : "-",
  }, {
    title: 'Payload & Response',
    dataIndex: 'payload',
    sorter: false,
    render: (a, row) =>
      <>
        Payload
        <div><b>Phone:</b> {JSON.parse(row.payload) ? JSON.parse(row.payload).phone_no : "-"}</div>
        <div>
          <Tooltip title={JSON.parse(row.payload) ? JSON.parse(row.payload).message : "-"} width={300}>
            <b>Message:</b> {JSON.parse(row.payload) ? JSON.parse(row.payload).message.substring(1, 150) + "..." : "-"}
          </Tooltip>
        </div>
        <Divider />
        Response
        <div><b>Status:</b> {row.response ? (JSON.parse(row.response).status ? JSON.parse(row.response).status : "-") : "-"}</div>
        <div><b>Message:</b> {row.response ? (JSON.parse(row.response).message ? JSON.parse(row.response).message : "-") : "-"}</div>
      </>,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivitySended(row),
  },
];

export default columns