import React from 'react';

import { Select } from 'antd';
import { semesterOptions } from '../../data/options';

const SelectTipeSemester = ({ ...props }) => {
  return (
    <Select
      {...props}
      placeholder="Pilih tipe semester"
      style={{
        width: '100%',
      }}
      options={semesterOptions}
    />
  );
};
export default SelectTipeSemester;
