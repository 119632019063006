import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectJenisPerizinan, SelectSemester, SelectTahunAjaran, SelectTenagaPendidik } from 'components/select';
import { RangeDate } from 'components/datetime';
import { UploadFile } from 'components/upload-files';

const { TextArea } = Input;
const ModalForm = ({ privilege, data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        values,
        loading,
        isSuperAdmin,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(privilege, data, fetchTable, hideModal);
    if(isSuperAdmin === undefined){
        return null
    }

    const { tahun_ajaran, semester } = privilege

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Perizinan Tenaga Pendidik"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Tahun Ajaran"
                            name="tahun_ajaran_id"
                            rules={[{ required: true, message: 'Tahun Ajaran harus diisi!' }]}>
                            <SelectTahunAjaran
                                allowClear
                                disabled={data.id || !isSuperAdmin ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("tahun_ajaran_id", val || null);
                                    handleChangeSetValue("semester_id", null);
                                    handleChangeSetValue("rombel_id", null);
                                    handleChangeSetValue("siswa_id", null);
                                    form.resetFields(['semester_id', 'rombel_id', 'siswa_id']);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Semester"
                            name="semester_id"
                            rules={[{ required: true, message: 'Semester harus diisi!' }]}>
                            <SelectSemester
                                allowClear
                                value={semester?.id || null}
                                tahunAjaranId={values.tahun_ajaran_id}
                                disabled={!values.tahun_ajaran_id || data.id || !isSuperAdmin ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("semester_id", val || null);
                                    handleChangeSetValue("rombel_id", null);
                                    handleChangeSetValue("siswa_id", null);
                                    form.resetFields(['rombel_id', 'siswa_id']);
                                }}
                            />
                        </Form.Item>
                        {isSuperAdmin && (
                            <Form.Item label="Tenaga Pendidik" name="gtk_id" rules={[{ required: true, message: 'Tenaga Pendidik harus dipilih!' }]}>
                                <SelectTenagaPendidik
                                    allowClear
                                    value={values.gtk_id}
                                    onChange={(val) => handleChangeSetValue("gtk_id", val || null)}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            label="Jenis Perizinan"
                            name="perizinan_jenis_id"
                            rules={[{ required: true, message: 'Jenis Perizinan harus diisi!' }]}>
                            <SelectJenisPerizinan
                                allowClear
                                tipePerizinan="gtk"
                            />
                        </Form.Item>
                        <Form.Item label="Tanggal" name="tgl" rules={[{ required: true, message: 'Tanggal harus diisi!' }]}>
                            <RangeDate />
                        </Form.Item>
                        <Form.Item
                            label="Keterangan"
                            name="keterangan"
                            rules={[{ required: true, message: 'Keterangan harus diisi!' }]}>
                            <TextArea />
                        </Form.Item>
                        <Form.Item label="File" name="file" rules={[{ required: data.id ? false : true, message: 'File harus diisi!' }]}>
                            <UploadFile
                                isValidation={true}
                                type="jpg, png & pdf"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;