import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg } from '../../../../lib/Helper';
import { schemaPengaturanPelanggaran } from '../schema';
import validate from 'validate.js';

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Menu, Dropdown, Input, Tooltip, Modal, Pagination } from 'antd';
import { PageHeader } from 'components/header';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import { FormLabel } from '@mui/material';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class PengaturanPelanggaran extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tahun_ajaran: null
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            filter: {
                visible: false,
                values: {},
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tatibsi/pelanggaran-tindakan/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaPengaturanPelanggaran);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaPengaturanPelanggaran);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },

            values: {},
            errors: {},
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    poin_min: row.poin_min ? row.poin_min : 0,
                    poin_maks: row.poin_maks ? row.poin_maks : 0,
                    tindakan: row.tindakan ? row.tindakan : null,
                    hubungi_wali: row.hubungi_wali ? row.hubungi_wali : null,
                    dokumentasi: row.dokumentasi ? row.dokumentasi : null,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaPengaturanPelanggaran)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        let param = {
            poin_min: values.poin_min ? values.poin_min : 0,
            poin_maks: values.poin_maks ? values.poin_maks : 0,
            tindakan: values.tindakan ? values.tindakan : null,
            hubungi_wali: values.hubungi_wali ? values.hubungi_wali : "Ya",
            dokumentasi: values.dokumentasi ? values.dokumentasi : "Ya",
        }

        // Define Network
        let method = "POST"
        let endpoint = "tatibsi/pelanggaran-tindakan"
        if (values.id) {
            method = "PUT"
            endpoint = "tatibsi/pelanggaran-tindakan/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tatibsi/pelanggaran-tindakan/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }


    render() {
        const { table, values, errors } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Data Tindakan"
            visible={this.state.form.visible}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Poin Minimal *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="poin_min"
                        type="text"
                        onChange={this.handleChange}
                        value={values.poin_min || ''}
                        error={this.hasError('poin_min')}
                        helperText={
                            this.hasError('poin_min') ? errors.poin_min[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Poin maksimal *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="poin_maks"
                        type="text"
                        onChange={this.handleChange}
                        value={values.poin_maks || ''}
                        error={this.hasError('poin_maks')}
                        helperText={
                            this.hasError('poin_maks') ? errors.poin_maks[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Tindakan *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="tindakan"
                        type="text"
                        onChange={this.handleChange}
                        value={values.tindakan || ''}
                        error={this.hasError('tindakan')}
                        helperText={
                            this.hasError('tindakan') ? errors.tindakan[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Hubungi Wali</FormLabel>
                    <Radio.Group style={{ width: '100%' }}
                        name="hubungi_wali"
                        value={values.hubungi_wali || "Ya"}
                        onChange={this.handleChange}
                    >
                        <Row gutter={[16, 16]}>
                            {["Ya", "Tidak"].map((row, i) => (
                                <Col xs={12} sm={8} md={6} key={i}>
                                    <Radio
                                        value={row}>{row}</Radio>
                                </Col>
                            ))}
                        </Row>
                    </Radio.Group>
                </Col>
                <Col xs={24}>
                    <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Perlu Dokumentasi</FormLabel>
                    <Radio.Group style={{ width: '100%' }}
                        name="dokumentasi"
                        value={values.dokumentasi || "Ya"}
                        onChange={this.handleChange}
                    >
                        <Row gutter={[16, 16]}>
                            {["Ya", "Tidak"].map((row, i) => (
                                <Col xs={12} sm={8} md={6} key={i}>
                                    <Radio
                                        value={row}>{row}</Radio>
                                </Col>
                            ))}
                        </Row>
                    </Radio.Group>
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Pengaturan Tindakan Pelanggaran"
                    extra={
                        this.props.action.create ?
                            <>
                                <Tooltip title="Tambah">
                                    <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                                </Tooltip>
                            </> : null
                    }
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={19} md={19} >
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }
                            }}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={6} md={5}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Poin Min',
                                    dataIndex: 'poin_min',
                                },
                                {
                                    title: 'Poin Maks',
                                    dataIndex: 'poin_maks',
                                },
                                {
                                    title: 'Tindakan',
                                    dataIndex: 'tindakan',
                                },
                                {
                                    title: 'Hubungi Wali',
                                    dataIndex: 'hubungi_wali',
                                },
                                {
                                    title: 'Dokumentasi',
                                    dataIndex: 'dokumentasi',
                                },
                                {
                                    title: <EllipsisOutlined />, align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        {this.props.action.update ? <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item> : ""}
                                        {this.props.action.delete ? <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item> : ""}
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={false}
                            loading={table.loading}
                            size="small"
                        />
                    </Col>
                    <Col xs={24} className="table-pagination">
                        <Pagination
                            size="small"
                            current={table.pagination.current}
                            pageSize={table.pagination.pageSize}
                            total={table.pagination.total}
                            showTotal={() => `Total ${table.pagination.total} items`}
                            showSizeChanger
                            onChange={(current, pageSize) => {
                                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                        />
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(PengaturanPelanggaran);
