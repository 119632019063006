import React, { useState, useEffect } from 'react';
import { Modal, Button, Divider, Form, Input, Row, Col, message } from 'antd';
import { _success, _setAxios, _warn } from '../../../../lib/Helper';
import { RangeTime } from '../../../../components/datetime';
import SwitchBasic from '../../../../components/switch/SwitchBasic';
import { SelectDays, SelectPresenceType } from '../../../../components/select';
import MapComponent from '../../../../components/maps/MapComponent';
import config from 'Config';

const FormContent = ({ visible, hideModal, fetchTable, data }) => {
    const [loading, setLoading] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);
    const [marker, setMarker] = useState({ lat: config.location?.lat, lng: config.location?.lng });
    const [address, setAddress] = useState("");

    useEffect(() => {
        if (data) {
            setLoadingContent(false);
        }
    }, [data]);

    const handleSubmit = async (values) => {
        setLoading(true);

        const times = values.jam
        if (times.length < 2) {
            message.error("Jam masuk dan jam pulang harus diisi");
            setLoading(false);
            return;
        }

        if (!marker.lat || !marker.lng) {
            message.error("Lokasi harus diisi");
            setLoading(false);
            return;
        }

        if (times[0] >= times[1]) {
            message.error("Jam masuk harus lebih kecil dari jam pulang");
            setLoading(false);
            return;
        }

        if (times[0] < new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })) {
            message.error("Jam masuk tidak boleh kurang dari sekarang");
            setLoading(false);
            return;
        }

        if (times[1] < new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })) {
            message.error("Jam pulang tidak boleh kurang dari sekarang");
            setLoading(false);
            return;
        }


        const params = {
            ...values,
            "lat": marker.lat,
            "long": marker.lng,
            "jam_masuk": new Date(times[0]).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),
            "jam_pulang": new Date(times[1]).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),
            "radius_active": values.radius_active ? 1 : 0
        };

        let endpoint = "presensi/setting";
        let method = "POST";
        if (data.id) {
            endpoint += `/${data.id}`;
            method = "PUT";
        }

        try {
            const resp = await _setAxios(endpoint, method, params);
            if (resp.status) {
                if(resp.data.status){
                    _success('topRight', 'Success', resp.data.message);
                    fetchTable(true);
                    hideModal();
                }else{
                    _warn('topRight', 'Warning', resp.data.message);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    if (loadingContent) {
        return null;
    }

    return (
        <Modal
            maskClosable={false}
            title="Form Konfigurasi Presensi"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null}
            width={700}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={data}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label="Tipe Presensi"
                            name="tipe"
                            rules={[{ required: true, message: 'Tipe Presensi wajib dipilih' }]}
                        >
                            <SelectPresenceType />
                        </Form.Item>
                        <Form.Item
                            label="Hari"
                            name="hari"
                            rules={[{ required: true, message: 'Hari wajib dipilih' }]}
                        >
                            <SelectDays/>
                        </Form.Item>
                        <Form.Item
                            label="Jam Masuk - Pulang"
                            name="jam"
                            rules={[{ required: true, message: 'Jam Masuk - Pulang wajib diisi' }]}
                            style={{ width: '100%' }}>
                            <RangeTime />
                        </Form.Item>
                        <Row gutter={[16, 0]}>
                            <Col xs={16}>
                                <Form.Item
                                    label="Radius Toleransi"
                                    name="radius_toleransi"
                                    rules={[
                                        { required: true, message: 'Radius Toleransi wajib diisi' },
                                        { pattern: /^\d*$/, message: "Hanya angka yang diperbolehkan" },
                                    ]}
                                >
                                    <Input placeholder="Radius Toleransi" />
                                </Form.Item>
                            </Col>
                            <Col xs={8}>
                                <Form.Item
                                    label="Radius Status"
                                    name="radius_active"
                                    valuePropName="checked"
                                >
                                    <SwitchBasic />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Row gutter={[16, 0]}>
                            <Col xs={24}>
                                <Form.Item
                                    label="Pilih lokasi marker absensi"
                                >
                                    <MapComponent
                                        marker={marker}
                                        setMarker={(val) => {
                                            if (val.length > 1) {
                                                setMarker({ lat: val[0], lng: val[1] });
                                            }
                                        }}
                                        setAddress={setAddress}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item label="Latitude">
                                    {marker.lat}
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item label="Longitude">
                                    {marker.lng}
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Alamat">
                                    {address || "No address found"}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ textAlign: "right" }}>
                        <Divider />
                        <Button onClick={hideModal} disabled={loading}>Batal</Button> &nbsp;
                        <Button type="primary" htmlType="submit" loading={loading}>Simpan</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default FormContent;
