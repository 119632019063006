import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

import { SelectAspekKategoriUbudiyah, SelectAspekPenilaianUbudiyah, SelectTingkatanKelas } from '../../../../components/select';

const schema = {
    tingkatan_kelas: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    ubudiyah_aspek_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                tingkatan_kelas: this.props.data.tingkatan_kelas ? this.props.data.tingkatan_kelas : null,
                ubudiyah_kategori_id: this.props.data.ubudiyah_kategori_id ? this.props.data.ubudiyah_kategori_id : null,
                ubudiyah_aspek_id: this.props.data.ubudiyah_aspek_id ? this.props.data.ubudiyah_aspek_id : null,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            tingkatan_kelas: values.tingkatan_kelas ? values.tingkatan_kelas : null,
            ubudiyah_aspek_id: values.ubudiyah_aspek_id ? values.ubudiyah_aspek_id : null,
        }

        let endpoint = "ubudiyah/capaian"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false,
                    values: {}
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {


        const { values } = this.state
        const { data } = this.props

        return (
            <Modal
                maskClosable={false}
                title={"Form Capaian Ubudiyah"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tingkat Kelas *">
                                <SelectTingkatanKelas
                                    defaultValue={data.tingkatan_kelas}
                                    value={values.tingkatan_kelas}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tingkatan_kelas", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item label="Aspek Kategori *">
                                <SelectAspekKategoriUbudiyah
                                    modul="aspek penilaian"
                                    defaultValue={data.ubudiyah_kategori_id}
                                    value={values.ubudiyah_kategori_id}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("ubudiyah_kategori_id", val ? val : null)
                                        this.handleChangeSetValue("ubudiyah_aspek_id", null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item label="Aspek Penilaian *">
                                <SelectAspekPenilaianUbudiyah
                                    modul="capaian"
                                    tingkatanKelas={values.tingkatan_kelas}
                                    ubudiyahKategoriID={values.ubudiyah_kategori_id}
                                    defaultValue={data.ubudiyah_kategori_id}
                                    value={values.ubudiyah_aspek_id}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("ubudiyah_aspek_id", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
