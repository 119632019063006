import React from 'react';
import { connect } from 'react-redux'
import { Tabs, Breadcrumb, message } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined } from '@ant-design/icons';
import Identitas from './Identitas';
import Document from './document/list/index';
import Gallery from './Gallery';
import BantuanOperasional from './BantuanOperasional';
import Lokasi from './Lokasi';
import PreloadContent from '../../components/preload/PreloadContent';
import AuthRedirect from '../../components/AuthRedirect';
import { isAuth } from '../../lib/Helper';

const { TabPane } = Tabs;

class LembagaIndex extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            tabIdx: "0",
        };
    }

    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.error('Identitas wajib disimpan terlebih dahulu.');
                return
            }
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })


        this.setState({
            tabIdx: key
        })
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : "identitas"
        })
    }

    render() {
        const { loading } = this.state;
        const { privilege } = this.props;

        const access = privilege.access["/lembaga/profil"]
        if (!isAuth(privilege) || access === undefined) {
          return <AuthRedirect />
        }

        const steps = [
            {
                title: 'Identitas',
                content: <Identitas {...this.props} />,
                key: "identitas",
            }, {
                title: 'Lokasi',
                content: <Lokasi {...this.props} />,
                key: "lokasi",
            }, {
                title: 'Bantuan Operasional',
                content: <BantuanOperasional {...this.props} />,
                key: "bantuan-operasional",
            }, {
                title: 'Dokumen',
                content: <Document access={access} {...this.props} />,
                key: "dokumen",
            }, {
                title: 'Galeri',
                content: <Gallery {...this.props} />,
                key: "galeri",
            }
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Lembaga</Breadcrumb.Item>
                        <Breadcrumb.Item>Profil Lembaga</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Profil Lembaga"
                    />
                    {
                        loading ? <PreloadContent /> : <Tabs defaultActiveKey="identitas" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                            {steps.map((item, i) => (
                                <TabPane key={item.key} tab={item.title}>
                                    {steps[i].content}
                                </TabPane>
                            ))}
                        </Tabs>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LembagaIndex);
