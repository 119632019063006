import React from 'react';
import { Button, Dropdown } from 'antd';
import { DownCircleOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';


class Action extends React.Component {

    render() {
        const items = [];
        const { row, action, modalForm } = this.props;

        if (!action.update) {
            return "-"
        }

        if (action.update) {
            items.push({
                key: row.id,
                label: "Update",
                onClick: () => {
                    modalForm(true, row)
                },
                icon: <EditOutlined />,
            })
        }
        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown>
        );
    }
}


export default React.memo(withRouter(Action));
