import React from 'react';
import { Col, Row, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { isMobile, MobileView } from 'react-device-detect';

const { Title } = Typography;

const PageHeader = ({ style, onBack, title, subTitle, extra }) => {
    if(title){
        style = {
            ...style,
            marginBottom: 16
        }
    }
    return (
        <div className="page-header">
            <Row justify="space-between">
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <div style={{ display: 'flex', justifyContent: isMobile ? (onBack ? 'left' : 'center') : 'flex-start', padding: '16px 0'}}>
                        {onBack && <ArrowLeftOutlined style={{ margin: "16px 0" }} onClick={onBack}/>}
                        {title && <Title level={3} style={{ margin: "0 8px 0 0" }}>{title}</Title>}
                        <MobileView><br/></MobileView>
                        {subTitle && <Typography.Text style={{ lineHeight: "2.5em" }}>{subTitle}</Typography.Text>}
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    {extra && <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px', padding: '16px 0', overflowX: 'auto'}}>{extra}</div>}
                </Col>
            </Row>
        </div>
    );
};

export default PageHeader;
