import React from 'react';
import { connect } from 'react-redux'
import Config from "../../../Config";
import axios from 'axios';
import validate from 'validate.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { Input, Tooltip, Table, Row, Col, Divider, Popover, Modal, Button, Radio, Space, Menu, Dropdown } from 'antd';
import { PageHeader } from 'components/header';
import { InfoCircleOutlined, SearchOutlined, EllipsisOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { schemaBeasiswa } from '../schema';
import { jenisInstansiOptions, beasiswaKategoriOptions } from '../../../data/options';
import { _getToken, _error, _success, _validationFormMsg, _setAxios, _tableLogActivity } from '../../../lib/Helper';

class Beasiswa extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tableOption: {
                sortField: "id",
                sortValue: "DESC",
            },
            data: [],
            search: "",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            visibleModalCreate: false,
            id: 0,
            values: {},
            errors: {},
            create: {
                visible: false,
                loading: false,
            },
            update: {
                visible: false,
                loading: false,
            },
            delete: {
                loading: false,
            },

            dataID: this.props.dataID ? this.props.dataID : this.props.match.params.id
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        var params = {
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
            search: this.state.search,
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSearch = (e) => {
        const { value } = e.target;

        this.setState({
            search: value,
        });

        var params = {
            search: value,
            pagination: { ...this.state.pagination, current: 1 },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);

    };

    handleTableFilter = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortField: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: e.target.value,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSort = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortValue: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: e.target.value,
                urutan: 1,
            }]
        });
    };

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        const headers = {
            'Accept': 'application/json',
            'Authorization': _getToken()
        };

        axios.post(Config.api + '/siswa/beasiswa/table/' + this.state.dataID, JSON.stringify(params), { headers })
            .then((resp) => {
                this.setState({
                    loading: false,
                    data: resp.data.list,
                    pagination: {
                        ...params.pagination,
                        total: resp.data.info.total,
                    },
                });
            })
            .catch(function (error) {
                if (error.response.data.message) {
                    _error('topRight', 'Failed', error.response.data.message)
                }
            });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    handleChangeSetValue = (name, value) => {
        this.setForm(name, value)
    };

    setForm = (name, value, row = {}) => {
        const { values } = this.state;
        values[name] = value;
        const errors = validate(values, schemaBeasiswa);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    resetForm = () => {
        this.setState({
            values: {},
            errors: {},
        });
    };


    // CREATE START ------------------------------------------
    showModalCreate = () => {
        this.setState({
            create: {
                ...this.state.create,
                visible: true,
            },
            values: {},
            errors: {},
        });
    };

    hideModalCreate = () => {
        this.setState({
            create: {
                visible: false,
                loading: false,
            },

        });
    };

    createSubmitBeasiswa = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schemaBeasiswa);
        if (errors) {
            return _validationFormMsg(errors)
        }

        const params = [{
            "tahun": values.beasiswa_tahun && values.beasiswa_tahun instanceof Date ? values.beasiswa_tahun.getFullYear() : values.beasiswa_tahun,
            "nama": values.beasiswa_nama ? values.beasiswa_nama : null,
            "kategori": values.beasiswa_kategori ? values.beasiswa_kategori.value : null,
            "nama_instansi": values.beasiswa_nama_instansi ? values.beasiswa_nama_instansi : null,
            "jenis_instansi": values.beasiswa_jenis_instansi ? values.beasiswa_jenis_instansi.value : null,
            "jangka_waktu": values.beasiswa_jangka_waktu ? values.beasiswa_jangka_waktu : null,
            "nominal": values.beasiswa_nominal ? values.beasiswa_nominal : null,
        }]

        this.setState({
            create: {
                ...this.state.create,
                loading: true
            }
        });

        _setAxios("siswa/beasiswa/" + this.state.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.hideModalCreate()
                this.resetForm()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    create: {
                        ...this.state.create,
                        loading: false
                    }
                });
            }
        })
    };

    // CREATE END ------------------------------------------

    // UPDATE START ------------------------------------------
    showModalUpdate = (row) => {
        this.setState({
            update: {
                visible: true,
                loading: false
            },

            id: row.id,
            values: {
                beasiswa_nama: row.nama,
                beasiswa_kategori: {
                    value: row.kategori,
                    label: row.kategori,
                },
                beasiswa_nama_instansi: row.nama_instansi,
                beasiswa_jenis_instansi: {
                    value: row.jenis_instansi,
                    label: row.jenis_instansi,
                },
                beasiswa_tahun: new Date(row.tahun, 11, 24, 10, 33, 30, 0),
                beasiswa_jangka_waktu: row.jangka_waktu,
                beasiswa_nominal: row.nominal,
            }
        });
    };

    hideModalUpdate = () => {
        this.setState({
            update: {
                visible: false,
                loading: false
            }
        });
    };

    updateSubmitBeasiswa = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schemaBeasiswa);
        if (errors) {
            return _validationFormMsg(errors)
        }

        const params = {
            "tahun": values.beasiswa_tahun && values.beasiswa_tahun instanceof Date ? values.beasiswa_tahun.getFullYear() : null,
            "nama": values.beasiswa_nama ? values.beasiswa_nama : null,
            "kategori": values.beasiswa_kategori ? values.beasiswa_kategori.value : null,
            "nama_instansi": values.beasiswa_nama_instansi ? values.beasiswa_nama_instansi : null,
            "jenis_instansi": values.beasiswa_jenis_instansi ? values.beasiswa_jenis_instansi.value : null,
            "jangka_waktu": values.beasiswa_jangka_waktu ? values.beasiswa_jangka_waktu : null,
            "nominal": values.beasiswa_nominal ? values.beasiswa_nominal : null,
        }

        this.setState({
            update: {
                ...this.state.update,
                loading: true
            }
        });
        _setAxios("siswa/beasiswa/" + this.state.id, "PUT", params).then(resp => {
            if (resp.status === true) {
                this.hideModalUpdate()
                this.resetForm()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    update: {
                        ...this.state.update,
                        loading: false
                    }
                });
            }
        })

    };
    // UPDATE END ------------------------------------------

    // DELETE START ------------------------------------------
    onDeleteSubmitBeasiswa = (row) => {
        Modal.confirm({
            title: 'Apakah Anda yakin?',
            icon: <ExclamationCircleOutlined />,
            content: 'Beasiswa [' + row.nama + '] akan dihapus dari list.',
            onOk: () => {
                this.setState({
                    delete: {
                        loading: true
                    }
                });

                _setAxios("siswa/beasiswa/" + row.id, "DELETE").then(resp => {
                    if (resp.status === true) {
                        this.setState({
                            delete: {
                                loading: false
                            }
                        });
                        _success('topRight', 'Success', resp.data.message)
                        this.fetchTable()
                    } else {
                        this.setState({
                            delete: {
                                loading: false
                            }
                        });
                    }
                })
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    };

    // DELETE END ------------------------------------------


    render() {
        const { action } = this.props
        const { data, pagination, loading, values, errors, tableOption } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
                <Space direction="vertical">
                    <Radio value="nama">Nama</Radio>
                    <Radio value="id">Tanggal Pembuatan</Radio>
                </Space>
            </Radio.Group>

            <Divider orientation="left"></Divider>

            <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
                <Space direction="vertical">
                    <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
                    <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
                </Space>
            </Radio.Group>
        </>

        const formInput = <>
            <Row gutter={[24, 24]}>

                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Nama Beasiswa/Bantuan"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="beasiswa_nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.beasiswa_nama || ""}
                        error={this.hasError('beasiswa_nama')}
                        helperText={
                            this.hasError('beasiswa_nama') ? errors.beasiswa_nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={beasiswaKategoriOptions}
                        value={values.beasiswa_kategori || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("beasiswa_kategori", newValue)
                        }}
                        name="beasiswa_kategori"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kategori"
                            size="small"
                            error={this.hasError('beasiswa_kategori')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('beasiswa_kategori') ? errors.beasiswa_kategori[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Nama Instansi"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="beasiswa_nama_instansi"
                        type="text"
                        onChange={this.handleChange}
                        value={values.beasiswa_nama_instansi || ""}
                        error={this.hasError('beasiswa_nama_instansi')}
                        helperText={
                            this.hasError('beasiswa_nama_instansi') ? errors.beasiswa_nama_instansi[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={jenisInstansiOptions}
                        value={values.beasiswa_jenis_instansi || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("beasiswa_jenis_instansi", newValue)
                        }}
                        name="beasiswa_jenis_instansi"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jenis Instansi"
                            size="small"
                            error={this.hasError('beasiswa_jenis_instansi')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('beasiswa_jenis_instansi') ? errors.beasiswa_jenis_instansi[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={12} sm={8}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            views={['year']}
                            // disableHighlightToday={true}
                            label="Tahun"
                            value={values.beasiswa_tahun || ""}
                            onChange={(newValue) => {
                                this.handleChangeSetValue("beasiswa_tahun", newValue)
                            }}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                size="small"
                                error={this.hasError('beasiswa_tahun')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    this.hasError('beasiswa_tahun') ? errors.beasiswa_tahun[0] : null
                                }
                            />}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={12} sm={8}>
                    <TextField
                        fullWidth
                        label="Jangka Waktu (Bulan)"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="beasiswa_jangka_waktu"
                        type="text"
                        onChange={this.handleChange}
                        value={values.beasiswa_jangka_waktu || ''}
                        error={this.hasError('beasiswa_jangka_waktu')}
                        helperText={
                            this.hasError('beasiswa_jangka_waktu') ? errors.beasiswa_jangka_waktu[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={8}>
                    <TextField
                        fullWidth
                        label="Nominal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="beasiswa_nominal"
                        type="text"
                        onChange={this.handleChange}
                        value={values.beasiswa_nominal || ''}
                        error={this.hasError('beasiswa_nominal')}
                        helperText={
                            this.hasError('beasiswa_nominal') ? errors.beasiswa_nominal[0] : null
                        }
                    />
                </Col>
            </Row>
        </>

        const modalCreate = <Modal
            title="Tambah Beasiswa/Bantuan"
            visible={this.state.create.visible}
            onCancel={this.hideModalCreate}
            footer={<>
                <Button onClick={this.hideModalCreate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.createSubmitBeasiswa} loading={this.state.create.loading}>Simpan</Button>
            </>}
        >
            <form autoComplete="off" onSubmit={this.createSubmitBeasiswa}>
                {formInput}
            </form>
        </Modal>

        const modalUpdate = <Modal
            title="Update Beasiswa/Bantuan"
            onCancel={this.hideModalUpdate}
            visible={this.state.update.visible}
            footer={<>
                <Button onClick={this.hideModalUpdate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.updateSubmitBeasiswa} loading={this.state.update.loading}>Simpan</Button>
            </>}

        >
            <form autoComplete="off" onSubmit={this.updateSubmitBeasiswa}>
                {formInput}
            </form>
        </Modal>

        const modalDelete = <Modal
            title={<><ExclamationCircleOutlined /> Confirm</>}
            visible={this.state.delete.visible}
            onCancel={this.hideModalDelete}
            footer={<>
                <Button onClick={this.hideModalDelete}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Simpan</Button>
            </>}
        >
            Apakah Anda Yakin?

        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Beasiswa & Bantuan"
                    extra={
                        <>
                            <Tooltip title="Tambah">
                                <Button type='primary' icon={<PlusOutlined />} onClick={this.showModalCreate}> Tambah</Button>
                            </Tooltip>
                        </>
                    }
                />
                <Row gutter={[16, 16]}>
                    <Col xs={16} sm={18} md={20} lg={22}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={this.handleTableSearch}
                            value={this.state.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={8} sm={6} md={4} lg={2} className="text-align-right">
                        <Popover placement="bottom" content={sortComponent} trigger="click">
                            <Button block><SortAscendingOutlined /> Sort</Button>
                        </Popover>
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    sorter: false,
                                    width: '3%',
                                },
                                {
                                    title: 'Tahun',
                                    dataIndex: 'tahun',
                                    sorter: false,
                                },
                                {
                                    title: 'Kategori',
                                    dataIndex: 'kategori',
                                    sorter: false,
                                },
                                {
                                    title: 'Beasiswa/Bantuan',
                                    dataIndex: 'nama',
                                    sorter: false,
                                },
                                {
                                    title: 'Instansi',
                                    dataIndex: 'nama_instansi',
                                    sorter: false,
                                },
                                {
                                    title: 'Jenis Instansi',
                                    dataIndex: 'jenis_instansi',
                                    sorter: false,
                                },
                                {
                                    title: 'Jangka Waktu',
                                    dataIndex: 'jangka_waktu',
                                    sorter: false,
                                },
                                {
                                    title: 'Nominal',
                                    dataIndex: 'nominal',
                                    sorter: false,
                                },
                                {
                                    title: <EllipsisOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        {action.update === true ? (<Menu.Item key={0} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                                        {action.delete === true ? (<Menu.Item key={1} onClick={this.onDeleteSubmitBeasiswa.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                        <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={this.handleTableChange}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalCreate}

                {modalUpdate}

                {modalDelete}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Beasiswa);
