import React from 'react';
import { Modal, Button, Form, Row, Col, message } from 'antd';
import { _getDate } from 'lib/Helper';
import { SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';
import dayjs from 'dayjs';
import { RangeDate } from 'components/datetime';

const ModalFilter = ({ values, visible, hideModal, setTempTableFilter, setTableFilterFromTemp }) => {
    return (
        <Modal
            title="Filter"
            width={300}
            open={visible}
            onCancel={hideModal}
            footer={<>
                <Button onClick={hideModal}>Tutup</Button>
                <Button type='primary' onClick={setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <SelectTahunAjaran
                        allowClear
                        value={values.tahun_ajaran_id}
                        onChange={(val) => {
                            setTempTableFilter("tahun_ajaran_id", val || null);
                            setTempTableFilter("semester_id", null);
                            setTempTableFilter("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Semester">
                    <SelectSemester
                        allowClear
                        value={values.semester_id}
                        tahunAjaranId={values.tahun_ajaran_id}
                        disabled={!values.tahun_ajaran_id}
                        onChange={(val) => {
                            setTempTableFilter("semester_id", val || null);
                            setTempTableFilter("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Rombel">
                    <SelectRombel
                        allowClear
                        value={values.rombel_id}
                        tahunAjaranId={values.tahun_ajaran_id}
                        semesterId={values.semester_id}
                        disabled={!values.tahun_ajaran_id || !values.semester_id}
                        onChange={(val) => {
                            setTempTableFilter("rombel_id", val || null);
                        }}
                    />
                </Form.Item>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Form.Item label="Periode">
                            <RangeDate
                                value={[
                                    dayjs(values.tgl_awal),
                                    dayjs(values.tgl_akhir),
                                ]}
                                onChange={(e, newValue) => {
                                    if (newValue.length === 2 && (newValue[0] && newValue[1])) {
                                        let tglAwal = _getDate(newValue[0]);
                                        let tglAkhir = _getDate(newValue[1]);

                                        let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
                                        let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");

                                        if (_tglAwal > _tglAkhir) {
                                            return message.warning("Periode yang dimasukkan tidak valid.");
                                        }

                                        setTempTableFilter("tgl_awal", newValue[0]);
                                        setTempTableFilter("tgl_akhir", newValue[1]);
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalFilter;
