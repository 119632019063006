import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { Breadcrumb, Tabs, message, Row, Col } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined } from '@ant-design/icons';
import Leater from './Leater';
import JalurMasukPTN from './JalurMasukPTN';
import JalurMasukKedinasan from './JalurMasukKedinasan';

const { TabPane } = Tabs;

class MediaInformasi extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIdx: "0",
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : "0"
        })
    }

    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.error('Data Rombel wajib disimpan terlebih dahulu.');
                return
            }
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIdx: key
        })
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/media-informasi"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const steps = [
            {
                title: 'Leater',
                content: <Leater {...this.props}/>,
            },
            {
                title: 'Jalur Masuk PTN',
                content: <JalurMasukPTN {...this.props}/>,
            },
            {
                title: 'Jalur Masuk Kedinasan',
                content: <JalurMasukKedinasan {...this.props}/>,
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item>Media Informasi</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Tabs defaultActiveKey="0" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                                {steps.map((item, i) => (
                                    <TabPane key={i} tab={item.title}>
                                        {steps[i].content}
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(MediaInformasi);
