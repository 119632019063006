import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg } from '../../lib/Helper';
import validate from 'validate.js';
import { mataPelajaranKelompokOptions, mataPelajaranKelompokBOptions, mataPelajaranKelompokCOptions } from '../../data/options';

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Menu, Dropdown, Input, Tooltip, Modal, Breadcrumb, Checkbox } from 'antd';
import { PageHeader } from 'components/header';
import { MenuOutlined, ClockCircleOutlined, DoubleRightOutlined, SaveOutlined, InfoCircleOutlined, SearchOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AuthRedirect from '../../components/AuthRedirect';
import { isAuth } from '../../lib/Helper';
import InputAdornment from '@mui/material/InputAdornment';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}
const schema = {

}

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

class MataPelajaran extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                btnDisable: true,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 1000,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,

            ddl: {
                mataPelajaranIndukOptions: [],
                jurusan: []
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getJurusanDDL()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...this.state.table,
                loading: true,
            }
        });
        _setAxios("mata-pelajaran/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...this.state.table,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...this.state.table,
                        loading: false,
                    }
                })
            }
        })
    };

    getJurusanDDL = () => {
        _setAxios("jurusan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jurusan: resp.data.data
                    },
                })
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)

        if (name === "kelompok") {
            let indexOptions = []
            if (value === "B") {
                indexOptions = mataPelajaranKelompokBOptions
            } else if (value === "C") {
                indexOptions = mataPelajaranKelompokCOptions
            }

            this.setState({
                ddl: {
                    ...this.state.ddl,
                    mataPelajaranIndukOptions: indexOptions
                },
            });
        }
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    nama: row.nama ? row.nama : null,
                    kelompok: row.kelompok ? row.kelompok : null,
                    induk: row.induk ? row.induk : null,
                    jurusan_id: row.jurusan_id ? {
                        id: row.jurusan_id,
                        nama: row.jurusan_nama,
                    } : null,
                    singkatan: row.singkatan ? row.singkatan : null,
                    mapel_pilihan: row.mapel_pilihan ? row.mapel_pilihan : null,
                    total_jtm: row.total_jtm ? row.total_jtm : null,
                    is_pembelajaran: row.is_pembelajaran === "1" ? true : false,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schema)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        const param = {
            nama: values.nama ? values.nama : null,
            kelompok: values.kelompok ? values.kelompok : null,
            induk: values.induk ? values.induk : null,
            jurusan_id: values.jurusan_id ? values.jurusan_id.id : null,
            singkatan: values.singkatan ? values.singkatan : null,
            mapel_pilihan: values.mapel_pilihan ? values.mapel_pilihan : null,
            total_jtm: values.total_jtm ? values.total_jtm : null,
            urutan: values.urutan ? values.urutan : 0,
            is_pembelajaran: values.is_pembelajaran ? 1 : 0,
        }

        // Define Network
        let method = "POST"
        let endpoint = "mata-pelajaran"
        if (values.id) {
            method = "PUT"
            endpoint = "mata-pelajaran/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data ({row.nama}) akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("mata-pelajaran/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // DRAG STRAT ----------
    onSortEnd = ({ oldIndex, newIndex }) => {
        const { table } = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(table.data), oldIndex, newIndex).filter(
                el => !!el,
            );
            this.setState({
                table: {
                    ...this.state.table,
                    data: newData,
                    btnDisable: false
                }
            });
        }
    };

    DraggableContainer = props => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperclassName="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { table } = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index

        const index = table.data.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    // DRAG END ----------


    onSubmitUrutan = (row) => {
        Modal.confirm({
            title: 'Simpan Perubahan Urutan',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan disimpan sesuai perubahan urutan, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.setState({
                        table: {
                            ...this.state.table,
                            loading: true
                        }
                    });

                    const paylods = []
                    this.state.table.data.forEach((row, i) => {
                        paylods.push({
                            id: row.id,
                            urutan: (i + 1),
                        })
                    });

                    _setAxios("mata-pelajaran/no-urutan/update", "PUT", paylods).then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const access = this.props.privilege.access["/kurikulum/mata-pelajaran"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action

        const { table, values, errors, ddl } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Mata Pelajaran"
            visible={this.state.form.visible}
            // width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={6} sm={6}>
                    <Autocomplete
                        options={mataPelajaranKelompokOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kelompok"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="kelompok"
                            type="text"
                            error={this.hasError('kelompok')}
                            helperText={
                                this.hasError('kelompok') ? errors.kelompok[0] : null
                            }
                        />}
                        value={values.kelompok || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("kelompok", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={18} sm={18}>
                    <Autocomplete
                        options={ddl.mataPelajaranIndukOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Induk"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="induk"
                            type="text"
                            error={this.hasError('induk')}
                            helperText={
                                this.hasError('induk') ? errors.induk[0] : null
                            }
                        />}
                        value={values.induk || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("induk", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={ddl.jurusan}
                        value={values.jurusan_id || null}
                        getOptionLabel={(option) =>
                            option.nama
                        }
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("jurusan_id", newValue ? newValue : null)
                        }}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jurusan"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jurusan_id"
                            type="text"
                            error={this.hasError('jurusan_id')}
                            helperText={
                                this.hasError('jurusan_id') ? errors.jurusan_id[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Mata pelajaran"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Nama Singkatan"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="singkatan"
                        type="text"
                        onChange={this.handleChange}
                        value={values.singkatan || ''}
                        error={this.hasError('singkatan')}
                        helperText={
                            this.hasError('singkatan') ? errors.singkatan[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Jumlah Jam"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="total_jtm"
                        type="text"
                        onChange={this.handleChange}
                        value={values.total_jtm || ''}
                        error={this.hasError('total_jtm')}
                        helperText={
                            this.hasError('total_jtm') ? errors.total_jtm[0] : null
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><ClockCircleOutlined /></InputAdornment>,
                            min: 0, max: 100
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <Checkbox
                        checked={values.is_pembelajaran}
                        onChange={(e) => {
                            this.handleChangeSetValue("is_pembelajaran", e.target.checked)
                        }}
                    >Apakah Mata pelajaran bisa di assign guru pada form rombel?</Checkbox>
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kurikulum</Breadcrumb.Item>
                        <Breadcrumb.Item>Mata Pelajaran</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={
                        action.create ? <Tooltip title="Tambah">
                            <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                        </Tooltip> : ""
                    }
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <PageHeader
                    className="site-page-header"
                    title="Mata Pelajaran"
                    subTitle="Data mata pelajaran"
                />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                // value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Col>

                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'Sort',
                                        dataIndex: 'sort',
                                        width: 30,
                                        className: 'drag-visible',
                                        render: () => <DragHandle />,
                                    },
                                    {
                                        title: 'Kode',
                                        dataIndex: 'singkatan',
                                    },
                                    {
                                        title: 'Mata Pelajaran',
                                        dataIndex: 'nama',
                                    },
                                    {
                                        title: 'Induk',
                                        dataIndex: 'induk',
                                    },
                                    {
                                        title: 'Kelompok',
                                        dataIndex: 'kelompok',
                                    },
                                    {
                                        title: 'Jurusan',
                                        dataIndex: 'jurusan_nama',
                                    },
                                    {
                                        title: 'JTM',
                                        dataIndex: 'total_jtm',
                                    },
                                    {
                                        title: 'Urutan',
                                        dataIndex: 'urutan',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                            <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                pagination={false}
                                dataSource={table.data}
                                loading={table.loading}
                                rowKey="index"
                                components={{
                                    body: {
                                        wrapper: this.DraggableContainer,
                                        row: this.DraggableBodyRow,
                                    },
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                        </Col>
                        <Col xs={24} style={{ paddingTop: 24 }}>
                            <Row>
                                <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                                    Total {table.pagination.total} items
                                </Col>
                                <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                                    <Button style={{ margin: '0 0 0 8px' }} type="primary" htmlType="submit" icon={<SaveOutlined />} loading={table.loading} disabled={table.btnDisable} onClick={this.onSubmitUrutan}>
                                        Simpan Urutan
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(MataPelajaran);
