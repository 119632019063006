import { SettingOutlined } from "@ant-design/icons";
import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";

const columns = (action, modalDelete) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nama',
    dataIndex: 'gtk_nama',
    sorter: false,
  }, {
    title: 'Tahun Ajaran',
    dataIndex: 'tahun_ajaran',
    sorter: false,
  }, {
    title: 'Semester',
    dataIndex: 'tipe_semester',
    sorter: false,
  }, {
    title: 'Tanggal',
    dataIndex: 'tgl',
    sorter: false,
  },
  {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalDelete={modalDelete}/>,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns