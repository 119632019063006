import React from 'react';
import { Divider, List, Image, Modal, Typography, Row, Col } from 'antd';
import { noImage } from '../../../../../data/image';
import { MapComponentBasic } from 'components/maps';

const { Text } = Typography;
const ModalDetail = ({ data, visible, hideModal }) => {

    return (
        <Modal
            title="Detail Kehadiran Siswa"
            open={visible}
            onCancel={hideModal}
            footer={null}
            width={700}
        >
            <List
                // bordered
                // header={<Text strong>Informasi Umum</Text>}
                dataSource={[
                    { label: 'Nama', value: data.siswa_nama },
                    { label: 'Tahun Ajaran', value: data.tahun_ajaran },
                    { label: 'Semester', value: data.tipe_semester },
                    { label: 'Rombel', value: data.rombel_nama },
                    { label: 'Tanggal', value: data.tgl },
                ]}
                renderItem={(item) => (
                    <List.Item>
                        <Text strong>{item.label}:</Text> {item.value || "Tidak ada"}
                    </List.Item>
                )}
            />

            <Row gutter={16}>
                <Col xs={12}>
                    <Divider orientation="left">Detail Masuk</Divider>
                    <List
                        bordered
                        dataSource={[
                            { label: 'Waktu Masuk', value: data.waktu_masuk },
                            { label: 'IP Masuk', value: data.ip_masuk },
                            {
                                label: 'Lokasi Masuk (Lat, Long)', value: data.lat_masuk && data.long_masuk ? <MapComponentBasic
                                    isMapOnly={true}
                                    marker={{ lat: data.lat_masuk, lng: data.long_masuk }}
                                    setMarker={(val) => {
                                        if (val.length > 1) {
                                            console.log("Marker selected: ", val);
                                        }
                                    }}
                                    setAddress={(val) => {
                                        console.log("Address selected: ", val);
                                    }}
                                /> : "Tidak ada lokasi"
                            },
                            { label: 'Radius Masuk', value: `${data.radius_masuk} meter` },
                            { label: 'Durasi Telat', value: data.durasi_telat || "Tidak ada" },
                            { label: 'Foto Masuk', value: data.foto_masuk ? <Image height={100} src={`${data.main_path}${data.foto_masuk}`} alt="Foto Masuk" /> : <Image height={100} src={noImage} alt="Foto Pulang" /> },
                        ]}
                        renderItem={(item) => (
                            <List.Item>
                                <Text strong>{item.label}:</Text> {item.value}
                            </List.Item>
                        )}
                    />
                </Col>
                <Col xs={12}>
                    <Divider orientation="left">Detail Pulang</Divider>
                    <List
                        bordered
                        dataSource={[
                            { label: 'Waktu Pulang', value: data.waktu_pulang || "Belum pulang" },
                            { label: 'IP Pulang', value: data.ip_pulang || "Tidak ada" },
                            {
                                label: 'Lokasi Pulang (Lat, Long)', value: data.lat_pulang && data.long_pulang ? <MapComponentBasic
                                    isMapOnly={true}
                                    marker={{ lat: data.lat_masuk, lng: data.long_masuk }}
                                    setMarker={(val) => {
                                        if (val.length > 1) {
                                            console.log("Marker selected: ", val);
                                        }
                                    }}
                                    setAddress={(val) => {
                                        console.log("Address selected: ", val);
                                    }}
                                /> : "Tidak ada lokasi"
                            },
                            { label: 'Radius Pulang', value: `${data.radius_pulang} meter` },
                            { label: 'Durasi Pulang Cepat', value: data.durasi_pulang_cepat || "Tidak ada" },
                            { label: 'Foto Pulang', value: data.foto_pulang ? <Image height={100} src={`${data.main_path}${data.foto_pulang}`} alt="Foto Pulang" /> : <Image height={100} src={noImage} alt="Foto Pulang" /> },
                        ]}
                        renderItem={(item) => (
                            <List.Item>
                                <Text strong>{item.label}:</Text> {item.value}
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>

            <Divider orientation="left">Audit</Divider>
            <List
                bordered
                dataSource={[
                    { label: 'Dibuat Oleh', value: data.created_by },
                    { label: 'Tanggal Dibuat', value: data.created_at },
                ]}
                renderItem={(item) => (
                    <List.Item>
                        <Text strong>{item.label}:</Text> {item.value}
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default ModalDetail;
