import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Input, Tooltip, Breadcrumb, Table, Row, Col, Divider, Popover, Modal, Button, Radio, Space, Menu, Dropdown, List, Image, Upload, message } from 'antd';
import { PageHeader } from 'components/header';
import { InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined, DownCircleOutlined, SettingOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import validate from 'validate.js';

import { isAuth, _success, _validationFormMsg, _setAxios, _tableLogActivity, _getBase64, _beforeUploadImage, _getBase64Upload } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { SelectKondisiRuangan, SelectPenggunaanRuangan } from '../../../components/select/material-ui';

const schema = {
    nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
        length: {
            maximum: 200
        }
    },
    gedung_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jenis_ruangan_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    ukuran_panjang: {
        numericality: {
            message: "harus angka"
        }
    },
    ukuran_lebar: {
        numericality: {
            message: "harus angka"
        }
    },
};

class Ruangan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tableOption: {
                sortField: "id",
                sortValue: "ASC",
            },
            dataDetail: {},
            data: [],
            search: "",
            pagination: {
                current: 1,
                pageSize: 30,
            },
            loading: false,
            visibleModalCreate: false,
            id: 0,
            values: {},
            errors: {},
            create: {
                visible: false,
                loading: false,
            },
            update: {
                visible: false,
                loading: false,
            },
            delete: {
                visible: false,
                loading: false,
            },
            ddl: {
                lahan: [],
                kepemilikan: [
                    { id: 1, name: "Milik sendiri" },
                    { id: 1, name: "Bukan Milik sendiri" },
                ],
                gedung: [],
                jenisRuangan: []
            },

            previewImage: '',
            previewTitle: '',
            fileList: [],
            visible: false,
            validateImage: false,


        };
    }

    componentDidMount() {
        this.fetchTable(true)
        this.getLahanDDL()
        this.getGedungDDL()
        this.getJenisRuanganDDL()
    }

    fetchTable = (isNew = true) => {
        var params = {
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: isNew ? [] : [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
            search: this.state.search,
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSearch = (e) => {
        const { value } = e.target;

        this.setState({
            search: value,
        });

        var params = {
            search: value,
            pagination: { ...this.state.pagination, current: 1 },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);

    };

    handleTableFilter = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortField: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: e.target.value,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSort = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortValue: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: e.target.value,
                urutan: 1,
            }]
        });
    };

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        _setAxios("ruangan/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    loading: false,
                    data: resp.data.list,
                    pagination: {
                        ...params.pagination,
                        total: resp.data.info.total,
                    },
                });
            } else {
                this.setState({ loading: false });
            }
        })
    };

    getLahanDDL = () => {
        _setAxios("lahan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        lahan: resp.data.data
                    }
                });
            }
        })
    }

    getGedungDDL = () => {
        _setAxios("gedung", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        gedung: resp.data.data
                    }
                });
            }
        })
    }

    getJenisRuanganDDL = () => {
        _setAxios("jenis-ruangan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenisRuangan: resp.data.data
                    }
                });
            }
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    handleChangeSetValue = (name, value) => {
        this.setForm(name, value)
    };


    setForm = (name, value, row = {}) => {
        const { values } = this.state;
        values[name] = value;
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    resetForm = () => {
        this.setState({
            values: {},
            errors: {},
        });
    };


    // CREATE START ------------------------------------------
    showModalCreate = () => {
        this.setState({
            create: {
                ...this.state.create,
                visible: true,
            },

        });
    };

    hideModalCreate = () => {
        this.setState({
            create: {
                visible: false,
                loading: false,
            },
        });
        this.resetForm()
    };

    createSubmit = async (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        var params = {}
        params.lahan_id = values.lahan_id ? values.lahan_id.id : null
        params.gedung_id = values.gedung_id.id
        params.jenis_ruangan_id = values.jenis_ruangan_id.id
        params.nama = values.nama
        params.penggunaan = values.penggunaan ? values.penggunaan.value : null
        params.kondisi = values.kondisi ? values.kondisi.value : null
        params.ukuran_panjang = values.ukuran_panjang ? values.ukuran_panjang : 0
        params.ukuran_lebar = values.ukuran_lebar ? values.ukuran_lebar : 0
        params.thn_dibangun = values.thn_dibangun instanceof Date ? values.thn_dibangun.getFullYear() : 0

        let fileList = this.state.fileList
        let foto = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                foto = await _getBase64(fileList[0].originFileObj)
                foto = _getBase64Upload(foto)
            }
        }
        params.foto = foto

        if (this.state.fileList.length > 0) {
            if (this.state.fileList[0].status === "error") {
                message.error('File image yang Anda upload tidak sesuai.!');
                return false
            }
        }

        this.setState({
            create: {
                ...this.state.create,
                loading: true
            }
        });
        _setAxios("ruangan", "POST", params).then(resp => {
            if (resp.status === true) {
                this.hideModalCreate()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    create: {
                        ...this.state.create,
                        loading: false
                    }
                });
            }
        })
    };

    // CREATE END ------------------------------------------

    // UPDATE START ------------------------------------------
    showModalUpdate = (row) => {
        this.setState({
            update: {
                visible: true,
                loading: false
            },

            id: row.id,
            values: {
                nama: row.nama,
                jenis_ruangan_id: {
                    id: row.jenis_ruangan_id,
                    nama: row.jenis_ruangan_nama
                },
                gedung_id: {
                    id: row.gedung_id,
                    nama: row.gedung_nama
                },
                lahan_id: {
                    id: row.lahan_id,
                    nama: row.lahan_nama
                },
                thn_dibangun: row.thn_dibangun,
                ukuran_panjang: row.ukuran_panjang,
                ukuran_lebar: row.ukuran_lebar,
                kondisi: row.kondisi || null,
                penggunaan: row.penggunaan 
            },
            fileList: row.foto ? [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: row.main_path + row.foto
                },
            ] : [],
        });
    };

    hideModalUpdate = () => {
        this.setState({
            update: {
                visible: false,
                loading: false
            },
            values: {},
            fileList: []
        });
        this.resetForm()
    };

    updateSubmit = async (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        var params = {}
        params.lahan_id = values.lahan_id ? values.lahan_id.id : null
        params.gedung_id = values.gedung_id.id
        params.jenis_ruangan_id = values.jenis_ruangan_id.id
        params.nama = values.nama
        params.penggunaan = values.penggunaan ? values.penggunaan : null
        params.kondisi = values.kondisi ? values.kondisi : null
        params.ukuran_panjang = values.ukuran_panjang ? values.ukuran_panjang : 0
        params.ukuran_lebar = values.ukuran_lebar ? values.ukuran_lebar : 0
        params.thn_dibangun = values.thn_dibangun instanceof Date ? values.thn_dibangun.getFullYear() : 0

        let fileList = this.state.fileList
        let foto = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                foto = await _getBase64(fileList[0].originFileObj)
                foto = _getBase64Upload(foto)
            }
        }
        params.foto = foto

        if (this.state.fileList.length > 0) {
            if (this.state.fileList[0].status === "error") {
                message.error('File image yang Anda upload tidak sesuai.!');
                return false
            }
        }

        this.setState({
            update: {
                ...this.state.update,
                loading: true
            }
        });
        _setAxios("ruangan/" + this.state.id, "PUT", params).then(resp => {
            if (resp.status === true) {
                this.hideModalUpdate()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    update: {
                        ...this.state.update,
                        loading: false
                    }
                });
            }
        })
    };
    // UPDATE END ------------------------------------------

    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>
                Setiap data Ruangan yang dihapus di sistem ini, maka juga akan otomatis menghapus :
                <ul>
                    <li>Relasi antara Ruangan dengan Rombel, sehingga Rombel tidak memiliki ruang kelas</li>
                </ul>
                ✅ Apakah anda yakin?
            </>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("ruangan/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    handleCancel = () => this.setState({ visible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await _getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            visible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChangeImage = ({ fileList }) => {
        if (this.state.validateImage === true) {
            fileList.forEach((row, i) => {
                fileList[i]["status"] = "done"
            });
        }
        this.setState({ fileList })
    };

    beforeUploadImage = (file) => {
        const validate = _beforeUploadImage(file)
        if (validate === false) {
            this.setState({ validateImage: false })
        } else {
            this.setState({ validateImage: true })
        }
        return true
    };


    render() {
        const access = this.props.privilege.access["/sarana-prasarana/ruangan"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { data, pagination, loading, values, errors, tableOption, previewImage, fileList, visible } = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div>Upload</div>
            </div>
        );

        const uploadComp = <>
            <ImgCrop rotate>
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    maxCount={1}
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChangeImage}
                    beforeUpload={this.beforeUploadImage}
                >
                    {fileList.length >= 1 ? null : uploadButton}
                </Upload>
            </ImgCrop>

            <Image
                width={200}
                style={{ display: 'none' }}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                preview={{
                    visible,
                    src: previewImage,
                    onVisibleChange: value => {
                        this.setState({
                            visible: value
                        })
                    },
                }}
            />
        </>


        const sortComponent = <>
            <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
                <Space direction="vertical">
                    <Radio value="id">Tanggal Pembuatan</Radio>
                    <Radio value="nama">Nama</Radio>
                </Space>
            </Radio.Group>

            <Divider orientation="left"></Divider>

            <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
                <Space direction="vertical">
                    <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
                    <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
                </Space>
            </Radio.Group>
        </>

        const formInput = <>
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Ruangan *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={this.state.ddl.jenisRuangan}
                        value={values.jenis_ruangan_id || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("jenis_ruangan_id", newValue ? newValue : null)
                        }}
                        getOptionLabel={(option) => option.nama}
                        name="jenis_ruangan_id"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jenis Ruangan *"
                            size="small"
                            error={this.hasError('jenis_ruangan_id')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('jenis_ruangan_id') ? errors.jenis_ruangan_id[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24} sm={16}>
                    <Autocomplete
                        options={this.state.ddl.gedung}
                        value={values.gedung_id || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("gedung_id", newValue ? newValue : null)

                        }}
                        getOptionLabel={(option) => option.nama}
                        name="gedung_id"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Gedung *"
                            size="small"
                            error={this.hasError('gedung_id')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('gedung_id') ? errors.gedung_id[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24} sm={8}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            views={['year']}
                            label="Tahun Dibangun"
                            value={values.thn_dibangun || null}
                            onChange={(newValue) => {
                                this.handleChangeSetValue("thn_dibangun", newValue)
                            }}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                size="small"
                                error={this.hasError('thn_dibangun')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    this.hasError('thn_dibangun') ? errors.thn_dibangun[0] : null
                                }
                            />}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Ukuran Panjang (m)"
                        size="small"
                        placeholder="0"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="ukuran_panjang"
                        onChange={this.handleChange}
                        value={values.ukuran_panjang || ''}
                        error={this.hasError('ukuran_panjang')}
                        helperText={
                            this.hasError('ukuran_panjang') ? errors.ukuran_panjang[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Ukuran Lebar (m)"
                        size="small"
                        placeholder="0"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="ukuran_lebar"
                        onChange={this.handleChange}
                        value={values.ukuran_lebar || ''}
                        error={this.hasError('ukuran_lebar')}
                        helperText={
                            this.hasError('ukuran_lebar') ? errors.ukuran_lebar[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <SelectKondisiRuangan
                        errors={errors}
                        value={values.kondisi || ''}
                        onChange={(e, val) => {
                            this.handleChangeSetValue("kondisi", val ? val.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <SelectPenggunaanRuangan
                        errors={errors}
                        value={values.penggunaan || null}
                        onChange={(e, val) => {
                            this.handleChangeSetValue("penggunaan", val ? val.value : null)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    {uploadComp}
                </Col>
                <Col xs={24} sm={12} style={{ textAlign: "center" }}>
                    <div>Upload Foto Ruangan</div>
                    <div>maks. 2MB bertipe jpg png</div>
                </Col>

            </Row>
        </>

        const modalCreate = <Modal
            title="Tambah Ruangan"
            visible={this.state.create.visible}
            onCancel={this.hideModalCreate}
            footer={<>
                <Button onClick={this.hideModalCreate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.createSubmit} loading={this.state.create.loading}>Simpan</Button>
            </>}
        >
            <form autoComplete="off" onSubmit={this.createSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalUpdate = <Modal
            title="Update Ruangan"
            onCancel={this.hideModalUpdate}
            visible={this.state.update.visible}
            footer={<>
                <Button onClick={this.hideModalUpdate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.updateSubmit} loading={this.state.update.loading}>Simpan</Button>
            </>}

        >
            <form autoComplete="off" onSubmit={this.updateSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalDelete = <Modal
            title={<><ExclamationCircleOutlined /> Confirm</>}
            visible={this.state.delete.visible}
            onCancel={this.hideModalDelete}
            width={200}
            footer={<>
                <Button onClick={this.hideModalDelete}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Simpan</Button>
            </>}
        >
            Apakah Anda Yakin?

        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
                        <Breadcrumb.Item>Ruangan</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create === true ? <Tooltip title="Tambah">
                        <Button type='primary' block onClick={this.showModalCreate}><PlusOutlined /> Tambah</Button>
                    </Tooltip> : ""}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Ruangan"
                        subTitle="Data sarana prasarana ruangan"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={16} sm={18} md={20} lg={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={this.handleTableSearch}
                                value={this.state.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={8} sm={6} md={4} lg={3} className="text-align-right">
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Col>
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        sorter: false,
                                        width: '3%',
                                    },
                                    {
                                        title: 'Ruangan',
                                        dataIndex: 'penggunaan',
                                        sorter: false,
                                        width: '20%',
                                        render: (a, row) => <List.Item style={{ padding: 0 }}>
                                            <List.Item.Meta
                                                title={row.nama}
                                                description={row.penggunaan}
                                            />
                                        </List.Item>,
                                    },
                                    {
                                        title: 'Lahan & Gedung',
                                        dataIndex: 'gedung_nama',
                                        sorter: false,
                                        width: '15%',
                                        render: (a, row) => <List.Item style={{ padding: 0 }}>
                                            <List.Item.Meta
                                                title={row.lahan_nama}
                                                description={row.gedung_nama}
                                            />
                                        </List.Item>,
                                    },
                                    {
                                        title: 'Jenis Ruangan',
                                        dataIndex: 'jenis_ruangan_nama',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Tahun Dibangun',
                                        dataIndex: 'thn_dibangun',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Panjang (m)',
                                        dataIndex: 'ukuran_panjang',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Lebar (m)',
                                        dataIndex: 'ukuran_lebar',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Kondisi',
                                        dataIndex: 'kondisi',
                                        sorter: false,
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.detail === true ? (<Menu.Item key={0}><Link to={"/sarana-prasarana/ruangan/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                                            {action.update ? (<Menu.Item key={1} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                                            {action.delete ? (<Menu.Item key={2} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={data}
                                pagination={pagination}
                                loading={loading}
                                onChange={this.handleTableChange}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalCreate}

                {modalUpdate}

                {modalDelete}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Ruangan);
