import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, Button, Breadcrumb, BackTop } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';

import { isAuth, _setAxios, getParamTable } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/AuthRedirect'
import Desktop from './Desktop';
import Mobile from './Mobile';
import SortingTableDesktop from '../../../../components/sorting-table/SortingTableDesktop';
import SortingTableMobile from '../../../../components/sorting-table/SortingTableMobile';
import SearchTable from '../../../../components/search-table/SearchTable';

const sortFields = [{
  value: "kategori_nama",
  label: "Kategori",
}, {
  value: "unit_nama",
  label: "Unit",
}, {
  value: "jml_baik",
  label: "Jumlah Baik",
}, {
  value: "jml_rusak_ringan",
  label: "Jumlah Rusak Ringan",
}, {
  value: "jml_rusak_berat",
  label: "Jumlah Rusak Berat",
}]

class AssetTetap extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          // kategori: "Artikel",
        },
      },
      form: {
        data: {},
        visible: false
      },
      delete: {
        data: {},
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    
    _setAxios(`sarpras/asset-tetap/table/${this.props.match.params.kategori_id}/${this.props.match.params.unit_id}`, "POST", params).then(resp => {
      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  render() {

    const access = this.props.privilege.access["/sarana-prasarana/asset-tetap"]
    if (!isAuth(this.props.privilege) || access === undefined || access.action.detail === false) {
      return <AuthRedirect />
    }
    const action = access.action
    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
            <Breadcrumb.Item>Asset Tetap </Breadcrumb.Item>
            <Breadcrumb.Item>Detail</Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.create ? <Button type='primary' onClick={this.modalForm.bind(this, true)}><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title={"Kategori "+this.props.match.params.kategori_nama}
            subTitle={"Unit "+this.props.match.params.unit_nama}
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobile table={table} fetch={this.fetch} sortFields={sortFields} />
              <SortingTableDesktop table={table} fetch={this.fetch} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </MobileView>
            </Col>

          </Row>
        </div>
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(AssetTetap);
