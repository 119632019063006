import React from 'react';
import { connect } from 'react-redux'
import validate from 'validate.js';
import { Table, Row, Col, Button, Menu, Dropdown, Input, Tooltip, Modal, message } from 'antd';
import { PageHeader } from 'components/header';
import { SaveOutlined, MenuOutlined, InfoCircleOutlined, SearchOutlined, SettingOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { _setAxios, _success, _tableLogActivity, getParamTable } from '../../../lib/Helper';
import { jenisPendaftaranAnggotaSiswaOptions } from '../../../data/options';
import { schemaAnggotaSiswa } from './schema';


import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

class AnggotaSiswa extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10000,
                    total: 0,
                },
                sorting: [{
                    field: "no_absen",
                    sort: "ASC",
                    urutan: 1
                }],
                filtering: {},
            },
            tablePesertaDidik: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    rombel_id: null
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,
            selectedRowKeys: [],
            ddl: {
                rombel: []
            }
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getRombelDDL()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("rombel-assign/table-utama/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        },
                    },
                    selectedRowKeys: [],
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    fetchTablePesertaDidik = () => {
        const { tablePesertaDidik } = this.state
        const params = getParamTable("default", tablePesertaDidik)
        this.fetchPesertaDidik(params);
    }

    fetchPesertaDidik = async (params = {}) => {
        this.setState({
            tablePesertaDidik: {
                ...params,
                loading: true
            }
        });
        _setAxios("rombel-assign/table-peserta-didik/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    tablePesertaDidik: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    },
                    selectedRowKeys: [],
                });
            } else {
                this.setState({
                    tablePesertaDidik: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    getRombelDDL = () => {
        _setAxios("rombel/rombel-asal", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombel: resp.data.data
                    },
                })
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values, tablePesertaDidik } = this.state;
        if (name === "jenis_pendaftaran") {
            values["rombel"] = null;
            this.fetchPesertaDidik(getParamTable("filter", tablePesertaDidik, null, "rombel_id"))
        }
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaAnggotaSiswa);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaAnggotaSiswa);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        this.fetchTablePesertaDidik()

        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    nama: row.nama ? row.nama : null,
                    tempat_lahir: row.tempat_lahir ? row.tempat_lahir : null,
                    tgl_lahir: row.tgl_lahir ? row.tgl_lahir : null,
                    pendidikan: row.pendidikan ? row.pendidikan : null,
                    jenis_kelamin: row.jenis_kelamin ? row.jenis_kelamin : null,
                    pekerjaan: row.pekerjaan ? row.pekerjaan : null,
                    status: row.status ? row.status : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmit = (row) => {
        const { values } = this.state

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            },
            values: {}
        })

        //Validation 
        if (!values.jenis_pendaftaran) {
            return message.warning("Jenis pendaftaran harus dipilih");
        }
        // Payload
        const params = []
        this.state.selectedRowKeys.forEach(siswaID => {
            params.push({
                siswa_id: siswaID,
                jenis_pendaftaran: values.jenis_pendaftaran ? values.jenis_pendaftaran : null,
            })
        });

        _setAxios("rombel-assign/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                },
                values: {}
            })
        })
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    // CREATE END ------------------------------------------

    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("rombel-assign/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // DRAG STRAT ----------
    onSortEnd = ({ oldIndex, newIndex }) => {
        const { table } = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(table.data), oldIndex, newIndex).filter(
                el => !!el,
            );
            this.setState({
                table: {
                    ...this.state.table,
                    data: newData
                }
            });
        }
    };

    DraggableContainer = props => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperclassName="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { table } = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index

        const index = table.data ? table.data.findIndex(x => x.index === restProps['data-row-key']) : 0;
        return <SortableItem index={index} {...restProps} />;
    };
    // DRAG END ----------

    onSubmitUrutan = (row) => {
        Modal.confirm({
            title: 'Simpan Perubahan Urutan No.Absen',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan disimpan sesuai perubahan urutan no.absensi, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.setState({
                        table: {
                            ...this.state.table,
                            loading: true
                        }
                    });

                    const paylods = []
                    this.state.table.data.forEach((row, i) => {
                        paylods.push({
                            id: row.id,
                            no_absen: (i + 1),
                        })
                    });

                    _setAxios("rombel-assign/no-absen", "PUT", paylods).then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const { table, tablePesertaDidik, values, errors, ddl, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        const modalForm = <Modal
            title="Tambah Peserta Didik ke Rombel"
            visible={this.state.form.visible}
            width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmit} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Autocomplete
                        options={jenisPendaftaranAnggotaSiswaOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jenis Pendaftaran"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jenis_pendaftaran"
                            type="text"
                            value={values.jenis_pendaftaran || null}
                            error={this.hasError('jenis_pendaftaran')}
                            helperText={
                                this.hasError('jenis_pendaftaran') ? errors.jenis_pendaftaran[0] : null
                            }
                        />}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("jenis_pendaftaran", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                {
                    !values.jenis_pendaftaran ? <div style={{ textAlign: "center", width: "100%", color: "#10101061" }}>Pilih jenis pendaftaran terlebih dahulu</div> :
                        <>
                            {
                                values.jenis_pendaftaran !== "Naik kelas" ? "" :
                                    <Col xs={24} sm={8}>
                                        <Autocomplete
                                            options={ddl.rombel}
                                            getOptionLabel={(option) =>
                                                option.nama
                                            }
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Rombel Asal"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="rombel"
                                                type="text"
                                                error={this.hasError('rombel')}
                                                helperText={
                                                    this.hasError('rombel') ? errors.rombel[0] : null
                                                }
                                            />}
                                            value={values.rombel || null}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("rombel", newValue ? newValue : null)
                                                this.fetchPesertaDidik(getParamTable("filter", tablePesertaDidik, newValue ? newValue.id : null, "rombel_id"))
                                            }}
                                        />
                                    </Col>
                            }
                            <Col xs={24} sm={values.jenis_pendaftaran === "Naik kelas" ? 16 : 24}>
                                <Input
                                    name="search"
                                    className='search-table'
                                    placeholder="Search..."
                                    prefix={<SearchOutlined className="site-form-item-icon" />}
                                    onChange={(e) => {
                                        const params = getParamTable("search", tablePesertaDidik, e.target.value)
                                        this.fetchPesertaDidik(params)
                                    }}
                                    value={tablePesertaDidik.search || ""}
                                    suffix={
                                        <Tooltip title="Cari berdasarkan nama">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    }
                                />
                            </Col>
                            <Col xs={24}>
                                <div>
                                    <div style={{ marginBottom: 16 }}>
                                        {/* <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                                Reload
                            </Button> */}
                                        <span style={{ marginLeft: 8 }}>
                                            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                                        </span>
                                    </div>
                                    <Table
                                        columns={[
                                            {
                                                title: 'No',
                                                dataIndex: 'rownum',
                                                width: '3%',
                                            },
                                            {
                                                title: 'NISN',
                                                dataIndex: 'nisn',
                                            },
                                            {
                                                title: 'Nama Siswa',
                                                dataIndex: 'nama',
                                            },
                                            {
                                                title: 'Asal Kelas',
                                                dataIndex: 'asal_kelas',
                                            },
                                        ]}
                                        rowKey={record => record.id}
                                        dataSource={tablePesertaDidik.data}
                                        pagination={tablePesertaDidik.pagination}
                                        loading={tablePesertaDidik.loading}
                                        onChange={(pagination) => {
                                            const params = getParamTable("change", tablePesertaDidik, null, null, pagination)
                                            this.fetchPesertaDidik(params)
                                        }}
                                        rowSelection={rowSelection}
                                        size="small"
                                    />
                                </div>
                            </Col>
                        </>
                }
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Anggota Siswa"
                    extra={
                        <>
                            <Tooltip title="Tambah">
                                <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </>
                    }

                />

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'Sort',
                                    dataIndex: 'sort',
                                    width: 30,
                                    className: 'drag-visible',
                                    render: () => <DragHandle />,
                                },
                                {
                                    title: 'NISN',
                                    width: 100,
                                    dataIndex: 'nisn',
                                },
                                {
                                    title: 'Nama Siswa',
                                    dataIndex: 'siswa_nama',
                                    render: (a, row) => <div style={{ minWidth: 300 }}>{row.siswa_nama}</div>
                                },
                                {
                                    title: 'No.Absen',
                                    width: 30,
                                    dataIndex: 'no_absen',
                                },
                                {
                                    title: <SettingOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            dataSource={table.data}
                            pagination={false}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                            rowKey="index"
                            components={{
                                body: {
                                    wrapper: this.DraggableContainer,
                                    row: this.DraggableBodyRow,
                                },
                            }}
                        />
                    </Col>
                    <Col xs={24} style={{ textAlign: "right" }}>
                        <br />
                        <Button style={{ margin: '0 0 0 8px' }} type="primary" htmlType="submit" icon={<SaveOutlined />} loading={table.loading} onClick={this.onSubmitUrutan}>
                            Simpan
                        </Button>
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(AnggotaSiswa);
