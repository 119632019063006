import React from 'react';
import { connect } from 'react-redux'
import { PageHeader } from 'components/header';
import { isAuth, _setAxios } from '../../../lib/Helper';

import AuthRedirect from '../../../components/AuthRedirect'
import EmptyDocument from '../../../components/empty/EmptyDocument';
import PreloadContent from '../../../components/preload/PreloadContent';
class ListIndex extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            file_active: null,
            loading: true
        };
    }

    componentDidMount() {
        this.getFileActive()
    }

    getFileActive = () => {
        _setAxios("lembaga/struktur-organisasi/document-show", "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                if (resp.data && data) {
                    this.setState({
                        loading: false,
                        file_active: data.file ? data.main_path + data.file : null
                    })
                }else{
                    this.setState({
                        loading: false
                    })
                }
            }else{
                this.setState({
                    loading: false
                })
            }
        })
    }

    render() {
        const access = this.props.privilege.access["/lembaga/struktur-organisasi"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Struktur Organisasi"
                    subTitle="Dokumen Aktif"
                />
                {
                    this.state.loading ? <PreloadContent /> :
                    this.state.file_active === null ? <EmptyDocument /> : <iframe src={this.state.file_active} type="application/pdf" title='File Aktif' style={{
                        width: "100%",
                        height: 720
                    }} />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(ListIndex);
