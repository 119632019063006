// useForm.js
import { useState } from 'react';
import { _setAxios, _success, _warn } from 'lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateFormat } from 'constant/constant_format';

dayjs.extend(customParseFormat);

const useForm = (data, fetchTable, hideModal) => {
    const [values, setValues] = useState({
        ...data,
        tgl: data.tgl_awal && data.tgl_akhir ? [dayjs(data.tgl_awal), dayjs(data.tgl_akhir)] : null,
        file: null
    });
    const [loading, setLoading] = useState(false);

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = (val) => {
        if (!val.tgl && !val.tgl[0] && !val.tgl[1]) {
            _warn('topRight', 'Error', 'Tolong pilih tanggal terlebih dahulu')
            return
        }

        if(!data?.id && !val.file) {
            _warn('topRight', 'Error', 'Tolong pilih file terlebih dahulu')
            return
        }

        const dateStart = dayjs(val?.tgl[0]).format(dateFormat);
        const dateEnd = dayjs(val?.tgl[1]).format(dateFormat);
        
        const params = {
            tahun_ajaran_id: val?.tahun_ajaran_id,
            semester_id: val?.semester_id,
            rombel_id: val?.rombel_id,
            siswa_id: val?.siswa_id,
            perizinan_jenis_id: val?.perizinan_jenis_id,
            tgl_awal: dateStart,
            tgl_akhir: dateEnd,
            keterangan: val?.keterangan,
            file: val?.file || null,
        };

        let endpoint = "perizinan/siswa";
        let method = "POST";

        if (data?.id) { 
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    };
};

export default useForm;
