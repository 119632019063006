import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import { Button, Spin } from "antd";
import "leaflet/dist/leaflet.css";
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder'; // Import the Geocoder
import L from "leaflet";

const geocoder = L.Control.Geocoder.nominatim({
  countrycodes: 'ID', // Membatasi hasil hanya untuk Indonesia
  bounded: 1, // Membatasi pencarian hanya dalam viewbox
  bbox: [5.88969, 95.3161, -10.3606, 141.0194]
});
// Set manual icon marker untuk mengatasi masalah
const customIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [41, 41],
});

const OnRenderInitialLocation = ({ marker, setAddress }) => {
  const map = useMapEvents({});

  useEffect(() => {
    if (marker) {
      geocoder.reverse(
        { lat: marker.lat, lng: marker.lng },
        map.options.crs.scale(map.getZoom()),
        (results) => {
          const address = results[0]?.name || "Address not found";
          setAddress(address);
        }
      );
    }
  }, [marker, setAddress, map]);

  return 
};

const OnRenderCurrentButton = ({ setPosition, setMarker, setLoading, setAddress }) => {
  const map = useMapEvents({});

  const handleCurrentLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          setPosition([latitude, longitude]);
          setMarker([latitude, longitude]);
          map.setView([latitude, longitude], 13);
          setLoading(false);
          geocoder.reverse(
            { lat: latitude, lng: longitude },
            map.options.crs.scale(map.getZoom()),
            (results) => {
              const address = results[0]?.name || "Address not found";
              setAddress(address);
            }
          );
        },
        (error) => {
          console.error("Error getting location: ", error);
          setLoading(false);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  return (
    <Button type="primary" onClick={handleCurrentLocation} style={{ position: "absolute", bottom: "20px", right: "16px", zIndex: 1000 }}>
      Current My Location
    </Button>
  );
};

const ZoomControls = () => {
  const map = useMap();

  const zoomIn = () => {
    map.zoomIn();
  };

  const zoomOut = () => {
    map.zoomOut();
  };

  return (
    <div style={{ position: "absolute", bottom: "20px", left: "16px", zIndex: 1000 }}>
      <Button onClick={zoomIn}>+</Button>
      <Button onClick={zoomOut} style={{ marginLeft: "5px" }}>-</Button>
    </div>
  );
};

const MapComponentBasic = ({ marker, setMarker, setAddress, isMapOnly = false }) => {
  const [position, setPosition] = useState([marker?.lat || 0, marker?.lng || 0]);
  const [loading, setLoading] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      {loading && <Spin style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1000 }} />}
      <MapContainer center={position} zoom={13} style={{ height: "200px", width: "100%" }} zoomControl={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {marker && <Marker position={marker} icon={customIcon} />}
        <OnRenderInitialLocation marker={marker} setAddress={setAddress} />
        {!isMapOnly && <ZoomControls />}
        {!isMapOnly && <OnRenderCurrentButton setPosition={setPosition} setMarker={setMarker} setLoading={setLoading} setAddress={setAddress} />}
      </MapContainer>
    </div>
  );
};

export default MapComponentBasic;
