import React from 'react';
import { Modal, Button, Form, Divider } from 'antd';
import { RangeMonth } from 'components/datetime';
import useExport from '../hooks/useExport';
import { SelectSemester, SelectTahunAjaran } from 'components/select';

const ModalExport = ({ privilege, visible, hideModal }) => {
    const [form] = Form.useForm(); // Initialize form instance
    const {
        values,
        handleChangeSetValue,
        loading,
        handleSubmit,
    } = useExport(privilege, hideModal);


    return (
        <Modal
            maskClosable={false}
            title={"Export"}
            open={visible}
            onCancel={hideModal}
            width={300}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Form.Item label="Tahun Ajaran" name="tahun_ajaran_id" rules={[{ required: true, message: 'Tahun Ajaran harus diisi' }]}>
                    <SelectTahunAjaran
                        allowClear
                        value={values.tahun_ajaran_id}
                        onChange={(val) => {
                            handleChangeSetValue("tahun_ajaran_id", val || null);
                            handleChangeSetValue("semester_id", null);
                            setTimeout(() => {
                                form.resetFields(['semester_id']);
                            }, 300);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Semester" name="semester_id" rules={[{ required: true, message: 'Semester harus diisi' }]}>
                    <SelectSemester
                        allowClear
                        value={values.semester_id}
                        tahunAjaranId={values.tahun_ajaran_id}
                        disabled={!values.tahun_ajaran_id ? true : false}
                        onChange={(val) => {
                            handleChangeSetValue("semester_id", val || null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Periode" name="periode" rules={[{ required: true, message: 'Periode harus diisi' }]}>
                    <RangeMonth />
                </Form.Item>

                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button onClick={hideModal} disabled={loading}>Batal</Button>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Lanjutkan</Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalExport;
