import React from 'react';
import { Modal, Button, Row, Col } from 'antd';
import html2canvas from "html2canvas";

import './style.css'
import { DownloadOutlined } from '@ant-design/icons';
import { _setAxios } from '../../../../lib/Helper';
import logoMan from '../../../../assets/images/logo-man.png'
import logoIkaman from '../../../../assets/images/logo-ikaman.png'
import moment from 'moment';
import PreloadContent from '../../../../components/preload/PreloadContent';

class modalPreviewKTA extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            data: {
                kta: {}
            }
        };
    }
    componentDidMount() {
        this.getDataKTAByID(this.props.data.id)
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleDownloadImage = async () => {
        const element = document.getElementById('print'),
            canvas = await html2canvas(element),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        link.href = data;
        link.download = 'downloaded-image.jpg';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    getDataKTAByID = (id) => {
        this.setState({
            loading: true,
        })
        _setAxios("alumni/kta/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    loading: false,
                    data: {
                        ...this.state.data,
                        kta: data
                    }
                })
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    render() {

        const { data, loading } = this.state
        const dataDiri = data.kta.data_diri ? data.kta.data_diri : {}
        const dataSetting = data.kta.setting ? data.kta.setting : {}

        return (
            <Modal
                title="Preview KTA"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} onClick={this.handleDownloadImage} loading={this.state.loading}>Download</Button>
                </>}
            >
                <div className="App">
                    {
                        loading ? <PreloadContent /> :
                            <div id="print" style={{
                                backgroundImage: `url(${dataSetting.Background})`
                            }}>
                                <div className="grid-container-kta">
                                    <div className="logoman"><img src={logoMan} alt={"Logo Man 1 Gresik"}/></div>
                                    <div className="titleman">
                                        <h3>IKATAN KELUARGA BESAR ALUMNI</h3>
                                        <h3 style={{
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            color: '#039264'
                                        }}>MAN 1 GRESIK</h3>
                                    </div>
                                    <div className="logoikaman"><img src={logoIkaman} alt={"Logo Ikaman"}/></div>
                                </div>
                                <Row guttent={[16, 16]}>
                                    <Col xs={18}>
                                        <Row guttent={[16, 16]}>
                                            <Col xs={24}><h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>{dataDiri.no_kta ? dataDiri.no_kta : ""}</h3></Col>
                                            <Col xs={24} style={{
                                                fontWeight: 'bold',
                                                fontSize: 18,
                                                color: '#039264'
                                            }}>{dataDiri.nama ? dataDiri.nama : ""}</Col>
                                            <Col xs={24}>{dataDiri.tempat_lahir ? dataDiri.tempat_lahir : ""}, {dataDiri.tgl_lahir ? moment(dataDiri.tgl_lahir).format('MMMM DD, YYYY') : ""}</Col>
                                            <Col xs={24}>
                                                {dataDiri.alamat ? dataDiri.alamat : null}
                                                {dataDiri.kelurahan_nama ? "," + dataDiri.kelurahan_nama : null}
                                                {dataDiri.kecamatan_nama ? "," + dataDiri.kecamatan_nama : null}
                                                {dataDiri.kabkot_nama ? "," + dataDiri.kabkot_nama : null}
                                                {dataDiri.provinsi_nama ? "," + dataDiri.provinsi_nama : null}
                                                {dataDiri.kode_pos ? dataDiri.kode_pos : null}
                                            </Col>
                                            <div style={{
                                                "width": "60px",
                                                "overflow": "hidden",
                                                "height": "60px",
                                                "margin": "15px 0 0px 2px"
                                            }}>
                                                {dataDiri.qrcode ? <img src={dataDiri.qrcode} alt={"QrCode Man 1 Gresik"} style={{width: "100%"}}/> : null}
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col xs={6} style={{ textAlign: 'center' }}>
                                        <img width={100} alt={"Foto Siswa Man 1 Gresik"} style={{margin: "24px 0 0 0"}} src={dataDiri.foto} />
                                    </Col>
                                </Row>
                            </div>
                    }
                </div>
            </Modal>
        )
    }
}

export default modalPreviewKTA;
