import React, { useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import { Popover, Button } from 'antd';
import { SortAscendingOutlined } from '@ant-design/icons';

import SortingTable from './SortingTable';
import { _scrollFloatBtn } from '../../lib/Helper';

const SortingTableOnly = ({ table, fetch, sortFields }) => {

  useEffect(() => {
    _scrollFloatBtn();
  }, []);

  return (
    <>
      <BrowserView>
        <Button.Group className='ant-btn-group-fullwidth'>
          <Popover placement="bottom" content={<SortingTable fetch={fetch} table={table} sortFields={sortFields} />} trigger="click">
            <Button block className='btn-border-radius'><SortAscendingOutlined /> Urutkan</Button>
          </Popover>
        </Button.Group>
      </BrowserView>
      <MobileView>
        <div className='float-btn-center' id='float-btn-center' align='center'>
          <div className="float-btn-div">
            <Popover placement="top" content={<SortingTable fetch={fetch} table={table} sortFields={sortFields} />} trigger="click">
              <Button type='primary' className='float-btn' icon={<SortAscendingOutlined />}>Urutkan</Button>
            </Popover>
          </div>
        </div>
      </MobileView>
    </>
  );
}

export default SortingTableOnly;
