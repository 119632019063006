import React from 'react';
import { TimePicker } from 'antd';
import { timeFormat } from 'constant/constant_format';

const RangeTime = ({ ...props }) => {
    return (
        <TimePicker.RangePicker 
        {...props}
        style={props.style ? props.style : { width: '100%' }}
        format={timeFormat}
        allowEmpty={[true, true]}
        />
    );
};
export default RangeTime;
