import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Modal, Button, Divider, Form } from 'antd';
import useForm from '../hooks/useForm';
import { SelectRombel, SelectSemester, SelectSiswaWithRombel, SelectTahunAjaran } from 'components/select';
import { Date, RangeTime } from 'components/datetime';
import { MapComponent } from 'components/maps';
import Camera from 'components/camera';
import { CameraOutlined } from '@ant-design/icons';

const ModalForm = ({ presensiSetting, data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance
    const videoRef = useRef(null); // Untuk menyimpan referensi video stream
    const [mediaStream, setMediaStream] = useState(null); // State untuk menyimpan stream
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [currentCamera, setCurrentCamera] = useState('user'); // 'user' untuk kamera depan, 'environment' untuk kamera belakang
    const [tipeFoto, setTipeFoto] = useState('');

    const {
        values,
        loading,
        loadingContent,
        previewMasuk,
        previewPulang,
        setPreviewMasuk,
        setPreviewPulang,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(presensiSetting, data, fetchTable, hideModal);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(values); // Set form values when modal opens
        }
    }, [visible, values, form]);

    const handleOpenCamera = async (fotoType) => {
        setTipeFoto(fotoType);
        setIsCameraOpen(true);
        if (fotoType === 'masuk') {
            setPreviewMasuk(null);
        } else if (fotoType === 'pulang') {
            setPreviewPulang(null);
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: currentCamera } });
            setMediaStream(stream); // Menyimpan stream ke state
            if (videoRef.current) {
                videoRef.current.srcObject = stream; // Menghubungkan stream ke video
                await videoRef.current.play(); // Memastikan video mulai diputar
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const handleTakePhoto = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0);
        const dataUri = canvas.toDataURL('image/jpeg');

        if (tipeFoto === 'masuk') {
            setPreviewMasuk(dataUri);
            handleChangeSetValue('foto_masuk', dataUri);
        } else if (tipeFoto === 'pulang') {
            setPreviewPulang(dataUri);
            handleChangeSetValue('foto_pulang', dataUri);
        }
    };

    if (!presensiSetting || loadingContent) {
        return null;
    }

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Kehadiran Siswa"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Tahun Ajaran"
                            name="tahun_ajaran_id"
                            rules={[{ required: true, message: 'Tahun Ajaran harus diisi!' }]}>
                            <SelectTahunAjaran
                                allowClear
                                disabled={data.id ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("tahun_ajaran_id", val || null);
                                    handleChangeSetValue("semester_id", null);
                                    handleChangeSetValue("rombel_id", null);
                                    handleChangeSetValue("siswa_id", null);
                                    form.resetFields(['semester_id', 'rombel_id', 'siswa_id']);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Semester"
                            name="semester_id"
                            rules={[{ required: true, message: 'Semester harus diisi!' }]}>
                            <SelectSemester
                                allowClear
                                tahunAjaranId={values.tahun_ajaran_id}
                                disabled={!values.tahun_ajaran_id || data.id ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("semester_id", val || null);
                                    handleChangeSetValue("rombel_id", null);
                                    handleChangeSetValue("siswa_id", null);
                                    form.resetFields(['rombel_id', 'siswa_id']);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Rombel" name="rombel_id" rules={[{ required: true, message: 'Rombel harus diisi!' }]}>
                            <SelectRombel
                                allowClear
                                tahunAjaranId={values.tahun_ajaran_id}
                                semesterId={values.semester_id}
                                disabled={!values.tahun_ajaran_id || !values.semester_id || data.id ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("rombel_id", val || null);
                                    handleChangeSetValue("siswa_id", null);
                                    form.resetFields(['siswa_id']);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Siswa" name="siswa_id" rules={[{ required: true, message: 'Siswa harus diisi!' }]}>
                            <SelectSiswaWithRombel
                                allowClear
                                rombelId={values.rombel_id}
                                value={values.siswa_id}
                                disabled={!values.rombel_id || !values.tahun_ajaran_id || !values.semester_id || data.id ? true : false}
                                onChange={(val) => handleChangeSetValue("siswa_id", val || null)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item label="Tanggal" name="tgl" rules={[{ required: true, message: 'Tanggal harus diisi!' }]}>
                            <Date />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item label="Waktu Masuk - Pulang" name="waktu" rules={[{ required: true, message: 'Waktu harus diisi!' }]}>
                            <RangeTime
                                value={values.waktu}
                                onChange={(val) => handleChangeSetValue("waktu", val)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item label="Foto Masuk" name="foto_masuk" rules={[{ required: data.id ? false : true, message: 'Foto Masuk harus diisi!' }]}>
                            {previewMasuk ? (
                                <>
                                    <img
                                        src={previewMasuk}
                                        alt="Preview"
                                        style={{ width: '100%', maxHeight: 200, height: '100%', objectFit: 'cover' }}
                                    />
                                    <Button onClick={() => handleOpenCamera("masuk")} style={{ width: "100%" }} icon={<CameraOutlined />}>Buka Kamera</Button>
                                </>
                            ) : (
                                <><Button onClick={() => handleOpenCamera("masuk")} style={{ width: "100%" }} icon={<CameraOutlined />}>Buka Kamera</Button>
                                    <Camera
                                        videoRef={videoRef}
                                        setMediaStream={setMediaStream}
                                        setIsCameraOpen={setIsCameraOpen}
                                        setCurrentCamera={setCurrentCamera}
                                        handleOpenCamera={handleOpenCamera}
                                        onTakePhoto={handleTakePhoto}
                                        isCameraOpen={isCameraOpen}
                                        mediaStream={mediaStream}
                                        currentCamera={currentCamera}
                                    /></>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item label="Foto Pulang" name="foto_pulang" rules={[{ required: data.id ? true : false, message: 'Foto Pulang harus diisi!' }]}>
                            {previewPulang ? (
                                <>
                                    <img
                                        src={previewPulang}
                                        alt="Preview"
                                        style={{ width: '100%', maxHeight: 200, height: '100%', objectFit: 'cover' }}
                                    />
                                    <Button onClick={() => handleOpenCamera("pulang")} style={{ width: "100%" }} icon={<CameraOutlined />}>Buka Kamera</Button>
                                </>
                            ) : (
                                <><Button onClick={() => handleOpenCamera("pulang")} style={{ width: "100%" }} icon={<CameraOutlined />}>Buka Kamera</Button>
                                    <Camera
                                        videoRef={videoRef}
                                        setMediaStream={setMediaStream}
                                        setIsCameraOpen={setIsCameraOpen}
                                        setCurrentCamera={setCurrentCamera}
                                        handleOpenCamera={handleOpenCamera}
                                        onTakePhoto={() => handleTakePhoto("pulang")}
                                        isCameraOpen={isCameraOpen}
                                        mediaStream={mediaStream}
                                        currentCamera={currentCamera}
                                    /></>
                            )}
                        </Form.Item>
                    </Col>
                    {!isCameraOpen && (
                        <Col xs={24}>
                            <Form.Item label="Location" name="location">
                                <MapComponent
                                    marker={values?.location}
                                    setMarker={(val) => {
                                        if (val.length > 1) {
                                            handleChangeSetValue("location", { lat: val[0], lng: val[1] });
                                        }
                                    }}
                                    setAddress={(val) => handleChangeSetValue("address", val || null)}
                                />
                            </Form.Item>
                            {values.address && <p>{values.address}</p>}
                        </Col>
                    )}
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;