import React, { useEffect } from 'react';
import './style.css';
import { CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';

const CameraCustom = ({ videoRef, setMediaStream, setIsCameraOpen, setCurrentCamera, handleOpenCamera, onTakePhoto, isCameraOpen, mediaStream, currentCamera }) => {

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop()); // Menghentikan semua track
      setMediaStream(null); // Menghapus referensi stream
    }
  };

  const switchCamera = async () => {
    const newCamera = currentCamera === 'user' ? 'environment' : 'user';
    setCurrentCamera(newCamera);
    handleCloseCamera(); // Menutup kamera yang ada
    await handleOpenCamera(); // Memulai kamera yang baru
  };

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [mediaStream]);

  return (
    <div>
      {isCameraOpen &&
        <div className="bg-camera">
          <div className="content-camera">
            <video ref={videoRef} style={{ width: '100%' }} autoPlay muted />
          </div>
          <div className='btn-camera'>
            {isCameraOpen && <button className="btn-take-photo" onClick={() => {
              onTakePhoto();
              handleCloseCamera();
            }}></button>}
            <SyncOutlined className='icon-change-camera-native' onClick={() => {
              switchCamera()
            }} />
            <CloseCircleOutlined className='icon-close-camera-native' onClick={() => {
              handleCloseCamera();
            }} />
          </div>
        </div>}

    </div>
  );
};

export default CameraCustom;
