import React from 'react';
import { connect } from 'react-redux'
import { Row, Col, Button, Breadcrumb, Tabs } from 'antd';
import { PageHeader } from 'components/header';
import { Radio, Divider } from 'antd';

import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { isAuth, _setAxios } from '../../../lib/Helper';
import { kemitraanPihakLuarOptions, kecukupanAirBersihOptions, pembalutCadanganOptions, saluranPembuanganAirLimbahOptions, sumberAirMinumOptions, sumberAirOptions, tipeToiletOptions } from '../../../data/options';

import AuthRedirect from '../../../components/AuthRedirect'
import PreloadContent from '../../../components/preload/PreloadContent';

class SanitasiAir extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tabIndex: "0",
      loading: {
        sdguks: true,
        kegiatanMedia: false,
        submit: false,
      },
      values: {},
      valuesKegiatan: [],
      errors: {},
    };
  }

  getDataSdgUks = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        sdguks: true
      }
    });
    _setAxios("sarpras/sanitasi/air", "GET").then(resp => {
      if (resp.status === true) {
        let data = resp.data.data
        this.setState({
          loading: {
            ...this.state.loading,
            sdguks: false
          },
          values: {
            ...this.state.values,
            ...data
          }
        });
      } else {
        this.setState({
          loading: {
            ...this.state.loading,
            sdguks: false
          },
        });
      }
    })
  }

  getDataKegiatanMedia = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        kegiatanMedia: true
      },
    });
    _setAxios("sarpras/sanitasi/kegiatan-media", "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          loading: {
            ...this.state.loading,
            kegiatanMedia: false
          },
          valuesKegiatan: resp.data.data
        });
      } else {
        this.setState({
          loading: {
            ...this.state.loading,
            kegiatanMedia: false
          },
        });
      }
    })
  }

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  handleChangeSetValueArray = (idx, name, value) => {
    const { valuesKegiatan } = this.state;
    valuesKegiatan[idx][name] = value

    this.setState({
      valuesKegiatan: valuesKegiatan,
    });
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }

  setNextActiveKey = () => {
    let key = (parseInt(this.state.tabIndex) + 1).toString()
    this.setState({
      tabIndex: key
    });
  }

  setBackActiveKey = () => {
    let key = (parseInt(this.state.tabIndex) - 1).toString()
    this.setState({
      tabIndex: key
    });
  }

  handleChangeTab = (key) => {
    this.setState({
      tabIndex: key
    })
  }

  setDataSdg = (values) => {
    this.setState({
      values: {
        ...this.state.values,
        sdg: values
      },
    });

    this.setNextActiveKey()
  }

  setDataUks = (values) => {
    this.setState({
      values: {
        ...this.state.values,
        uks: values
      },
    });

    this.setNextActiveKey()
  }

  setDataKegiatanMedia = (values) => {
    this.setState({
      values: {
        ...this.state.values,
        kegiatanMedia: values
      },
    });

    this.onSubmit()
  }

  onSubmit = async () => {
    this.setState({
      loading: {
        ...this.state.loading,
        submit: true
      }
    })

    const { valuesKegiatan, values } = this.state;

    _setAxios("sarpras/sanitasi/1", "PUT", {
      "sanitasi_air": {
        air_bersih: values.air_bersih ? values.air_bersih : null,
        air_minum: values.air_minum ? values.air_minum : null,
        kecukupan_air_bersih: values.kecukupan_air_bersih ? values.kecukupan_air_bersih : null,
        jamban_khusus: values.jamban_khusus ? values.jamban_khusus : null,
        tipe_jamban: values.tipe_jamban ? values.tipe_jamban : null,
        pembalut_cadangan: values.pembalut_cadangan ? values.pembalut_cadangan : null,
        jml_hari_kegiatan_cuci_tangan: values.jml_hari_kegiatan_cuci_tangan ? values.jml_hari_kegiatan_cuci_tangan : null,
        jml_tempat_cuci_tangan_baik: values.jml_tempat_cuci_tangan_baik ? values.jml_tempat_cuci_tangan_baik : null,
        jml_tempat_cuci_tangan_rusak: values.jml_tempat_cuci_tangan_rusak ? values.jml_tempat_cuci_tangan_rusak : null,
        sabun_dan_air_mengalir_sdg: values.sabun_dan_air_mengalir_sdg ? values.sabun_dan_air_mengalir_sdg : null,
        saluran_pembuangan_limbah: values.saluran_pembuangan_limbah ? values.saluran_pembuangan_limbah : null,
        pengurusan_tangki_septik: values.pengurusan_tangki_septik ? values.pengurusan_tangki_septik : null,
        selokan: values.selokan ? values.selokan : null,
        tempat_sampah_kelas: values.tempat_sampah_kelas ? values.tempat_sampah_kelas : null,
        tempat_sampah_jamban: values.tempat_sampah_jamban ? values.tempat_sampah_jamban : null,
        cermin_jamban: values.cermin_jamban ? values.cermin_jamban : null,
        tps_tertutup: values.tps_tertutup ? values.tps_tertutup : null,
        sampah_diangkut_rutin: values.sampah_diangkut_rutin ? values.sampah_diangkut_rutin : null,
        rencana_anggaran_sanitasi: values.rencana_anggaran_sanitasi ? values.rencana_anggaran_sanitasi : null,
        kegiatan_rutin_sanitasi: values.kegiatan_rutin_sanitasi ? values.kegiatan_rutin_sanitasi : null,
        kemitraan_sanitasi: values.kemitraan_sanitasi ? values.kemitraan_sanitasi : null,
        jamban_laki_perempuan: values.jamban_laki_perempuan ? values.jamban_laki_perempuan : null,
        sabun_dan_air_mengalir_uks: values.sabun_dan_air_mengalir_uks ? values.sabun_dan_air_mengalir_uks : null,
        jml_jamban_baik_pria: values.jml_jamban_baik_pria ? values.jml_jamban_baik_pria : null,
        jml_jamban_baik_perempuan: values.jml_jamban_baik_perempuan ? values.jml_jamban_baik_perempuan : null,
        jml_jamban_baik_bersama: values.jml_jamban_baik_bersama ? values.jml_jamban_baik_bersama : null,
        jml_jamban_tidak_digunakan_pria: values.jml_jamban_tidak_digunakan_pria ? values.jml_jamban_tidak_digunakan_pria : null,
        jml_jamban_tidak_digunakan_perempuan: values.jml_jamban_tidak_digunakan_perempuan ? values.jml_jamban_tidak_digunakan_perempuan : null,
        jml_jamban_tidak_digunakan_bersama: values.jml_jamban_tidak_digunakan_bersama ? values.jml_jamban_tidak_digunakan_bersama : null,
      },
      kegiatan_dan_media: valuesKegiatan
    }).then(resp => {
      if (resp.status === true) {
        window.location.reload();
      } else {
      }
    })
  }


  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    this.setState({
      tabIndex: query.get("tab") ? query.get("tab") : "0"
    })

    this.getDataSdgUks()
    this.getDataKegiatanMedia()

  }


  render() {

    const { values, valuesKegiatan, errors, loading } = this.state
    // console.log("values", values.kegiatanMedia)

    const tabs = [{
      "title": "Sustainable Development Goals (SDG)",
      "content":
        loading.sdguks ? <PreloadContent /> : <Row gutter={[24, 24]} >
          <Col xs={24} md={15}>
            Sumber Air Bersih di Madrasah
          </Col>
          <Col xs={24} md={9}>
            <Autocomplete
              clearText={false}
              options={sumberAirOptions}
              value={values ? values.air_bersih : null}
              onChange={(e, newValue) => {
                this.handleChangeSetValue("air_bersih", newValue ? newValue.label : null)
              }}

              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Pilih Sumber Air *"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="air_bersih"
                type="text"
                error={this.hasError('air_bersih')}
                helperText={
                  this.hasError('air_bersih') ? errors.air_bersih[0] : null
                }
              />}
            />
          </Col>
          <Col xs={24} md={15}>
            Sumber Air Minum di Madrasah
          </Col>
          <Col xs={24} md={9}>
            <Autocomplete
              options={sumberAirMinumOptions}
              value={values ? values.air_minum : null}
              onChange={(e, newValue) => {
                this.handleChangeSetValue("air_minum", newValue ? newValue.label : null)
              }}

              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Pilih Sumber Air *"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="air_minum"
                type="text"
                error={this.hasError('air_minum')}
                helperText={
                  this.hasError('air_minum') ? errors.air_minum[0] : null
                }
              />}
            />
          </Col>
          <Col xs={24} md={15}>
            Kecukupan Air Bersih
          </Col>
          <Col xs={24} md={9}>
            <Autocomplete
              options={kecukupanAirBersihOptions}
              value={values ? values.kecukupan_air_bersih : null}
              onChange={(e, newValue) => {
                this.handleChangeSetValue("kecukupan_air_bersih", newValue ? newValue.label : null)
              }}

              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Pilih Status *"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="kecukupan_air_bersih"
                type="text"
                error={this.hasError('kecukupan_air_bersih')}
                helperText={
                  this.hasError('kecukupan_air_bersih') ? errors.kecukupan_air_bersih[0] : null
                }
              />}
            />
          </Col>
          <Col xs={24} md={15}>
            Madrasah Menyediakan Jamban yang dilengkapi fasilitas pendukung untuk siswa berkebutuhan khusus
          </Col>
          <Col xs={24} md={9}>
            <Radio.Group
              onChange={(e) => {
                this.handleChangeSetValue("jamban_khusus", e ? e.target.value : null)
              }}
              value={values ? values.jamban_khusus : null}>
              <Radio value={"1"}>Ya</Radio>
              <Radio value={"0"}>Tidak</Radio>
            </Radio.Group>
          </Col>
          <Col xs={24} md={15}>
            Tipe toilat/jamban yang dimiliki Madrasah
          </Col>
          <Col xs={24} md={9}>
            <Autocomplete
              options={tipeToiletOptions}
              value={values ? values.tipe_jamban : null}
              onChange={(e, newValue) => {
                this.handleChangeSetValue("tipe_jamban", newValue ? newValue.label : null)
              }}

              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Pilih Tipe Jamban *"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="tipe_jamban"
                type="text"
                error={this.hasError('tipe_jamban')}
                helperText={
                  this.hasError('tipe_jamban') ? errors.tipe_jamban[0] : null
                }
              />}
            />
          </Col>


          <Col xs={24} md={15}>
            Madrasah menyediakan pembalut cadangan
          </Col>
          <Col xs={24} md={9}>
            <Autocomplete
              options={pembalutCadanganOptions}
              value={values ? values.pembalut_cadangan : null}
              onChange={(e, newValue) => {
                this.handleChangeSetValue("pembalut_cadangan", newValue ? newValue.label : null)
              }}

              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Pilih Status *"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="pembalut_cadangan"
                type="text"
                error={this.hasError('pembalut_cadangan')}
                helperText={
                  this.hasError('pembalut_cadangan') ? errors.pembalut_cadangan[0] : null
                }
              />}
            />
          </Col>
          <Col xs={24} md={15}>
            Jumlah hari dalam seminggu siswa mengikuti kegiatan cuci tangan berkelompok
          </Col>
          <Col xs={24} md={9}>
            <TextField
              fullWidth
              label="Jumlah hari *"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="jml_hari_kegiatan_cuci_tangan"
              type="number"
              onChange={(e) => {
                this.handleChangeSetValue("jml_hari_kegiatan_cuci_tangan", e.target.value)
              }}
              value={values.jml_hari_kegiatan_cuci_tangan || null}
              error={this.hasError('jml_hari_kegiatan_cuci_tangan')}
              helperText={
                this.hasError('jml_hari_kegiatan_cuci_tangan') ? errors.jml_hari_kegiatan_cuci_tangan[0] : null
              }
            />
          </Col>
          <Col xs={24} md={15}>
            Jumlah tempat cuci tangan yang ada di Madrasah kondisi baik
          </Col>
          <Col xs={24} md={9}>
            <TextField
              fullWidth
              label="Jumlah tempat *"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="jml_tempat_cuci_tangan_baik"
              type="number"
              onChange={(e) => {
                this.handleChangeSetValue("jml_tempat_cuci_tangan_baik", e.target.value)
              }}
              value={values.jml_tempat_cuci_tangan_baik || null}
              error={this.hasError('jml_tempat_cuci_tangan_baik')}
              helperText={
                this.hasError('jml_tempat_cuci_tangan_baik') ? errors.jml_tempat_cuci_tangan_baik[0] : null
              }
            />
          </Col>
          <Col xs={24} md={15}>
            Jumlah tempat cuci tangan yang ada di Madrasah kondisi rusak
          </Col>
          <Col xs={24} md={9}>
            <TextField
              fullWidth
              label="Jumlah tempat *"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="jml_tempat_cuci_tangan_rusak"
              type="number"
              onChange={(e) => {
                this.handleChangeSetValue("jml_tempat_cuci_tangan_rusak", e.target.value)
              }}
              value={values.jml_tempat_cuci_tangan_rusak || null}
              error={this.hasError('jml_tempat_cuci_tangan_rusak')}
              helperText={
                this.hasError('jml_tempat_cuci_tangan_rusak') ? errors.jml_tempat_cuci_tangan_rusak[0] : null
              }
            />
          </Col>
          <Col xs={24} md={15}>
            Apakah sabun dan air mengalir tersedia ditempat cuci tangan?
          </Col>
          <Col xs={24} md={9}>
            <Radio.Group
              onChange={(e) => {
                this.handleChangeSetValue("sabun_dan_air_mengalir_sdg ", e ? e.target.value : null)
              }}
              value={values ? values.sabun_dan_air_mengalir_sdg : null}>
              <Radio value={"1"}>Ya</Radio>
              <Radio value={"0"}>Tidak</Radio>
            </Radio.Group>
          </Col>
          <Col xs={24} md={15}>
            Madrasah memiliki saluran pembuangan air limbah dan jamban?
          </Col>
          <Col xs={24} md={9}>
            <Autocomplete
              options={saluranPembuanganAirLimbahOptions}
              value={values ? values.saluran_pembuangan_limbah : null}
              onChange={(e, newValue) => {
                this.handleChangeSetValue("saluran_pembuangan_limbah", newValue ? newValue.label : null)
              }}

              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Pilih Tipe Jamban *"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="saluran_pembuangan_limbah"
                type="text"
                error={this.hasError('saluran_pembuangan_limbah')}
                helperText={
                  this.hasError('saluran_pembuangan_limbah') ? errors.saluran_pembuangan_limbah[0] : null
                }
              />}
            />
          </Col>
          <Col xs={24} md={15}>
            Madrasah pernah menguras tangki septik dalam 3 hingga 5 tahun terakhir dengan truk/motor sedot tinja?
          </Col>
          <Col xs={24} md={9}>
            <Radio.Group
              onChange={(e) => {
                this.handleChangeSetValue("pengurusan_tangki_septik", e ? e.target.value : null)
              }}
              value={values ? values.pengurusan_tangki_septik : null}>
              <Radio value={"1"}>Ya</Radio>
              <Radio value={"0"}>Tidak</Radio>
            </Radio.Group>
          </Col>
          <Col xs={24} style={{ textAlign: "right" }}>
            <Divider />
            <Button type="primary" icon={<ArrowRightOutlined />} onClick={this.setNextActiveKey}>Lanjutkan</Button>
          </Col>
        </Row>
    }, {
      "title": "Stratifikasi UKS",
      "content": loading.sdguks ? <PreloadContent /> : <Row gutter={[24, 24]} >
        <Col xs={24} md={15}>
          Madrasah memiliki selokan untuk menghindari genangan air?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("selokan", e ? e.target.value : null)
            }}
            value={values ? values.selokan : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Madrasah menyediakan tempat sampah di setiap ruang kelas?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("tempat_sampah_kelas", e ? e.target.value : null)
            }}
            value={values ? values.tempat_sampah_kelas : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Madrasah menyediakan tempat sampah tertutup di setiap unit jamban perempuan?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("tempat_sampah_jamban", e ? e.target.value : null)
            }}
            value={values ? values.tempat_sampah_jamban : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Madrasah menyediakan cermin disetiap unit jamban perempuan?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("cermin_jamban", e ? e.target.value : null)
            }}
            value={values ? values.cermin_jamban : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Madrasah memiliki tempat pembuangan sampah sementara (TPS) yang tertutup?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("tps_tertutup", e ? e.target.value : null)
            }}
            value={values ? values.tps_tertutup : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Sampah dari tempat pembuangan sampah sementara diangkut secara rutin?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("sampah_diangkut_rutin", e ? e.target.value : null)
            }}
            value={values ? values.sampah_diangkut_rutin : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Ada perencanaan & penganggaran untuk kegiatan pemeliharaan dan perawatan sanitasi madrasah?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("rencana_anggaran_sanitasi", e ? e.target.value : null)
            }}
            value={values ? values.rencana_anggaran_sanitasi : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Ada kegiatan rutin yang melibatkan siswa untuk memelihara dan merawat fasilitas sanitasi di Madrasah?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("kegiatan_rutin_sanitasi", e ? e.target.value : null)
            }}
            value={values ? values.kegiatan_rutin_sanitasi : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Ada kemitraan dengan pihak luar untuk sanitasi madrasah?
        </Col>
        <Col xs={24} md={9}>
          <Autocomplete
            options={kemitraanPihakLuarOptions}
            value={values ? values.kemitraan_sanitasi : null}
            onChange={(e, newValue) => {
              this.handleChangeSetValue("kemitraan_sanitasi", newValue ? newValue.label : null)
            }}

            renderInput={(params) => <TextField {...params}
              fullWidth
              label="Pilih Status *"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="kemitraan_sanitasi"
              type="text"
              error={this.hasError('kemitraan_sanitasi')}
              helperText={
                this.hasError('kemitraan_sanitasi') ? errors.kemitraan_sanitasi[0] : null
              }
            />}
          />
        </Col>
        <Col xs={24} md={15}>
          Apakah jamban yang ada, dipisahkan antara Laki dan Perempuan?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("jamban_laki_perempuan", e ? e.target.value : null)
            }}
            value={values ? values.jamban_laki_perempuan : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} md={15}>
          Apakah sabun & air mengalir pada tempat cuci tangan?
        </Col>
        <Col xs={24} md={9}>
          <Radio.Group
            onChange={(e) => {
              this.handleChangeSetValue("sabun_dan_air_mengalir_uks", e ? e.target.value : null)
            }}
            value={values ? values.sabun_dan_air_mengalir_uks : null}>
            <Radio value={"1"}>Ya</Radio>
            <Radio value={"0"}>Tidak</Radio>
          </Radio.Group>
        </Col>

        <Col xs={24} md={15}>
          Jumlah jamban baik
        </Col>
        <Col xs={24} md={3}>
          <TextField
            fullWidth
            label="Laki-laki *"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="jml_jamban_baik_pria"
            type="number"
            onChange={(e) => {
              this.handleChangeSetValue("jml_jamban_baik_pria", e.target.value)
            }}
            value={values ? values.jml_jamban_baik_pria : null}
            error={this.hasError('jml_jamban_baik_pria')}
            helperText={
              this.hasError('jml_jamban_baik_pria') ? errors.jml_jamban_baik_pria[0] : null
            }
          />
        </Col>
        <Col xs={24} md={3}>
          <TextField
            fullWidth
            label="Perempuan *"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="jml_jamban_baik_perempuan"
            type="number"
            onChange={(e) => {
              this.handleChangeSetValue("jml_jamban_baik_perempuan", e.target.value)
            }}
            value={values ? values.jml_jamban_baik_perempuan : null}
            error={this.hasError('jml_jamban_baik_perempuan')}
            helperText={
              this.hasError('jml_jamban_baik_perempuan') ? errors.jml_jamban_baik_perempuan[0] : null
            }
          />
        </Col>
        <Col xs={24} md={3}>
          <TextField
            fullWidth
            label="Bersama *"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="jml_jamban_baik_bersama"
            type="number"
            onChange={(e) => {
              this.handleChangeSetValue("jml_jamban_baik_bersama", e.target.value)
            }}
            value={values ? values.jml_jamban_baik_bersama : null}
            error={this.hasError('jml_jamban_baik_bersama')}
            helperText={
              this.hasError('jml_jamban_baik_bersama') ? errors.jml_jamban_baik_bersama[0] : null
            }
          />
        </Col>
        <Col xs={24} md={15}>
          Jumlah jamban tidak dapat digunakan
        </Col>
        <Col xs={24} md={3}>
          <TextField
            fullWidth
            label="Laki-laki *"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="jml_jamban_tidak_digunakan_pria"
            type="number"
            onChange={(e) => {
              this.handleChangeSetValue("jml_jamban_tidak_digunakan_pria", e.target.value)
            }}
            value={values ? values.jml_jamban_tidak_digunakan_pria : null}
            error={this.hasError('jml_jamban_tidak_digunakan_pria')}
            helperText={
              this.hasError('jml_jamban_tidak_digunakan_pria') ? errors.jml_jamban_tidak_digunakan_pria[0] : null
            }
          />
        </Col>
        <Col xs={24} md={3}>
          <TextField
            fullWidth
            label="Perempuan *"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="jml_jamban_tidak_digunakan_perempuan"
            type="number"
            onChange={(e) => {
              this.handleChangeSetValue("jml_jamban_tidak_digunakan_perempuan", e.target.value)
            }}
            value={values ? values.jml_jamban_tidak_digunakan_perempuan : null}
            error={this.hasError('jml_jamban_tidak_digunakan_perempuan')}
            helperText={
              this.hasError('jml_jamban_tidak_digunakan_perempuan') ? errors.jml_jamban_tidak_digunakan_perempuan[0] : null
            }
          />
        </Col>
        <Col xs={24} md={3}>
          <TextField
            fullWidth
            label="Bersama *"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="jml_jamban_tidak_digunakan_bersama"
            type="number"
            onChange={(e) => {
              this.handleChangeSetValue("jml_jamban_tidak_digunakan_bersama", e.target.value)
            }}
            value={values ? values.jml_jamban_tidak_digunakan_bersama : null}
            error={this.hasError('jml_jamban_tidak_digunakan_bersama')}
            helperText={
              this.hasError('jml_jamban_tidak_digunakan_bersama') ? errors.jml_jamban_tidak_digunakan_bersama[0] : null
            }
          />
        </Col>
        <Col xs={24} style={{ textAlign: "right" }}>
          <Divider />
          <Button icon={<ArrowLeftOutlined />} onClick={this.setBackActiveKey}>Kembali</Button> &nbsp;
          <Button type="primary" icon={<ArrowRightOutlined />} onClick={this.setNextActiveKey}>Lanjutkan</Button>
        </Col>
      </Row>
    }, {
      "title": "Kegiatan dan Media",
      "content": <>
        <Row gutter={[24, 24]} >
          <Col xs={24}>
            Sekolah memiliki kegiatan dan media Komunikasi, Informasi dan Edukasi (KIE) tentang sanitasi sekolah.
          </Col>
          <Col xs={24} md={9}>

          </Col>
          <Col xs={24} md={3}>
            KIE di R.Kelas
          </Col>
          <Col xs={24} md={3}>
            KIE di R.Toilet
          </Col>
          <Col xs={24} md={3}>
            KIE di R.Selasar
          </Col>
          <Col xs={24} md={3}>
            KIE di R.UKS
          </Col>
          <Col xs={24} md={3}>
            KIE di R.Kantin
          </Col>
        </Row>
        <Divider />

        {loading.kegiatanMedia ? "Loading..." : valuesKegiatan.map((row, i) =>
          <Row gutter={[24, 24]} key={i}>
            <Col xs={24} md={9}>
              {row.title}
            </Col>
            <Col xs={24} md={3}>
              <Radio.Group
                name="kelas"
                onChange={(e) => {
                  this.handleChangeSetValueArray(i, 'kelas', e ? e.target.value : null)
                }}
                value={valuesKegiatan[i] ? valuesKegiatan[i].kelas : null}>
                <Radio value={"1"}>Ya</Radio>
                <Radio value={"0"}>Tidak</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} md={3}>
              <Radio.Group
                name="toilet"
                onChange={(e) => {
                  this.handleChangeSetValueArray(i, "toilet", e ? e.target.value : null)
                }}
                value={valuesKegiatan[i] ? valuesKegiatan[i].toilet : null}>
                <Radio value={"1"}>Ya</Radio>
                <Radio value={"0"}>Tidak</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} md={3}>
              <Radio.Group
                onChange={(e) => {
                  this.handleChangeSetValueArray(i, "selasar", e ? e.target.value : null)
                }}
                value={valuesKegiatan[i] ? valuesKegiatan[i].selasar : null}>
                <Radio value={"1"}>Ya</Radio>
                <Radio value={"0"}>Tidak</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} md={3}>
              <Radio.Group
                onChange={(e) => {
                  this.handleChangeSetValueArray(i, "uks", e ? e.target.value : null)
                }}
                value={valuesKegiatan[i] ? valuesKegiatan[i].uks : null}>
                <Radio value={"1"}>Ya</Radio>
                <Radio value={"0"}>Tidak</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} md={3}>
              <Radio.Group
                onChange={(e) => {
                  this.handleChangeSetValueArray(i, "kantin", e ? e.target.value : null)
                }}
                value={valuesKegiatan[i] ? valuesKegiatan[i].kantin : null}>
                <Radio value={"1"}>Ya</Radio>
                <Radio value={"0"}>Tidak</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24}>
              <Divider />
            </Col>
          </Row>
        )}

        <Row gutter={[24, 24]} >
          <Col xs={24} style={{ textAlign: "right" }}>
            <Button icon={<ArrowLeftOutlined />} onClick={this.setBackActiveKey}>Kembali</Button> &nbsp;
            <Button type="primary" icon={<ArrowRightOutlined />} onClick={this.onSubmit} loading={loading.submit}>Simpan</Button>
          </Col>
        </Row>
      </>
    }]

    const access = this.props.privilege.access["/sarana-prasarana/sanitasi-air"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
            <Breadcrumb.Item>Sanitasi Air </Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.create ? <Button type='primary' onClick={this.modalForm.bind(this, true)}><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <Tabs defaultActiveKey={0} activeKey={this.state.tabIndex} onChange={this.handleChangeTab}>

            {tabs.map((val, i) =>
              <Tabs.TabPane tab={val.title} key={i}>
                {val.content}
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(SanitasiAir);
