import React from 'react';
import { Select } from 'antd';

const SelectStatusNotif = ({ ...props }) => {
  return (
    <Select
      placeholder="Pilih status"
      style={{
        width: '100%',
      }}
      options={[
        {
          value: 'DONE',
          label: 'Done',
        }, {
          value: 'FAILED',
          label: 'Failed',
        }
      ]}
      {...props}
    />
  );
};
export default SelectStatusNotif;
