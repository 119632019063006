import React from 'react';
import { connect } from 'react-redux'
import { Alert, Breadcrumb, Button, Divider } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';

import { _setAxios, _success } from '../../../lib/Helper';

class Laporan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("sarpras/laporan", "POST", {}).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
                        <Breadcrumb.Item>Laporan</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Laporan"
                    />
                    <Alert
                        message="Informational Notes"
                        description="Laporan yang akan diunduh adalah hasil rekapitulasi dari kondisi Sarana dan Prasarana yang ada di dalam sistem."
                        type="info"
                        showIcon
                    />

                    <Divider/>

                    <center>
                        <Button type="primary" shape="round" icon={<DownloadOutlined />} size={"large"} loading={this.state.loading} onClick={this.handleSubmit}>
                            Unduh Laporan
                        </Button>
                    </center>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Laporan);
