import React from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const LabelIsUsed = ({ ...props }) => {

  if(props.isUsed) {
    return (
      <Tag color="green" icon={<CheckCircleOutlined />}>Aktif</Tag>
    );
  }
  
  return (
    <Tag color="red" icon={<CloseCircleOutlined />}>Non-Aktif</Tag>
  );
};
export default LabelIsUsed;
