import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button, Typography } from 'antd';
import { DeleteOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import ModalForm from '../modal/Form';
import Filter from '../modal/Filter';
import ModalDeleteFile from '../modal/DeleteFile';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableWithFilter } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';
import ModalExport from '../modal/Export';
import ModalDetail from '../modal/Detail';
import { currentDate } from 'constant/constant_format';

const { Text } = Typography;
const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const KehadiranTenagaPendidik = ({ privilege, action }) => {
  const {
    table,
    form,
    filter,
    deleteFile,
    exportState,
    detailState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalForm,
    modalDeleteFile,
    modalExport,
    modalDetail,
  } = useList(privilege?.tahun_ajaran, privilege?.semester);

  const presensiSetting = privilege.presensi?.setting;

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Tenaga Pendidik"
        subTitle="Data kehadiran gtk"
        extra={[
          action.create && (
            <Button
              type='primary'
              onClick={() => modalForm(true)}
              icon={<PlusOutlined />}>
              Tambah
            </Button>
          ),
          action.export && (
            <Button
              onClick={() => modalExport(true)}
              icon={<DownloadOutlined />}>
              Export
            </Button>
          ),
          action.delete && (
            <Button
              danger
              onClick={() => modalDeleteFile(true)}
              icon={<DeleteOutlined />}
            > Hapus Foto Kehadiran
            </Button>)
        ]} />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={18} md={19}>
          <SearchTable table={table} fetch={fetchData} />
        </Col>
        <Col xs={24} sm={6} md={5}>
          <SortingTableWithFilter
            table={table}
            fetch={fetchData}
            modalFilter={modalFilter}
            sortFields={sortFields}
          />
        </Col>
        <Col xs={24}>
          <Text italic>Default data yang tampil adalah hari ini, </Text><Text code>{currentDate}</Text>
        </Col>
        <Col xs={24}>
          <TableList
            state={{ table, filter }}
            fetch={fetchData}
            fetchTable={fetchTable}
            columns={columns(action, modalForm, modalDetail)}
          />
        </Col>
      </Row>
      {form.visible && (
        <ModalForm
          presensiSetting={presensiSetting}
          data={form.data}
          visible={form.visible}
          hideModal={() => modalForm(false)}
          fetchTable={fetchTable}
        />
      )}
      {filter.visible && (
        <Filter
          visible={filter.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filter.values}
        />
      )}
      {deleteFile.visible && (
        <ModalDeleteFile
          visible={deleteFile.visible}
          hideModal={() => modalDeleteFile(false)}
          fetchTable={fetchTable}
        />
      )}
      {exportState.visible && (
        <ModalExport
          privilege={privilege}
          visible={exportState.visible}
          hideModal={() => modalExport(false)}
        />
      )}
      {detailState.visible && (
        <ModalDetail
          data={detailState.data}
          visible={detailState.visible}
          hideModal={() => modalDetail(false)}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(KehadiranTenagaPendidik);