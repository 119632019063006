import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectPresenceType } from 'components/select';
import { SwitchBasic } from 'components/switch';
const { TextArea } = Input;

const ModalForm = ({ data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(data, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Jenis Perizinan"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={16}>
                        <Form.Item
                            label="Nama Perizinan"
                            name="nama"
                            rules={[{ required: true, message: 'Nama harus diisi!' }]}>
                            <Input placeholder='Ex: Jhon' />
                        </Form.Item>
                    </Col>
                    <Col xs={8}>
                        <Form.Item
                            label="Inisial"
                            name="inisial"
                            rules={[{ required: true, message: 'Inisial harus diisi!' }]}>
                            <Input placeholder='Ex: JH' />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label="Tipe"
                            name="tipe"
                            rules={[{ required: true, message: 'Semester harus dipilih!' }]}>
                            <SelectPresenceType allowClear />
                        </Form.Item>
                        <Form.Item
                            name="is_approve" >
                            <SwitchBasic
                                defaultChecked={values.is_approve === "1" ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("is_approve", val ? "1" : "0");
                                }}
                            />
                            &nbsp;Aktifkan jika perizinan perlu disetujui
                        </Form.Item>
                        <Form.Item
                            label="Keterangan"
                            name="keterangan">
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;