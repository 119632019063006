import React from 'react';
import { connect } from 'react-redux'
import { _success, _validationFormMsg, _setAxios, _getDate } from '../../../lib/Helper';
import { statusTempatTinggalOrtuOptions, kewarganegaraanOptions, statusKehidupanOptions, pendidikanOptions, pekerjaanOptions, penghasilanOptions, domisiliOptions } from '../../../data/options';
import { schemaAyah, schemaIbu, schemaWali } from '../schema';
import validate from 'validate.js';
import { Button, Row, Col, Checkbox, Divider, Radio, Skeleton, List, Tooltip, Alert } from 'antd';
import { SaveOutlined, EditOutlined } from '@ant-design/icons';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

class OrangTua extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dataOrangTua: {
        ayah: [],
        ibu: [],
        wali: []
      },
      loading: true,
      isUpdate: false,
      loadingBtn: false,
      values: {},
      errors: {},
      isValid: false,

      ddl: {
        regionsAyah: [],
        regionsIbu: [],
        regionsWali: [],
        villagesAyah: [],
        villagesIbu: [],
        villagesWali: [],
      },

      isAlamatIbuSamaDenganAyah: false,
      isAlamatWaliSamaDenganAyah: false,
      isAlamatWaliSamaDenganIbu: false,

      dataID: this.props.dataID ? this.props.dataID : this.props.match.params.id

    };
  }

  componentDidMount() {
    this.getOrangTua()
  }

  getOrangTua = () => {
    this.setState({
      loading: true
    })

    _setAxios("siswa/data-orang-tua/" + this.state.dataID, "GET").then(resp => {
      if (resp.data.status === true) {
        if (resp.data.data.length > 0) {
          let ayah = []
          let ibu = []
          let wali = []
          let values = {}

          resp.data.data.forEach(row => {
            let data = [
              {
                title: "Statu Hidup",
                description: row.status_hidup ? row.status_hidup : "-",
              },
              {
                title: "Nama",
                description: row.nama ? row.nama : "-",
              },
              {
                title: "NIK",
                description: row.nik ? row.nik : "-",
              },
              {
                title: "Kewarganegaraan",
                description: row.kewarganegaraan ? row.kewarganegaraan : "-",
              },
              {
                title: "Tempat Lahir",
                description: row.tempat_lahir ? row.tempat_lahir : "-",
              },
              {
                title: "Tanggal Lahir",
                description: row.tgl_lahir ? row.tgl_lahir : "-",
              },
              {
                title: "Pendidikan",
                description: row.pendidikan ? row.pendidikan : "-",
              },
              {
                title: "Pekerjaan",
                description: row.pekerjaan ? row.pekerjaan : "-",
              },
              {
                title: "Penghasilan",
                description: row.penghasilan ? row.penghasilan : "-",
              },
              {
                title: "No.Telepon",
                description: row.no_telp ? row.no_telp : "-",
              },
              {
                title: "Domisili",
                description: row.domisili ? row.domisili : "-",
              },
              {
                title: "Status Tempat Tinggal",
                description: row.status_tempat_tinggal ? row.status_tempat_tinggal : "-",
              },
              {
                title: "Alamat",
                description: (row.alamat ? row.alamat : null) + (row.provinsi_nama ? ", " + row.provinsi_nama : null) + (row.kabkot_nama ? ", " + row.kabkot_nama : null) + (row.kecamatan_nama ? ", " + row.kecamatan_nama : null) + (row.kelurahan_nama ? ", " + row.kelurahan_nama : null) + (row.kode_pos ? " (" + row.kode_pos + ")" : null),

              },
              {
                title: row.email ? "Email" : "",
                description: row.email ? row.email : "",

              },
            ]
            row.status_hidup = "Masih Hidup"

            if (row.tipe === "Ayah") {
              ayah = data
              values = {
                ...values,
                ayah_id: row.id ? row.id : null,
                ayah_status: row.status_hidup ? row.status_hidup : null,
                ayah_nama: row.nama ? row.nama : null,
                ayah_nik: row.nik ? row.nik : null,
                ayah_kewarganegaraan: row.kewarganegaraan ? {
                  value: row.kewarganegaraan,
                  label: row.kewarganegaraan,
                } : null,
                ayah_tempat_lahir: row.tempat_lahir ? row.tempat_lahir : null,
                ayah_tgl_lahir: row.tgl_lahir ? row.tgl_lahir : null,
                ayah_pendidikan: row.pendidikan ? {
                  value: row.pendidikan,
                  label: row.pendidikan,
                } : null,
                ayah_pekerjaan: row.pekerjaan ? {
                  value: row.pekerjaan,
                  label: row.pekerjaan,
                } : null,
                ayah_penghasilan: row.penghasilan ? {
                  value: row.penghasilan,
                  label: row.penghasilan,
                } : null,
                ayah_no_telp: row.no_telp ? row.no_telp : null,
                ayah_domisili: row.domisili ? row.domisili : null,
                ayah_status_tempat_tinggal: row.status_tempat_tinggal ? {
                  value: row.status_tempat_tinggal,
                  label: row.status_tempat_tinggal,
                } : null,
                ayah_region: row.provinsi_id ? {
                  provinsi_nama: row.provinsi_nama,
                  kabkot_nama: row.kabkot_nama,
                  kecamatan_nama: row.kecamatan_nama,
                  provinsi_id: row.provinsi_id,
                  kabkot_id: row.kabkot_id,
                  kecamatan_id: row.kecamatan_id,
                } : null,
                ayah_village: row.kelurahan_id ? {
                  id: row.kelurahan_id,
                  nama: row.kelurahan_nama
                } : null,
                ayah_kode_pos: row.kode_pos ? row.kode_pos : null,
                ayah_alamat: row.alamat ? row.alamat : null,
              }
            } else if (row.tipe === "Ibu") {
              ibu = data
              values = {
                ...values,
                ibu_id: row.id ? row.id : null,
                ibu_status: row.status_hidup ? row.status_hidup : null,
                ibu_nama: row.nama ? row.nama : null,
                ibu_nik: row.nik ? row.nik : null,
                ibu_kewarganegaraan: row.kewarganegaraan ? {
                  value: row.kewarganegaraan,
                  label: row.kewarganegaraan,
                } : null,
                ibu_tempat_lahir: row.tempat_lahir ? row.tempat_lahir : null,
                ibu_tgl_lahir: row.tgl_lahir ? row.tgl_lahir : null,
                ibu_pendidikan: row.pendidikan ? {
                  value: row.pendidikan,
                  label: row.pendidikan,
                } : null,
                ibu_pekerjaan: row.pekerjaan ? {
                  value: row.pekerjaan,
                  label: row.pekerjaan,
                } : null,
                ibu_penghasilan: row.penghasilan ? {
                  value: row.penghasilan,
                  label: row.penghasilan,
                } : null,
                ibu_no_telp: row.no_telp ? row.no_telp : null,
                ibu_domisili: row.domisili ? row.domisili : null,
                ibu_status_tempat_tinggal: row.status_tempat_tinggal ? {
                  value: row.status_tempat_tinggal,
                  label: row.status_tempat_tinggal,
                } : null,
                ibu_region: row.provinsi_id ? {
                  provinsi_nama: row.provinsi_nama,
                  kabkot_nama: row.kabkot_nama,
                  kecamatan_nama: row.kecamatan_nama,
                  provinsi_id: row.provinsi_id,
                  kabkot_id: row.kabkot_id,
                  kecamatan_id: row.kecamatan_id,
                } : null,
                ibu_village: row.kelurahan_id ? {
                  id: row.kelurahan_id,
                  nama: row.kelurahan_nama
                } : null,
                ibu_kode_pos: row.kode_pos ? row.kode_pos : null,
                ibu_alamat: row.alamat ? row.alamat : null,
              }
            } else if (row.tipe === "Wali") {
              wali = data
              values = {
                ...values,
                wali_id: row.id ? row.id : null,
                wali_status: row.status_hidup ? row.status_hidup : null,
                wali_nama: row.nama ? row.nama : null,
                wali_nik: row.nik ? row.nik : null,
                wali_kewarganegaraan: row.kewarganegaraan ? {
                  value: row.kewarganegaraan,
                  label: row.kewarganegaraan,
                } : null,
                wali_tempat_lahir: row.tempat_lahir ? row.tempat_lahir : null,
                wali_tgl_lahir: row.tgl_lahir ? row.tgl_lahir : null,
                wali_pendidikan: row.pendidikan ? {
                  value: row.pendidikan,
                  label: row.pendidikan,
                } : null,
                wali_pekerjaan: row.pekerjaan ? {
                  value: row.pekerjaan,
                  label: row.pekerjaan,
                } : null,
                wali_penghasilan: row.penghasilan ? {
                  value: row.penghasilan,
                  label: row.penghasilan,
                } : null,
                wali_no_telp: row.no_telp ? row.no_telp : null,
                wali_domisili: row.domisili ? row.domisili : null,
                wali_status_tempat_tinggal: row.status_tempat_tinggal ? {
                  value: row.status_tempat_tinggal,
                  label: row.status_tempat_tinggal,
                } : null,
                wali_region: row.provinsi_id ? {
                  provinsi_nama: row.provinsi_nama,
                  kabkot_nama: row.kabkot_nama,
                  kecamatan_nama: row.kecamatan_nama,
                  provinsi_id: row.provinsi_id,
                  kabkot_id: row.kabkot_id,
                  kecamatan_id: row.kecamatan_id,
                } : null,
                wali_village: row.kelurahan_id ? {
                  id: row.kelurahan_id,
                  nama: row.kelurahan_nama
                } : null,
                wali_kode_pos: row.kode_pos ? row.kode_pos : null,
                wali_alamat: row.alamat ? row.alamat : null,
                wali_email: row.email ? row.email : null,
              }
            }
          });

          this.setState({
            isUpdate: false,
            loading: false,
            dataOrangTua: {
              ayah: ayah,
              ibu: ibu,
              wali: wali,
            },
            values: values

          })
        }
      } else {
        this.setState({
          isUpdate: false,
          loading: false,
        })
      }
    })
  }

  // DDL START ------------------------------------------------------------------------------------------------------------------------

  getRegionDDL = (name, search = "") => {
    _setAxios("region", "POST", {
      "pagination": {
        "current": 1,
        "pageSize": 10
      },
      "search": search
    }).then(resp => {
      if (resp.status === true) {
        switch (name) {
          case "ayah_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                regionsAyah: resp.data.list
              }
            });
            break;
          case "ibu_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                regionsIbu: resp.data.list
              }
            });
            break;
          case "wali_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                regionsWali: resp.data.list
              }
            });
            break;

          default:
            break;
        }
      }
    })
  }

  getVillageDDL = (name, districtID) => {
    _setAxios("region/" + districtID, "POST", {
      pagination: {
        current: 1,
        pageSize: 30
      },
    }).then(resp => {
      if (resp.status === true) {
        switch (name) {
          case "ayah_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                villagesAyah: resp.data.data
              }
            });
            break;
          case "ibu_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                villagesIbu: resp.data.data
              }
            });
            break;
          case "wali_region":
            this.setState({
              ddl: {
                ...this.state.ddl,
                villagesWali: resp.data.data
              }
            });
            break;

          default:
            break;
        }
      }
    })
  }
  // DDL END ---------------------------------------------------------------------------------------------------------------------------

  // HANDLE FORM START ---------------------------------------------------------------------------------------------------------------------------
  handleChange = (e) => {
    const { values } = this.state;
    const { name, value } = e.target;

    if (name === "ayah_domisili" && value === "Luar Negeri") {
      values.ayah_region = null
      values.ayah_village = null
      values.ayah_kode_pos = null
    } else if (name === "ibu_domisili" && value === "Luar Negeri") {
      values.ibu_region = null
      values.ibu_village = null
      values.ibu_kode_pos = null
    } else if (name === "wali_domisili" && value === "Luar Negeri") {
      values.wali_region = null
      values.wali_village = null
      values.wali_kode_pos = null
    }

    this.setForm(values, name, value)
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;

    if (name === "region" || name === "ayah_region" || name === "ibu_region" || name === "wali_region") {
      switch (name) {
        case "region":
          values["village"] = null;
          break;
        case "ayah_region":
          values["ayah_village"] = null;
          break;
        case "ibu_region":
          values["ibu_village"] = null;
          break;
        case "wali_region":
          values["wali_village"] = null;
          break;

        default:
          break;
      }
      if (value) {
        this.getVillageDDL(name, value.kecamatan_id)
      }
    }

    this.setForm(values, name, value)
  };

  setForm = (values, name, value, row = {}) => {
    values[name] = value;

    this.setValidate(values)
  }

  setValidate = (values) => {
    const schema = this.getSchemaParent(values)
    const errors = validate(values, schema);
    this.setState({
      values: values,
      errors: errors || {},
    });

    return errors
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }
  // HANDLE FORM END ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  getSchemaParent = (values) => {
    // const { isAlamatIbuSamaDenganAyah, isAlamatWaliSamaDenganAyah, isAlamatWaliSamaDenganIbu } = this.state;
    const { isAlamatIbuSamaDenganAyah } = this.state;

    let schema = {}

    if (values.ayah_nama || values.ayah_nik || values.ayah_status || values.ayah_domisili || values.ayah_kewarganegaraan || values.ayah_tempat_lahir || values.ayah_tgl_lahir || values.ayah_pekerjaan || values.ayah_pendidikan || values.ayah_penghasilan || values.ayah_no_telp || values.ayah_region || values.ayah_village || values.ayah_kode_pos || values.ayah_alamat) {
      schema = { ...schema, ...schemaAyah }
    }
    if (isAlamatIbuSamaDenganAyah || values.ibu_nama || values.ibu_nik || values.ibu_status || values.ibu_domisili || values.ibu_kewarganegaraan || values.ibu_tempat_lahir || values.ibu_tgl_lahir || values.ibu_pekerjaan || values.ibu_pendidikan || values.ibu_penghasilan || values.ibu_no_telp || values.ibu_region || values.ibu_village || values.ibu_kode_pos || values.ibu_alamat) {
      schema = { ...schema, ...schemaIbu }
    }
    // if (isAlamatWaliSamaDenganAyah || isAlamatWaliSamaDenganIbu || values.wali_nama || values.wali_nik || values.wali_status || values.wali_domisili || values.wali_kewarganegaraan || values.wali_tempat_lahir || values.wali_tgl_lahir || values.wali_pekerjaan || values.wali_pendidikan || values.wali_penghasilan || values.wali_no_telp || values.wali_region || values.wali_village || values.wali_kode_pos || values.wali_alamat) {
    schema = { ...schema, ...schemaWali }
    // }

    return schema
  }

  handleChangeAlamatIbuSamaDenganAyah = (e) => {
    this.setState({
      isAlamatIbuSamaDenganAyah: !this.state.isAlamatIbuSamaDenganAyah
    })
  };

  handleChangeAlamatWaliSamaDenganAyah = (e) => {
    this.setState({
      isAlamatWaliSamaDenganAyah: !this.state.isAlamatWaliSamaDenganAyah,
      isAlamatWaliSamaDenganIbu: false
    })
  };

  handleChangeAlamatWaliSamaDenganIbu = (e) => {
    this.setState({
      isAlamatWaliSamaDenganIbu: !this.state.isAlamatWaliSamaDenganIbu,
      isAlamatWaliSamaDenganAyah: false
    })
  };

  onSubmitWali = () => {
    const { values, isAlamatWaliSamaDenganAyah, isAlamatWaliSamaDenganIbu } = this.state;
    const params = {
      status_hidup: values.wali_status ? values.wali_status : null,
      nama: values.wali_nama ? values.wali_nama : null,
      nik: values.wali_nik ? values.wali_nik : null,
      tempat_lahir: values.wali_tempat_lahir ? values.wali_tempat_lahir : null,
      tgl_lahir: _getDate(values.wali_tgl_lahir),
      kewarganegaraan: values.wali_kewarganegaraan ? values.wali_kewarganegaraan.value : null,
      pendidikan: values.wali_pendidikan ? values.wali_pendidikan.value : null,
      pekerjaan: values.wali_pekerjaan ? values.wali_pekerjaan.value : null,
      penghasilan: values.wali_penghasilan ? values.wali_penghasilan.value : null,
      no_telp: values.wali_no_telp ? values.wali_no_telp : null,
      email: values.wali_email ? values.wali_email : null,

      provinsi_id: isAlamatWaliSamaDenganAyah ? (values.ayah_region ? values.ayah_region.provinsi_id : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_region ? values.ibu_region.provinsi_id : null) : (values.wali_region ? values.wali_region.provinsi_id : null)),
      kabkot_id: isAlamatWaliSamaDenganAyah ? (values.ayah_region ? values.ayah_region.kabkot_id : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_region ? values.ibu_region.kabkot_id : null) : (values.wali_region ? values.wali_region.kabkot_id : null)),
      kecamatan_id: isAlamatWaliSamaDenganAyah ? (values.ayah_region ? values.ayah_region.kecamatan_id : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_region ? values.ibu_region.kecamatan_id : null) : (values.wali_region ? values.wali_region.kecamatan_id : null)),
      kelurahan_id: isAlamatWaliSamaDenganAyah ? (values.ayah_village ? values.ayah_village.id : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_village ? values.ibu_village.id : null) : (values.wali_village ? values.wali_village.id : null)),
      domisili: isAlamatWaliSamaDenganAyah ? (values.ayah_domisili ? values.ayah_domisili : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_domisili ? values.ibu_domisili : null) : (values.wali_domisili ? values.wali_domisili : null)),
      status_tempat_tinggal: isAlamatWaliSamaDenganAyah ? (values.ayah_status_tempat_tinggal ? values.ayah_status_tempat_tinggal.value : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_status_tempat_tinggal ? values.ibu_status_tempat_tinggal.value : null) : (values.wali_status_tempat_tinggal ? values.wali_status_tempat_tinggal.value : null)),
      alamat: isAlamatWaliSamaDenganAyah ? (values.ayah_alamat ? values.ayah_alamat : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_alamat ? values.ibu_alamat : null) : (values.wali_alamat ? values.wali_alamat : null)),
      kode_pos: isAlamatWaliSamaDenganAyah ? (values.ayah_kode_pos ? values.ayah_kode_pos : null) : (isAlamatWaliSamaDenganIbu ? (values.ibu_kode_pos ? values.ibu_kode_pos : null) : (values.wali_kode_pos ? values.wali_kode_pos : null)),
      tipe: "Wali",
    }

    if (values.wali_id) {
      _setAxios("siswa/data-orang-tua/" + values.wali_id, "PUT", params).then(resp => {
        if (resp.status === true) {
          _success('topRight', 'Success', 'Data Wali berhasil diupdate')
          this.setState({
            isUpdate: false,
            loadingBtn: false,
          });
          this.getOrangTua()
        } else {
          this.setState({
            loadingBtn: false
          });
        }
      })
    } else {
      if (params.nama) {
        _setAxios("siswa/data-orang-tua/" + this.state.dataID, "POST", params).then(resp => {
          if (resp.status === true) {
            _success('topRight', 'Success', 'Data Wali berhasil diupdate')
            this.setState({
              isUpdate: false,
              loadingBtn: false
            });
            this.getOrangTua()
          } else {
            this.setState({
              loadingBtn: false
            });
          }
        })
      } else {
        this.setState({
          loadingBtn: false,
          isUpdate: false
        })
      }
    }
  }

  onSubmitIbu = () => {
    const { values, isAlamatIbuSamaDenganAyah } = this.state;
    const params = {
      status_hidup: values.ibu_status ? values.ibu_status : null,
      nama: values.ibu_nama ? values.ibu_nama : null,
      nik: values.ibu_nik ? values.ibu_nik : null,
      tempat_lahir: values.ibu_tempat_lahir ? values.ibu_tempat_lahir : null,
      tgl_lahir: _getDate(values.ibu_tgl_lahir),
      kewarganegaraan: values.ibu_kewarganegaraan ? values.ibu_kewarganegaraan.value : null,
      pendidikan: values.ibu_pendidikan ? values.ibu_pendidikan.value : null,
      pekerjaan: values.ibu_pekerjaan ? values.ibu_pekerjaan.value : null,
      penghasilan: values.ibu_penghasilan ? values.ibu_penghasilan.value : null,
      no_telp: values.ibu_no_telp ? values.ibu_no_telp : null,
      email: null,

      provinsi_id: isAlamatIbuSamaDenganAyah ? (values.ayah_region ? values.ayah_region.provinsi_id : null) : values.ibu_region ? values.ibu_region.provinsi_id : null,
      kabkot_id: isAlamatIbuSamaDenganAyah ? (values.ayah_region ? values.ayah_region.kabkot_id : null) : values.ibu_region ? values.ibu_region.kabkot_id : null,
      kecamatan_id: isAlamatIbuSamaDenganAyah ? (values.ayah_region ? values.ayah_region.kecamatan_id : null) : values.ibu_region ? values.ibu_region.kecamatan_id : null,
      kelurahan_id: isAlamatIbuSamaDenganAyah ? (values.ayah_village ? values.ayah_village.id : null) : values.ibu_village ? values.ibu_village.id : null,
      domisili: isAlamatIbuSamaDenganAyah ? (values.ayah_domisili ? values.ayah_domisili : null) : (values.ibu_domisili ? values.ibu_domisili : null),
      status_tempat_tinggal: isAlamatIbuSamaDenganAyah ? (values.ayah_status_tempat_tinggal ? values.ayah_status_tempat_tinggal.value : null) : (values.ibu_status_tempat_tinggal ? values.ibu_status_tempat_tinggal.value : null),
      alamat: isAlamatIbuSamaDenganAyah ? (values.ayah_alamat ? values.ayah_alamat : null) : (values.ibu_alamat ? values.ibu_alamat : null),
      kode_pos: isAlamatIbuSamaDenganAyah ? (values.ayah_kode_pos ? values.ayah_kode_pos : null) : (values.ibu_kode_pos ? values.ibu_kode_pos : null),
      tipe: "Ibu",
    }

    if (values.ibu_id) {
      _setAxios("siswa/data-orang-tua/" + values.ibu_id, "PUT", params).then(resp => {
        if (resp.status === true) {
          _success('topRight', 'Success', 'Data Ibu berhasil diupdate')
          this.onSubmitWali()
        } else {
          this.setState({
            loadingBtn: false
          });
        }
      })
    } else {
      if (params.nama) {
        _setAxios("siswa/data-orang-tua/" + this.state.dataID, "POST", params).then(resp => {
          if (resp.status === true) {
            _success('topRight', 'Success', 'Data Ibu berhasil disimpan')
            this.onSubmitWali()
          } else {
            this.setState({
              loadingBtn: false
            });
          }
        })
      }
      this.onSubmitWali()
    }
  }

  onSubmitAyah = () => {
    const { values } = this.state;

    //Validation Wali (WAJIB)
    const validationWali = this.setValidate(values, schemaWali)
    if (validationWali !== undefined) {
      return _validationFormMsg(validationWali)
    }

    //Validation Orang Tua
    const schema = this.getSchemaParent(values)
    const validation = this.setValidate(values, schema)
    if (validation !== undefined) {
      return _validationFormMsg(validation)
    }

    const params = {
      status_hidup: values.ayah_status ? values.ayah_status : null,
      provinsi_id: values.ayah_region ? values.ayah_region.provinsi_id : null,
      kabkot_id: values.ayah_region ? values.ayah_region.kabkot_id : null,
      kecamatan_id: values.ayah_region ? values.ayah_region.kecamatan_id : null,
      kelurahan_id: values.ayah_village ? values.ayah_village.id : null,
      nama: values.ayah_nama ? values.ayah_nama : null,
      nik: values.ayah_nik ? values.ayah_nik : null,
      tempat_lahir: values.ayah_tempat_lahir ? values.ayah_tempat_lahir : null,
      tgl_lahir: _getDate(values.ayah_tgl_lahir),
      kewarganegaraan: values.ayah_kewarganegaraan ? values.ayah_kewarganegaraan.value : null,
      pendidikan: values.ayah_pendidikan ? values.ayah_pendidikan.value : null,
      pekerjaan: values.ayah_pekerjaan ? values.ayah_pekerjaan.value : null,
      penghasilan: values.ayah_penghasilan ? values.ayah_penghasilan.value : null,
      domisili: values.ayah_domisili ? values.ayah_domisili : null,
      status_tempat_tinggal: values.ayah_status_tempat_tinggal ? values.ayah_status_tempat_tinggal.value : null,
      no_telp: values.ayah_no_telp ? values.ayah_no_telp : null,
      email: null,
      alamat: values.ayah_alamat ? values.ayah_alamat : null,
      kode_pos: values.ayah_kode_pos ? values.ayah_kode_pos : null,
      tipe: "Ayah",
    }

    this.setState({
      loadingBtn: true,
    })

    if (values.ayah_id) {
      _setAxios("siswa/data-orang-tua/" + values.ayah_id, "PUT", params).then(resp => {
        if (resp.status === true) {
          _success('topRight', 'Success', 'Data Ayah berhasil diupdate')
          this.onSubmitIbu()
        } else {
          this.setState({
            loadingBtn: false
          });
        }
      })
    } else {
      if (params.nama) {
        _setAxios("siswa/data-orang-tua/" + this.state.dataID, "POST", params).then(resp => {
          if (resp.status === true) {
            _success('topRight', 'Success', 'Data Ayah berhasil disimpan')
            this.onSubmitIbu()
          } else {
            this.setState({
              loadingBtn: false
            });
          }
        })
      } else {
        this.onSubmitIbu()
      }
    }
  }

  render() {
    const { action } = this.props
    const { loading, dataOrangTua, errors, values, ddl, isAlamatIbuSamaDenganAyah, isAlamatWaliSamaDenganAyah, isAlamatWaliSamaDenganIbu } = this.state;

    const compDetail = <>
      <Row gutter={[16, 16]}>
        {
          !loading && (action.update || action.update_partial) ?
            <Col xs={24} style={{ textAlign: "right" }}>
              <Tooltip placement="top" title={"Ubah data diri"}>
                <Button shape="circle" icon={<EditOutlined />} onClick={() => {
                  this.setState({
                    isUpdate: true
                  })
                }}></Button>
              </Tooltip>
            </Col>
            : ""
        }
        <Col xs={24} sm={8}>
          <Divider orientation="left"><h6>DATA AYAH KANDUNG</h6></Divider>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataOrangTua.ayah}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>
        <Col xs={24} sm={8}>
          <Divider orientation="left"><h6>DATA IBU KANDUNG</h6></Divider>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataOrangTua.ibu}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>
        <Col xs={24} sm={8}>
          <Divider orientation="left"><h6>DATA WALI</h6></Divider>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataOrangTua.wali}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>
      </Row>
    </>

    const form = <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card className='form-box'>
          <CardContent>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={24}>
                    <Alert message="Data Ayah kandung" type="info" showIcon />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Status</FormLabel>
                        <Radio.Group style={{ width: '100%' }}
                          name="ayah_status"
                          value={values.ayah_status || ""}
                          onChange={this.handleChange}
                        >
                          <Row gutter={[16, 16]}>
                            {statusKehidupanOptions.map((row, i) => (
                              <Col xs={12} sm={8} md={6} key={i}>
                                <Radio value={row.value}>{row.label}</Radio>
                              </Col>
                            ))}
                          </Row>
                        </Radio.Group>
                      </Col>

                      <Col xs={24}>
                        <TextField
                          fullWidth
                          label="Nama"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ayah_nama"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ayah_nama || ''}
                          error={this.hasError('ayah_nama')}
                          helperText={
                            this.hasError('ayah_nama') ? errors.ayah_nama[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24}>
                        <TextField
                          fullWidth
                          label="NIK"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ayah_nik"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ayah_nik || null}
                          error={this.hasError('ayah_nik')}
                          helperText={
                            this.hasError('ayah_nik') ? errors.ayah_nik[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <Autocomplete
                          options={kewarganegaraanOptions}
                          value={values.ayah_kewarganegaraan || null}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ayah_kewarganegaraan", newValue ? newValue : null)
                          }}

                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kewarganegaraan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ayah_kewarganegaraan"
                            type="text"
                            error={this.hasError('ayah_kewarganegaraan')}
                            helperText={
                              this.hasError('ayah_kewarganegaraan') ? errors.ayah_kewarganegaraan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <TextField
                          fullWidth
                          label="Tempat Lahir"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ayah_tempat_lahir"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ayah_tempat_lahir || null}
                          error={this.hasError('ayah_tempat_lahir')}
                          helperText={
                            this.hasError('ayah_tempat_lahir') ? errors.ayah_tempat_lahir[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              label="Tanggal Lahir"
                              inputFormat="dd-MM-yyyy"
                              value={values.ayah_tgl_lahir || null}
                              onChange={(newValue) => {
                                this.handleChangeSetValue("ayah_tgl_lahir", newValue)
                              }}
                              renderInput={(params) => <TextField
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={
                                  this.hasError('ayah_tgl_lahir') ? errors.ayah_tgl_lahir[0] : null
                                }
                                {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={pendidikanOptions}
                          value={values.ayah_status === "Masih Hidup" ? (values.ayah_pendidikan || null) : null}
                          readOnly={values.ayah_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ayah_pendidikan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pendidikan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ayah_pendidikan"
                            type="text"
                            error={this.hasError('ayah_pendidikan')}
                            helperText={
                              this.hasError('ayah_pendidikan') ? errors.ayah_pendidikan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={pekerjaanOptions}
                          value={values.ayah_status === "Masih Hidup" ? (values.ayah_pekerjaan || null) : null}
                          readOnly={values.ayah_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ayah_pekerjaan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pekerjaan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ayah_pekerjaan"
                            type="text"
                            error={this.hasError('ayah_pekerjaan')}
                            helperText={
                              this.hasError('ayah_pekerjaan') ? errors.ayah_pekerjaan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={penghasilanOptions}
                          value={values.ayah_status === "Masih Hidup" ? (values.ayah_penghasilan || null) : null}
                          readOnly={values.ayah_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ayah_penghasilan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Penghasilan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ayah_penghasilan"
                            type="text"
                            error={this.hasError('ayah_penghasilan')}
                            helperText={
                              this.hasError('ayah_penghasilan') ? errors.ayah_penghasilan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <TextField
                          fullWidth
                          label="No. Telepon"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ayah_no_telp"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ayah_no_telp || ''}
                          error={this.hasError('ayah_no_telp')}
                          helperText={
                            this.hasError('ayah_no_telp') ? errors.ayah_no_telp[0] : null
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Domisili</FormLabel>
                        <Radio.Group style={{ width: '100%' }}
                          name="ayah_domisili"
                          value={values.ayah_domisili || ""}
                          onChange={this.handleChange}
                        >
                          <Row gutter={[16, 16]}>
                            {domisiliOptions.map((row, i) => (
                              <Col xs={12} sm={8} md={6} key={i}>
                                <Radio value={row.value}>{row.label}</Radio>
                              </Col>
                            ))}
                          </Row>
                        </Radio.Group>
                      </Col>
                      <Col xs={24}>
                        <Autocomplete
                          options={statusTempatTinggalOrtuOptions}
                          value={values.ayah_status_tempat_tinggal || null}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ayah_status_tempat_tinggal", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Status Tempat Tinggal"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ayah_status_tempat_tinggal"
                            type="text"
                            error={this.hasError('ayah_status_tempat_tinggal')}
                            helperText={
                              this.hasError('ayah_status_tempat_tinggal') ? errors.ayah_status_tempat_tinggal[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={24}>
                        <Autocomplete
                          options={ddl.regionsAyah}
                          value={values.ayah_region || null}
                          readOnly={values.ayah_domisili === "Luar Negeri" ? true : false}
                          getOptionLabel={(option) =>
                            option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                          }
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ayah_region", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Provinsi, Kota & Kecamatan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ayah_region"
                            type="text"
                            onChange={e => {
                              const { name, value } = e.target;
                              this.getRegionDDL(name, value)
                            }}
                            error={this.hasError('ayah_region')}
                            helperText={
                              this.hasError('ayah_region') ? errors.ayah_region[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={ddl.villagesAyah}
                          value={values.ayah_village || null}
                          readOnly={values.ayah_domisili === "Luar Negeri" ? true : false}
                          getOptionLabel={(option) =>
                            option.nama
                          }
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ayah_village", newValue)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kelurahan / Desa"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ayah_village"
                            type="text"
                            error={this.hasError('ayah_village')}
                            helperText={
                              this.hasError('ayah_village') ? errors.ayah_village[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <TextField
                          fullWidth
                          label="Kode Pos"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ayah_kode_pos"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ayah_kode_pos || ''}
                          error={this.hasError('ayah_kode_pos')}
                          helperText={
                            this.hasError('ayah_kode_pos') ? errors.ayah_kode_pos[0] : null
                          }
                          InputProps={{
                            readOnly: values.ayah_domisili === "Luar Negeri" ? true : false,
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={24}>
                        <TextField
                          fullWidth
                          multiline
                          label="Alamat"
                          size="small"
                          rows={3.8}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ayah_alamat"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ayah_alamat || ''}
                          error={this.hasError('ayah_alamat')}
                          helperText={
                            this.hasError('ayah_alamat') ? errors.ayah_alamat[0] : null
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>

      <Col xs={24}>
        <Card className='form-box'>
          <CardContent>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={24}>
                    <Alert message="Data Ibu kandung" type="info" showIcon />

                    <div style={{ float: "right", top: "-35px", position: "relative" }}><Checkbox name="sama_dengan_ayah" value={0} onChange={this.handleChangeAlamatIbuSamaDenganAyah}>Sama dengan alamat Ayah</Checkbox></div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Status</FormLabel>
                        <Radio.Group style={{ width: '100%' }}
                          name="ibu_status"
                          value={values.ibu_status || ""}
                          onChange={this.handleChange}
                        >
                          <Row gutter={[16, 16]}>
                            {statusKehidupanOptions.map((row, i) => (
                              <Col xs={12} sm={8} md={6} key={i}>
                                <Radio value={row.value}>{row.label}</Radio>
                              </Col>
                            ))}
                          </Row>
                        </Radio.Group>
                      </Col>

                      <Col xs={24}>
                        <TextField
                          fullWidth
                          label="Nama"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ibu_nama"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ibu_nama || ''}
                          error={this.hasError('ibu_nama')}
                          helperText={
                            this.hasError('ibu_nama') ? errors.ibu_nama[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24}>
                        <TextField
                          fullWidth
                          label="NIK"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ibu_nik"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ibu_nik || ''}
                          error={this.hasError('ibu_nik')}
                          helperText={
                            this.hasError('ibu_nik') ? errors.ibu_nik[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <Autocomplete
                          options={kewarganegaraanOptions}
                          value={values.ibu_kewarganegaraan || null}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ibu_kewarganegaraan", newValue ? newValue : null)
                          }}

                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kewarganegaraan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ibu_kewarganegaraan"
                            type="text"
                            error={this.hasError('ibu_kewarganegaraan')}
                            helperText={
                              this.hasError('ibu_kewarganegaraan') ? errors.ibu_kewarganegaraan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <TextField
                          fullWidth
                          label="Tempat Lahir"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ibu_tempat_lahir"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ibu_tempat_lahir || null}
                          error={this.hasError('ibu_tempat_lahir')}
                          helperText={
                            this.hasError('ibu_tempat_lahir') ? errors.ibu_tempat_lahir[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              label="Tanggal Lahir"
                              inputFormat="dd-MM-yyyy"
                              value={values.ibu_tgl_lahir || null}
                              onChange={(newValue) => {
                                this.handleChangeSetValue("ibu_tgl_lahir", newValue)
                              }}
                              renderInput={(params) => <TextField
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={
                                  this.hasError('ibu_tgl_lahir') ? errors.ibu_tgl_lahir[0] : null
                                }
                                {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={pendidikanOptions}
                          value={values.ibu_status === "Masih Hidup" ? (values.ibu_pendidikan || null) : null}
                          readOnly={values.ibu_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ibu_pendidikan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pendidikan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ibu_pendidikan"
                            type="text"
                            error={this.hasError('ibu_pendidikan')}
                            helperText={
                              this.hasError('ibu_pendidikan') ? errors.ibu_pendidikan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={pekerjaanOptions}
                          value={values.ibu_status === "Masih Hidup" ? (values.ibu_pekerjaan || null) : null}
                          readOnly={values.ibu_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ibu_pekerjaan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pekerjaan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ibu_pekerjaan"
                            type="text"
                            error={this.hasError('ibu_pekerjaan')}
                            helperText={
                              this.hasError('ibu_pekerjaan') ? errors.ibu_pekerjaan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={penghasilanOptions}
                          value={values.ibu_status === "Masih Hidup" ? (values.ibu_penghasilan || null) : null}
                          readOnly={values.ibu_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ibu_penghasilan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Penghasilan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ibu_penghasilan"
                            type="text"
                            error={this.hasError('ibu_penghasilan')}
                            helperText={
                              this.hasError('ibu_penghasilan') ? errors.ibu_penghasilan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <TextField
                          fullWidth
                          label="No. Telepon"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ibu_no_telp"
                          type="text"
                          onChange={this.handleChange}
                          value={values.ibu_no_telp || ''}
                          error={this.hasError('ibu_no_telp')}
                          helperText={
                            this.hasError('ibu_no_telp') ? errors.ibu_no_telp[0] : null
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Domisili</FormLabel>
                        <Radio.Group style={{ width: '100%' }}
                          name="ibu_domisili"
                          value={(isAlamatIbuSamaDenganAyah ? values.ayah_domisili : values.ibu_domisili) || ""}
                          onChange={this.handleChange}
                        >
                          <Row gutter={[16, 16]}>
                            {domisiliOptions.map((row, i) => (
                              <Col xs={12} sm={8} md={6} key={i}>
                                <Radio value={row.value}>{row.label}</Radio>
                              </Col>
                            ))}
                          </Row>
                        </Radio.Group>
                      </Col>
                      <Col xs={24}>
                        <Autocomplete
                          options={statusTempatTinggalOrtuOptions}
                          value={(isAlamatIbuSamaDenganAyah ? values.ayah_status_tempat_tinggal : values.ibu_status_tempat_tinggal) || null}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ibu_status_tempat_tinggal", newValue)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Status Tempat Tinggal"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ibu_status_tempat_tinggal"
                            type="text"
                            error={this.hasError('ibu_status_tempat_tinggal')}
                            helperText={
                              this.hasError('ibu_status_tempat_tinggal') ? errors.ibu_status_tempat_tinggal[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={24}>
                        <Autocomplete
                          options={ddl.regionsIbu}
                          value={(isAlamatIbuSamaDenganAyah ? values.ayah_region : values.ibu_region) || null}
                          getOptionLabel={(option) =>
                            option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                          }
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ibu_region", newValue ? newValue : null)
                          }}
                          readOnly={isAlamatIbuSamaDenganAyah ? true : (values.ibu_domisili === "Luar Negeri" ? true : false)}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Provinsi, Kota & Kecamatan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ibu_region"
                            type="text"
                            onChange={e => {
                              const { name, value } = e.target;
                              this.getRegionDDL(name, value)
                            }}
                            error={this.hasError('ibu_region')}
                            helperText={
                              this.hasError('ibu_region') ? errors.ibu_region[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={ddl.villagesIbu}
                          value={(isAlamatIbuSamaDenganAyah ? values.ayah_village : values.ibu_village) || null}
                          getOptionLabel={(option) =>
                            option.nama
                          }
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("ibu_village", newValue)
                          }}
                          readOnly={isAlamatIbuSamaDenganAyah ? true : (values.ibu_domisili === "Luar Negeri" ? true : false)}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kelurahan / Desa"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="ibu_village"
                            type="text"
                            error={this.hasError('ibu_village')}
                            helperText={
                              this.hasError('ibu_village') ? errors.ibu_village[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <TextField
                          fullWidth
                          label="Kode Pos"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ibu_kode_pos"
                          type="text"
                          onChange={this.handleChange}
                          value={(isAlamatIbuSamaDenganAyah ? values.ayah_kode_pos : values.ibu_kode_pos) || ""}
                          error={this.hasError('ibu_kode_pos')}
                          helperText={
                            this.hasError('ibu_kode_pos') ? errors.ibu_kode_pos[0] : null
                          }
                          InputProps={{
                            readOnly: isAlamatIbuSamaDenganAyah ? true : (values.ibu_domisili === "Luar Negeri" ? true : false),
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={24}>
                        <TextField
                          fullWidth
                          multiline
                          label="Alamat"
                          size="small"
                          rows={3.8}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="ibu_alamat"
                          type="text"
                          onChange={this.handleChange}
                          value={(isAlamatIbuSamaDenganAyah ? values.ayah_alamat : values.ibu_alamat) || ""}
                          error={this.hasError('ibu_alamat')}
                          helperText={
                            this.hasError('ibu_alamat') ? errors.ibu_alamat[0] : null
                          }
                          InputProps={{
                            readOnly: isAlamatIbuSamaDenganAyah ? true : false,
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>

      <Col xs={24}>
        <Card className='form-box'>
          <CardContent>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={24}>
                    <Alert message="Data Wali" type="info" showIcon />

                    <div style={{ float: "right", top: "-35px", position: "relative" }}><Checkbox name="sama_dengan_ayah" value={0} checked={this.state.isAlamatWaliSamaDenganAyah ? true : false} onChange={this.handleChangeAlamatWaliSamaDenganAyah}>Sama dengan alamat Ayah</Checkbox> <Checkbox name="sama_dengan_ayah" value={0} checked={this.state.isAlamatWaliSamaDenganIbu ? true : false} onChange={this.handleChangeAlamatWaliSamaDenganIbu}>Sama dengan alamat Ibu</Checkbox></div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Status</FormLabel>
                        <Radio.Group style={{ width: '100%' }}
                          name="wali_status"
                          value={values.wali_status || ""}
                          onChange={this.handleChange}
                        >
                          <Row gutter={[16, 16]}>
                            {statusKehidupanOptions.map((row, i) => (
                              <Col xs={12} sm={8} md={6} key={i}>
                                <Radio value={row.value}>{row.label}</Radio>
                              </Col>
                            ))}
                          </Row>
                        </Radio.Group>
                      </Col>

                      <Col xs={24}>
                        <TextField
                          fullWidth
                          label="Nama"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="wali_nama"
                          type="text"
                          onChange={this.handleChange}
                          value={values.wali_nama || ''}
                          error={this.hasError('wali_nama')}
                          helperText={
                            this.hasError('wali_nama') ? errors.wali_nama[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24}>
                        <TextField
                          fullWidth
                          label="NIK"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="wali_nik"
                          type="text"
                          onChange={this.handleChange}
                          value={values.wali_nik || ''}
                          error={this.hasError('wali_nik')}
                          helperText={
                            this.hasError('wali_nik') ? errors.wali_nik[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <Autocomplete
                          options={kewarganegaraanOptions}
                          value={values.wali_kewarganegaraan || null}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("wali_kewarganegaraan", newValue ? newValue : null)
                          }}

                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kewarganegaraan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="wali_kewarganegaraan"
                            type="text"
                            error={this.hasError('wali_kewarganegaraan')}
                            helperText={
                              this.hasError('wali_kewarganegaraan') ? errors.wali_kewarganegaraan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <TextField
                          fullWidth
                          label="Tempat Lahir"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="wali_tempat_lahir"
                          type="text"
                          onChange={this.handleChange}
                          value={values.wali_tempat_lahir || null}
                          error={this.hasError('wali_tempat_lahir')}
                          helperText={
                            this.hasError('wali_tempat_lahir') ? errors.wali_tempat_lahir[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24} sm={8}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              label="Tanggal Lahir"
                              inputFormat="dd-MM-yyyy"
                              value={values.wali_tgl_lahir || null}
                              onChange={(newValue) => {
                                this.handleChangeSetValue("wali_tgl_lahir", newValue)
                              }}
                              renderInput={(params) => <TextField
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={
                                  this.hasError('wali_tgl_lahir') ? errors.wali_tgl_lahir[0] : null
                                }
                                {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={pendidikanOptions}
                          value={values.wali_status === "Masih Hidup" ? (values.wali_pendidikan || null) : null}
                          readOnly={values.wali_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("wali_pendidikan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pendidikan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="wali_pendidikan"
                            type="text"
                            error={this.hasError('wali_pendidikan')}
                            helperText={
                              this.hasError('wali_pendidikan') ? errors.wali_pendidikan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={pekerjaanOptions}
                          value={values.wali_status === "Masih Hidup" ? (values.wali_pekerjaan || null) : null}
                          readOnly={values.wali_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("wali_pekerjaan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Pekerjaan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="wali_pekerjaan"
                            type="text"
                            error={this.hasError('wali_pekerjaan')}
                            helperText={
                              this.hasError('wali_pekerjaan') ? errors.wali_pekerjaan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={penghasilanOptions}
                          value={values.wali_status === "Masih Hidup" ? (values.wali_penghasilan || null) : null}
                          readOnly={values.wali_status === "Masih Hidup" ? false : true}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("wali_penghasilan", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Penghasilan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="wali_penghasilan"
                            type="text"
                            error={this.hasError('wali_penghasilan')}
                            helperText={
                              this.hasError('wali_penghasilan') ? errors.wali_penghasilan[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <TextField
                          fullWidth
                          label="No. Telepon"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="wali_no_telp"
                          type="text"
                          onChange={this.handleChange}
                          value={values.wali_no_telp || ''}
                          error={this.hasError('wali_no_telp')}
                          helperText={
                            this.hasError('wali_no_telp') ? errors.wali_no_telp[0] : null
                          }
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <TextField
                          fullWidth
                          label="E-Mail"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            // readOnly: true
                          }}
                          name="wali_email"
                          type="text"
                          onChange={this.handleChange}
                          value={values.wali_email || ''}
                          error={this.hasError('wali_email')}
                          helperText={
                            this.hasError('wali_email') ? errors.wali_email[0] : null
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Domisili</FormLabel>
                        <Radio.Group style={{ width: '100%' }}
                          name="wali_domisili"
                          value={(isAlamatWaliSamaDenganAyah ? values.ayah_domisili : (isAlamatWaliSamaDenganIbu ? (isAlamatIbuSamaDenganAyah ? values.ayah_domisili : values.ibu_domisili) : values.wali_domisili)) || ""}
                          onChange={this.handleChange}
                        >
                          <Row gutter={[16, 16]}>
                            {domisiliOptions.map((row, i) => (
                              <Col xs={12} sm={8} md={6} key={i}>
                                <Radio value={row.value}>{row.label}</Radio>
                              </Col>
                            ))}
                          </Row>
                        </Radio.Group>
                      </Col>
                      <Col xs={24}>
                        <Autocomplete
                          options={statusTempatTinggalOrtuOptions}
                          value={(isAlamatWaliSamaDenganAyah ? values.ayah_status_tempat_tinggal : (isAlamatWaliSamaDenganIbu ? (isAlamatIbuSamaDenganAyah ? values.ayah_status_tempat_tinggal : values.ibu_status_tempat_tinggal) : values.wali_status_tempat_tinggal)) || null}
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("wali_status_tempat_tinggal", newValue ? newValue : null)
                          }}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Status Tempat Tinggal"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="wali_status_tempat_tinggal"
                            type="text"
                            error={this.hasError('wali_status_tempat_tinggal')}
                            helperText={
                              this.hasError('wali_status_tempat_tinggal') ? errors.wali_status_tempat_tinggal[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={24}>
                        <Autocomplete
                          options={ddl.regionsWali}
                          value={(isAlamatWaliSamaDenganAyah ? values.ayah_region : (isAlamatWaliSamaDenganIbu ? (isAlamatIbuSamaDenganAyah ? values.ayah_region : values.ibu_region) : values.wali_region)) || null}
                          getOptionLabel={(option) =>
                            option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                          }
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("wali_region", newValue ? newValue : null)
                          }}
                          readOnly={isAlamatWaliSamaDenganAyah || isAlamatWaliSamaDenganIbu ? true : (values.wali_domisili === "Luar Negeri" ? true : false)}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Provinsi, Kota & Kecamatan"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="wali_region"
                            type="text"
                            onChange={e => {
                              const { name, value } = e.target;
                              this.getRegionDDL(name, value)
                            }}
                            error={this.hasError('wali_region')}
                            helperText={
                              this.hasError('wali_region') ? errors.wali_region[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Autocomplete
                          options={ddl.villagesWali}
                          value={(isAlamatWaliSamaDenganAyah ? values.ayah_village : (isAlamatWaliSamaDenganIbu ? (isAlamatIbuSamaDenganAyah ? values.ayah_village : values.ibu_village) : values.wali_village)) || null}
                          getOptionLabel={(option) =>
                            option.nama
                          }
                          onChange={(e, newValue) => {
                            this.handleChangeSetValue("wali_village", newValue)
                          }}
                          readOnly={isAlamatWaliSamaDenganAyah || isAlamatWaliSamaDenganIbu ? true : (values.wali_domisili === "Luar Negeri" ? true : false)}
                          renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kelurahan / Desa"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="wali_village"
                            type="text"
                            error={this.hasError('wali_village')}
                            helperText={
                              this.hasError('wali_village') ? errors.wali_village[0] : null
                            }
                          />}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <TextField
                          fullWidth
                          label="Kode Pos"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="wali_kode_pos"
                          type="text"
                          onChange={this.handleChange}
                          value={(isAlamatWaliSamaDenganAyah ? values.ayah_kode_pos : (isAlamatWaliSamaDenganIbu ? (isAlamatIbuSamaDenganAyah ? values.ayah_kode_pos : values.ibu_kode_pos) : values.wali_kode_pos)) || ""}
                          error={this.hasError('wali_kode_pos')}
                          helperText={
                            this.hasError('wali_kode_pos') ? errors.wali_kode_pos[0] : null
                          }
                          InputProps={{
                            readOnly: isAlamatWaliSamaDenganAyah || isAlamatWaliSamaDenganIbu ? true : (values.wali_domisili === "Luar Negeri" ? true : false),
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={24}>
                        <TextField
                          fullWidth
                          multiline
                          label="Alamat"
                          size="small"
                          rows={3.8}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="wali_alamat"
                          type="text"
                          onChange={this.handleChange}
                          value={(isAlamatWaliSamaDenganAyah ? values.ayah_alamat : (isAlamatWaliSamaDenganIbu ? (isAlamatIbuSamaDenganAyah ? values.ayah_alamat : values.ibu_alamat) : values.wali_alamat)) || ""}
                          error={this.hasError('wali_alamat')}
                          helperText={
                            this.hasError('wali_alamat') ? errors.wali_alamat[0] : null
                          }
                          InputProps={{
                            readOnly: isAlamatWaliSamaDenganAyah || isAlamatWaliSamaDenganIbu ? true : false,
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>

      <Col xs={24} style={{ textAlign: "right" }}>
        <Button style={{ margin: 0 }} loading={this.state.loadingBtn} onClick={() => {
          this.setState({
            isUpdate: false
          })
        }}>
          Batal
        </Button>
        <Button style={{ margin: '0 0 0 8px' }} type="primary" htmlType="submit" icon={<SaveOutlined />} loading={this.state.loadingBtn} onClick={this.onSubmitAyah}>
          Simpan
        </Button>
      </Col>
    </Row>

    return (
      <>
        {this.state.isUpdate ? form : compDetail}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(OrangTua);
