import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { PageHeader } from 'components/header';
import React from 'react';

import TracingDetail from './Detail';
import TracingForm from './Form';

class Tracing extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUpdate: false
        }
    }

    render() {
        const { action } = this.props
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Tracing"
                    extra={[
                        action.update && !this.state.isUpdate ? <Button shape='circle' onClick={() => {
                            this.setState({
                                isUpdate: true
                            })
                        }}><EditOutlined /></Button> : null
                    ]}
                />

                {
                    this.state.isUpdate ? <TracingForm onCancel={() => {
                        this.setState({
                            isUpdate: false
                        })
                    }} {...this.props}/> : <TracingDetail {...this.props}/>
                }
            </>
        )
    }
}


export default Tracing;