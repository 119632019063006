import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, _getFile, _beforeUploadImage } from '../../../lib/Helper';
import validate from 'validate.js';
import moment from 'moment';
import AuthRedirect from '../../../components/AuthRedirect'

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Menu, Dropdown, Input, Tooltip, Modal, Upload, DatePicker, Form, message } from 'antd';
import { PageHeader } from 'components/header';
import { SaveOutlined, DownloadOutlined, InfoCircleOutlined, SearchOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined, SettingOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';

const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const dateFormat = 'DD-MM-YYYY';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}
const schema = {}

class JalurMasukKedinasan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tipe: "Kedinasan"
                },
            },
            form: {
                visible: false,
                loading: false,
            },

            fileList: [],
            validateFile: false,
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("bk/media-informasi/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadImage = (file) => {
        if (!_beforeUploadImage(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {},
            fileList: []
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    nama: row.nama ? row.nama : null,
                    link: row.link ? row.link : null,
                    dates: [moment(row.periode_awal, dateFormat), moment(row.periode_akhir, dateFormat)],
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schema)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }
        if (!values.dates || values.dates.length === 0) {
            message.warn('Periode post harus diisi');
            return
        }

        let periodeAwal = moment(values.dates[0]).format("DD-MM-YYYY")
        let periodeAkhir = moment(values.dates[1]).format("DD-MM-YYYY")

        // Payload
        const param = {
            jenis_jalur: null,
            nama: values.nama ? values.nama : null,
            periode_awal: periodeAwal ? periodeAwal : null,
            periode_akhir: periodeAkhir ? periodeAkhir : null,
            keterangan: values.keterangan ? values.keterangan : null,
            file: await _getFile(this.state.fileList),
            link: values.link ? values.link : null,
            tipe: "Kedinasan",
        }

        // Define Network
        let method = "POST"
        let endpoint = "bk/media-informasi"
        if (values.id) {
            method = "PUT"
            endpoint = "bk/media-informasi/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("bk/media-informasi/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/media-informasi"]
        // const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }

        const { table, values, errors } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Jalur Masuk Kedinasan"
            visible={this.state.form.visible}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Sekolah Kedinasan *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Link"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="link"
                        type="text"
                        onChange={this.handleChange}
                        value={values.link || ''}
                        error={this.hasError('link')}
                        helperText={
                            this.hasError('link') ? errors.link[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Form.Item label="Periode Post *">
                        <RangePicker
                            style={{
                                width: "100%", borderRadius: 4, padding: "6px 10px"
                            }}
                            format={dateFormat}
                            value={values.dates || []}
                            onChange={val => {
                                this.setState({
                                    values: {
                                        ...values,
                                        dates: val
                                    }
                                })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.fileList}
                        onChange={this.handleChangeFile}
                        beforeUpload={this.beforeUploadImage}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, JPEG & PNG</p>
                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                    </Dragger>
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <PageHeader
                            className="site-page-header"
                            title="Jalur Masuk Kedinasan"
                            extra={
                                <>
                                    <Tooltip title="Tambah">
                                        <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                                    </Tooltip>
                                </>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={21}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }
                            }}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={3}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Jalur Masuk Kedinasan',
                                    dataIndex: 'nama',
                                    render: (a, row) => <div style={{ width: 250 }}>{row.nama}</div>,
                                },
                                {
                                    title: 'Link',
                                    dataIndex: 'link',
                                },
                                {
                                    title: 'File',
                                    dataIndex: 'file',
                                    render: (a, row) => row.file ? <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-",
                                },
                                {
                                    title: 'Periode Post',
                                    dataIndex: 'periode_awal',
                                    render: (a, row) => row.periode_awal + " - " + row.periode_akhir,
                                },
                                {
                                    title: <SettingOutlined />,
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>
                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(JalurMasukKedinasan);
