import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";
import { LabelIsActive } from "components/labels";

const columns = (action, modalForm, modalDelete) => [
    {
      title: 'No',
      dataIndex: 'rownum',
      width: '2%',
    },
    {
      title: 'Tipe',
      dataIndex: 'tipe',
    },
    {
      title: 'Hari',
      dataIndex: 'hari',
    },
    {
      title: 'Jam Masuk',
      dataIndex: 'jam_masuk',
    },
    {
      title: 'Jam Pulang',
      dataIndex: 'jam_pulang',
    },
    {
      title: 'Radius Toleransi',
      dataIndex: 'radius_toleransi',
    },
    {
      title: 'Radius Status',
      render: (a, row) => <LabelIsActive isActive={row.radius_active === "1"} />,
    },
    {
      title: 'Latitude',
      dataIndex: 'lat',
    },
    {
      title: 'Longitude',
      dataIndex: 'long',
    },
    {
      title: <SettingOutlined />,
      align: "center",
      key: 'operation',
      width: '2%',
      render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete} />,
    },
    {
      title: 'LogActivity',
      key: 'logactivity',
      width: '10%',
      render: (a, row) => _tableLogActivity(row),
    },
  ];
  
  export default columns