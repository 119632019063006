import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, Button, Breadcrumb, BackTop } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';

import { isAuth, _setAxios, getParamTable } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/AuthRedirect'
import Form from '../modal/Form';
import FormTransaksi from '../history/modal/Form';
import Delete from '../modal/Delete';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SortingTableDesktop from '../../../../components/sorting-table/SortingTableDesktop';
import SortingTableMobile from '../../../../components/sorting-table/SortingTableMobile';
import SearchTable from '../../../../components/search-table/SearchTable';
import Export from '../modal/Export';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "nama",
  label: "Nama Barang",
}, {
  value: "kategori_nama",
  label: "Kategori",
}, {
  value: "satuan",
  label: "Satuan",
}]

class AssetLancar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      form: {
        data: {},
        visible: false
      },
      formTransaksi: {
        data: {},
        visible: false
      },
      delete: {
        data: {},
        visible: false
      },
      export: {
        visible: false,
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("sarpras/asset-lancar/master/table", "POST", params).then(resp => {
      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }

  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: {
          ...data,
          kategori_id: data.kategori_id ? {
            id: data.kategori_id,
            nama: data.kategori_nama,
          } : null
        },
        visible: visible
      },
    })
  }

  modalFormTransaksi = (visible = false, data = {}) => {
    this.setState({
      formTransaksi: {
        data: data,
        visible: visible
      },
    })
  }

  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }

  render() {

    const access = this.props.privilege.access["/sarana-prasarana/asset-lancar"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
            <Breadcrumb.Item>Asset Lancar </Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true)}><DownloadOutlined /> Export</Button> : null,
            action.create ? <Button type='primary' onClick={this.modalForm.bind(this, true)}><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Asset Lancar"
            subTitle="Data Asset Lancar"
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobile table={table} fetch={this.fetch} sortFields={sortFields} />
              <SortingTableDesktop table={table} fetch={this.fetch} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalFormTransaksi={(visible, data) => {
                    this.modalFormTransaksi(visible, data)
                  }}
                  modalDelete={this.modalDelete}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalFormTransaksi={(visible, data) => {
                    this.modalFormTransaksi(visible, data)
                  }}
                  modalDelete={this.modalDelete}
                />
              </MobileView>
            </Col>

          </Row>
        </div>
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.formTransaksi.visible ?
            <FormTransaksi
              data={this.state.formTransaksi.data}
              visible={this.state.formTransaksi.visible}
              hideModal={this.modalFormTransaksi.bind(this, false)}
              fetchTable={this.fetchTable}
              {...this.props}
            /> : ""
        }
        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(AssetLancar);
