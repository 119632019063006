import React from 'react';

import { Row, Col, Divider, List, Spin, Empty } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MobileView } from 'react-device-detect';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { columns, state } = this.props
    const { table } = state

    return (
      <MobileView>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => {
                  return <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row[table.titleMobile] ? row[table.titleMobile] : ""}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              {columns.map((col, i) => {
                                if (col.key === "operation" || col.key === "logactivity" || col.dataIndex === table.titleMobile) {
                                  return null
                                }
                                return (
                                  <>
                                    <b>{col.title}</b>: {
                                      col.render ? col.render(null, row) : col.renderText ? col.renderText(row) : col.dataIndex ? row[col.dataIndex] : "-" ? row[col.dataIndex] : "-"
                                    }<br />
                                  </>
                                )
                              })}
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    {columns.map((col, i) => {
                      if (col.key === "operation") {
                        return col.render ? col.render(null, row) : col.renderText ? col.renderText(row) : col.dataIndex ? row[col.dataIndex] : "-" ? row[col.dataIndex] : "-"
                      }
                      return null
                    })}
                  </List.Item>
                }}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </MobileView>
    )
  }
}

export default Mobile;
