import React from 'react';

import { Row, Col, Modal, Button, Form, Select, DatePicker, Input } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../../lib/Helper';
import { validate } from 'validate.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const dateFormat = 'DD-MM-YYYY';

const schema = {
    kecakapan: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tanggal_lulus: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    pembina: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};


class ModalContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                kecakapan: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            siswa_id: values.siswa_id ? values.siswa_id : null,
            pramuka_master_sku_id: values.kecakapan ? values.kecakapan : null,
            tgl_lulus: values.tanggal_lulus ? values.tanggal_lulus : null,
            pembina: values.pembina ? values.pembina : null
        }

        let endpoint = "kaldik-kegiatan/pramuka/penilaian"
        let method = "POST"
        if (this.props.data.id) {
            endpoint = "kaldik-kegiatan/pramuka/penilaian/" + this.props.data.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.getSiswaPramukaByID()
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    componentDidMount() {
        this.getKecakapanDDL()
        this.setState({
            values: {
                ...this.props.data,
                kecakapan: this.props.data.sku_id,
                tanggal_lulus: this.props.data.tgl_lulus
            }
        })
    }

    getKecakapanDDL = () => {

        let isBantaraCompleted = false
        if (this.props.dataSiswa && this.props.dataSiswa.total_bantara) {
            let totalBantaraEnd = 0
            let totalBantaraStart = 0
            let totalBantara = this.props.dataSiswa.total_bantara.split("/");
            if (totalBantara.length === 2) {
                totalBantaraStart = totalBantara[0]
                totalBantaraEnd = totalBantara[1]

                if (totalBantaraStart === totalBantaraEnd) {
                    isBantaraCompleted = true
                }
            }
        }
        _setAxios("kaldik-kegiatan/pramuka/master-sku/dropdown", "POST", {
            "agama": "Islam",
            "jenis_penegak": isBantaraCompleted ? "Penegak Laksana" : "Penegak Bantara"
        }).then(resp => {
            if (resp.status === true) {
                let ddlKecakapan = []
                resp.data.data.forEach(el => {
                    ddlKecakapan.push({
                        value: el.id,
                        label: el.uraian_kecakapan
                    })
                });
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        kecakapan: ddlKecakapan
                    },
                })
            }
        })
    }

    render() {
        const { ddl } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Form Syarat Kecakapan Umum Siswa"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24}>
                            <Form.Item label="Kecakapan *">
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    name="kecakapan"
                                    placeholder="Pilih kecakapan"
                                    defaultValue={this.props.data.sku_id}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("kecakapan", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => {
                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    options={ddl.kecakapan}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item label="Tanggal Lulus *">
                                <DatePicker defaultValue={this.props.data.tgl_lulus ? dayjs(this.props.data.tgl_lulus, dateFormat) : null} format={dateFormat} style={{ width: "100%" }}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tanggal_lulus", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Pembina *">
                                <Input
                                    name="pembina"
                                    placeholder="Pembina"
                                    defaultValue={this.props.data.pembina}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("pembina", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        )
    }
}

export default ModalContent;
