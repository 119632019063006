import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _validationFormMsg, _getBase64Upload, _getBase64 } from '../../lib/Helper';
import { schemaSertifikasi } from './schema';
import validate from 'validate.js';
import { statusSertifikasiOptions, statusKelulusanOptions, profesiDidapatkanOptions } from '../../data/options';

import { Row, Col, Upload, message, Button, Skeleton, Divider } from 'antd';
import { PageHeader } from 'components/header';
import { InboxOutlined, SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

const { Dragger } = Upload;
class Sertifikasi extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,
            loading: true
        };
    }

    componentDidMount() {
        if (this.props.tipeForm === "create") {
            this.setState({
                loading: false
            })
        } else {
        }
        this.getSertifikasiByID(this.props.dataID)
    }

    getSertifikasiByID = (id) => {
        _setAxios("tk/sertifikasi/table/" + id, "POST").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                let fileName = ""
                if (data.file) {
                    fileName = data.file.substring(data.file.lastIndexOf('/') + 1);
                }
                this.setState({
                    values: data,
                    fileList: data.file ? [{
                        uid: '-1',
                        name: fileName,
                        status: 'done',
                        url: data.main_path + data.file,
                    }] : [],
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaSertifikasi);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaSertifikasi);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        const isJpgOrPngPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPngPdf) {
            message.error('Anda bisa upload JPG/PNG/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }

        const flag = isJpgOrPngPdf && isLt2M

        if (flag === false) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaSertifikasi)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Tahun & Tanggal
        let tahunSertifikasi = values.tahun_sertifikasi
        if (values.tahun_sertifikasi && values.tahun_sertifikasi instanceof Date) {
            tahunSertifikasi = values.tahun_sertifikasi.getFullYear()
        }

        // File base64
        let fileList = this.state.fileList
        let file = null
        if (fileList && fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }
        }

        // Payload
        const param = {
            status_sertifikasi: values.status_sertifikasi ? values.status_sertifikasi : null,
            tahun_sertifikasi: tahunSertifikasi,
            status_kelulusan: values.status_kelulusan ? values.status_kelulusan : null,
            tgl_lulus: values.tgl_lulus ? values.tgl_lulus : null,
            nomor_sertifikat: values.nomor_sertifikat ? values.nomor_sertifikat : null,
            profesi: values.profesi ? values.profesi : null,
            file: file,
        }

        // Define Network
        let method = "POST"
        let payload = param
        let id = this.props.dataID
        if (values.id) {
            method = "PUT"
            payload = param
            id = values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios("tk/sertifikasi/" + id, method, payload).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.getSertifikasiByID(this.props.dataID)
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------

    render() {
        const { values, errors, form, loading } = this.state;

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Sertifikasi"
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    {
                        loading ? <Skeleton /> :
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={18}>
                                    <Autocomplete
                                        options={statusSertifikasiOptions}
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Status Sertifikasi *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="status_sertifikasi"
                                            type="text"
                                            error={this.hasError('status_sertifikasi')}
                                            helperText={
                                                this.hasError('status_sertifikasi') ? errors.status_sertifikasi[0] : null
                                            }
                                        />}
                                        value={values.status_sertifikasi || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("status_sertifikasi", newValue ? newValue.value : null)
                                        }}
                                    />
                                </Col>
                                <Col xs={24} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <MobileDatePicker
                                                label="Tahun Sertifikasi *"
                                                views={['year']}
                                                value={values.tahun_sertifikasi || null}
                                                onChange={(newValue) => {
                                                    this.handleChangeSetValue("tahun_sertifikasi", newValue)
                                                }}
                                                renderInput={(params) => <TextField
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={this.hasError('tahun_sertifikasi')}
                                                    helperText={
                                                        this.hasError('tahun_sertifikasi') ? errors.tahun_sertifikasi[0] : null
                                                    }
                                                    {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Col>
                                <Col xs={24} sm={18}>
                                    <Autocomplete
                                        options={statusKelulusanOptions}
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Status Kelulusan *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="status_kelulusan"
                                            type="text"
                                            error={this.hasError('status_kelulusan')}
                                            helperText={
                                                this.hasError('status_kelulusan') ? errors.status_kelulusan[0] : null
                                            }
                                        />}
                                        value={values.status_kelulusan || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("status_kelulusan", newValue ? newValue.value : null)
                                        }}
                                    />
                                </Col>
                                <Col xs={24} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <MobileDatePicker
                                                label="Tanggal Lulus"
                                                renderInput={(params) => <TextField
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={this.hasError('tgl_lulus')}
                                                    helperText={
                                                        this.hasError('tgl_lulus') ? errors.tgl_lulus[0] : null
                                                    }
                                                    {...params} />}
                                                value={values.tgl_lulus || null}
                                                onChange={(newValue) => {
                                                    this.handleChangeSetValue("tgl_lulus", newValue)
                                                }}

                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Col>
                                <Col xs={24}>
                                    <TextField
                                        fullWidth
                                        label="Nomor Sertifikat"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="nomor_sertifikat"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={values.nomor_sertifikat || ''}
                                        error={this.hasError('nomor_sertifikat')}
                                        helperText={
                                            this.hasError('nomor_sertifikat') ? errors.nomor_sertifikat[0] : null
                                        }
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Autocomplete
                                        options={profesiDidapatkanOptions}
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Profesi"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="profesi"
                                            type="text"
                                            error={this.hasError('profesi')}
                                            helperText={
                                                this.hasError('profesi') ? errors.profesi[0] : null
                                            }
                                        />}
                                        value={values.profesi || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("profesi", newValue ? newValue.value : null)
                                        }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Dragger
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        fileList={this.state.fileList}
                                        onChange={this.handleChangeFile}
                                        beforeUpload={this.beforeUploadFile}
                                        maxCount={1}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                                    </Dragger>
                                </Col>
                                <Col xs={24} style={{ textAlign: "right" }}>
                                    <Divider />
                                    <Button type="text" onclick="history.back()">Kembali</Button>
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={form.loading} onClick={this.onSubmitForm} style={{ float: "right" }}>Simpan</Button>
                                </Col>
                            </Row>
                    }

                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Sertifikasi);
