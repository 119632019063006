import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from 'lib/Helper';
import { Breadcrumb, Row, Col, Tabs } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined } from '@ant-design/icons';
import KehadiranIndex from './kehadiran/list/index'
import KetidakHadiranIndex from './ketidakhadiran/list/index'
import AuthRedirect from 'components/AuthRedirect';

const { TabPane } = Tabs;
class NotificationPresensi extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tabIndex: "kehadiran",
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIndex: query.get("tab") ? query.get("tab") : "kehadiran"
        })

    }

    handleChangeTab = (key) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIndex: key
        })
    }


    render() {
        const { privilege } = this.props
        const access = privilege.access["/notification/presensi"]
        const action = access.action

        if (!isAuth(privilege) || (access === undefined)) {
            return <AuthRedirect />
        }

        const tabData = [
            {
                key: "kehadiran",
                title: "Kehadiran",
                component: <KehadiranIndex
                    action={action}
                    {...this.props} />
            },
            {
                key: "ketidakhadiran",
                title: "Ketidakhadiran",
                component: <KetidakHadiranIndex
                    action={action}
                    {...this.props} />
            },
        ]

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Notification</Breadcrumb.Item>
                        <Breadcrumb.Item>Presensi </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Tabs defaultActiveKey={this.state.tabIndex} activeKey={this.state.tabIndex} onChange={this.handleChangeTab}>
                                {tabData.map((row, i) => <TabPane tab={row.title} key={row.key}> {row.component} </TabPane>)}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(NotificationPresensi);