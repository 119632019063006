import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, getParamTempTableFiltering, _setAxios } from 'lib/Helper';
import { yearFormat } from 'constant/constant_format';
import dayjs from 'dayjs';

const pathPage = "/kurikulum/tahun-ajaran"

const useSemester = (privilege, tahunAjaranId) => {
  const access = privilege.access[pathPage];
  const action = access ? access.action : {};

  const initialTableState = () => ({
    endpoint: "semester/table",
    titleMobile: "",
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
      current: 1,
      pageSize: 10,
      total: null,
    },
    sorting: [],
    filtering: {
      tahun_ajaran_id: tahunAjaranId
    },
  });

  const [table, setTable] = useState(initialTableState());
  const [form, setFormState] = useState({ data: {}, visible: false });
  const [filter, setFilterState] = useState({ values: {
    ...table.filtering
  }, visible: false });
  const [deleteState, setDeleteState] = useState({ data: {}, visible: false });

  const fetchTable = (isNew = false) => {
    const params = getParamTable("default", table);
    fetchData(params, isNew);
  };

  const fetchData = async (params = {}, isNew = false) => {
    updateTableLoadingState(isNew);

    params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

    const resp = await _setAxios(params.endpoint, "POST", params);
    if (resp.status) {
      setTimeout(() => {
        updateTableData(resp.data.list, params.pagination.current, isNew);
      }, 0);
    } else {
      resetTableLoadingState();
    }
  };

  const updateTableLoadingState = (isNew) => {
    setTable(prev => ({
      ...prev,
      loading_first: isMobile ? isNew : false,
      loading: true
    }));
  };

  const resetTableLoadingState = () => {
    setTable(prev => ({ ...prev, loading_first: false, loading: false }));
  };

  const updateTableData = (data, current, isNew) => {
    const updatedData = isNew ? [] : table.data;
    const newCurrent = isMobile ? current + 1 : current;

    setTable(prev => ({
      ...prev,
      loading_first: false,
      loading: false,
      data: isMobile ? [...updatedData, ...data] : data,
      pagination: {
        ...prev.pagination,
        current: newCurrent,
        total: data.length,
      },
    }));
  };

  const setTempTableFilter = (name, value) => {
    setFilterState(prev => ({
      ...prev,
      values: { ...prev.values, [name]: value }
    }));
  };

  const setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(table, filter.values);
    fetchData(params, isMobile);
    modalFilter(false);
  };

  const modalFilter = (visible = false) => {
    setFilterState(prev => ({ ...prev, visible }));
  };

  const modalForm = useCallback((visible = false, data = {}) => {
    if (data.id) {
      if (data.tahun_ajaran) {
        data.periode = [
          dayjs(data.tahun_ajaran.split("-")[0], yearFormat),
          dayjs(data.tahun_ajaran.split("-")[1], yearFormat)
        ]
      }
    }

    setFormState({ visible, data });
  }, []);

  const modalDelete = (visible = false, data = {}) => {
    setDeleteState({ visible, data });
  };

  return {
    table,
    form,
    filter,
    deleteState,
    action,
    modalForm,
    modalDelete,
    modalFilter,
    setTempTableFilter,
    setTableFilterFromTemp,
    fetchData,
    fetchTable,
  };
};

export default useSemester;
