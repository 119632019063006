import React from 'react';

import { Select } from 'antd';
import { daysOptions } from '../../data/options';

const SelectDays = ({ ...props }) => {
  return (
    <Select
      {...props}
      placeholder="Pilih hari"
      style={{
        width: '100%',
      }}
      options={daysOptions}
    />
  );
};
export default SelectDays;
